import React, { useState, useEffect } from "react";
import { getFontSizeStyle } from "../../../helpers/helpers";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { baseURL } from "../../../Shared/baseURL";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Loader } from "../../../constants/loaders";
import { useParams } from "react-router-dom";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import { getCandidateProfileById } from "../../../../redux/actions/candidatesAction";

const TimelineActionModal = ({
  timelineNewActionModal,
  setTimelineNewActionModal,
  timelineToggle,
  fetchData,
}) => {
  const settingsSelector = useSelector((state) => state.settings);

  const { t } = useTranslation();

  const { id } = useParams();

  const dispatch = useDispatch();

  const timelineActionsInitialState = {
    candidatecv_id: "",
    timeline_action_type_id: "",
    timeline_action_type_comment: "",
  };

  const [timelineActionsFormValues, setTimelineActionsFormValues] = useState(
    timelineActionsInitialState
  );
  const [loading, setLoading] = useState(false);
  const [isCommentDisabled, setIsCommentDisabled] = useState(false);
  const [commentActionTypeId, setCommentActionTypeId] = useState("");
  // const [candidateById, setCandidateById] = useState({});

  const fetchCandidateProfile = async () => {
    try {
      const response = await dispatch(getCandidateProfileById(id));
      // setCandidateById(response.payload.cadidatecv);

      const timelineResponse = response.payload.cadidatecv;

      let existedData = {
        candidateById: id,
        timeline_action_type_id: timelineResponse.timeline_action_type_id,
        timeline_action_type_comment:
          timelineResponse.timeline_action_type_comment === null
            ? ""
            : timelineResponse.timeline_action_type_comment,
      };
      setTimelineActionsFormValues(existedData);

      if (
        timelineResponse.timeline_action_type_id > 0 &&
        timelineResponse.timeline_action_type_id < 10
      ) {
        setIsCommentDisabled(true);
      } else {
        setIsCommentDisabled(false);
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    fetchCandidateProfile();
  }, []);

  const axiosInstance = useAxiosInstance();

  const handleTimelineActionSubmit = (e) => {
    setLoading(true);
    return axiosInstance
      .post(`${baseURL}api/open/candidate_cv/timeline_action_type`, {
        candidatecv_id: id,
        timeline_action_type_id: commentActionTypeId,
        timeline_action_type_comment: isCommentDisabled
          ? ""
          : e.timeline_action_type_comment,
      })
      .then((response) => {
        setTimelineNewActionModal(false);
        fetchData();
        fetchCandidateProfile();
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={timelineNewActionModal} toggle={timelineToggle}>
      <ModalHeader
        toggle={timelineToggle}
        style={getFontSizeStyle(settingsSelector.FontSize, "15px")}
      >
        {t("timelineHistory.newActivityModal.heading")}
      </ModalHeader>
      <Formik
        initialValues={timelineActionsFormValues}
        enableReinitialize={true}
        onSubmit={(e) => handleTimelineActionSubmit(e)}
      >
        <Form>
          <ModalBody>
            <FormGroup>
              <Label style={getFontSizeStyle(settingsSelector.FontSize)}>
                {t("timelineHistory.newActivityModal.call")}
              </Label>
              <div className="custom-controls-stacked d-flex">
                <Label className="custom-control custom-radio custom-control-inline">
                  <Field name="timeline_action_type_id">
                    {({ field }) => (
                      <input
                        {...field}
                        type="radio"
                        className="custom-control-input"
                        value="1"
                        defaultChecked={
                          timelineActionsFormValues.timeline_action_type_id ===
                          1
                        }
                        onChange={() => {
                          setIsCommentDisabled(true);
                          setCommentActionTypeId("1");
                        }}
                      />
                    )}
                  </Field>
                  <span
                    className="custom-control-label"
                    style={getFontSizeStyle(settingsSelector.FontSize)}
                  >
                    {t("timelineHistory.newActivityModal.incoming")}
                  </span>
                </Label>
                <Label className="custom-control custom-radio custom-control-inline">
                  <Field name="timeline_action_type_id">
                    {({ field }) => (
                      <input
                        {...field}
                        type="radio"
                        className="custom-control-input"
                        value="2"
                        defaultChecked={
                          timelineActionsFormValues.timeline_action_type_id ===
                          2
                        }
                        onChange={() => {
                          setIsCommentDisabled(true);
                          setCommentActionTypeId("2");
                        }}
                      />
                    )}
                  </Field>
                  <span
                    className="custom-control-label"
                    style={getFontSizeStyle(settingsSelector.FontSize)}
                  >
                    {t("timelineHistory.newActivityModal.outgoing")}
                  </span>
                </Label>
                <Label className="custom-control custom-radio custom-control-inline">
                  <Field name="timeline_action_type_id">
                    {({ field }) => (
                      <input
                        {...field}
                        type="radio"
                        className="custom-control-input"
                        value="3"
                        defaultChecked={
                          timelineActionsFormValues.timeline_action_type_id ===
                          3
                        }
                        onChange={() => {
                          setIsCommentDisabled(true);
                          setCommentActionTypeId("3");
                        }}
                      />
                    )}
                  </Field>
                  <span
                    className="custom-control-label"
                    style={getFontSizeStyle(settingsSelector.FontSize)}
                  >
                    {t("timelineHistory.newActivityModal.answered")}
                  </span>
                </Label>
                <Label className="custom-control custom-radio custom-control-inline">
                  <Field name="timeline_action_type_id">
                    {({ field }) => (
                      <input
                        {...field}
                        type="radio"
                        className="custom-control-input"
                        value="4"
                        defaultChecked={
                          timelineActionsFormValues.timeline_action_type_id ===
                          4
                        }
                        onChange={() => {
                          setIsCommentDisabled(true);
                          setCommentActionTypeId("4");
                        }}
                      />
                    )}
                  </Field>
                  <span
                    className="custom-control-label"
                    style={getFontSizeStyle(settingsSelector.FontSize)}
                  >
                    {t("timelineHistory.newActivityModal.notAnswered")}
                  </span>
                </Label>
              </div>
            </FormGroup>
            <FormGroup>
              <Label style={getFontSizeStyle(settingsSelector.FontSize)}>
                {t("timelineHistory.newActivityModal.sms")}
              </Label>
              <div className="custom-controls-stacked">
                <Label className="custom-control custom-radio custom-control-inline">
                  <Field name="timeline_action_type_id">
                    {({ field }) => (
                      <input
                        {...field}
                        type="radio"
                        className="custom-control-input"
                        value="5"
                        defaultChecked={
                          timelineActionsFormValues.timeline_action_type_id ===
                          5
                        }
                        onChange={() => {
                          setIsCommentDisabled(true);
                          setCommentActionTypeId("5");
                        }}
                      />
                    )}
                  </Field>
                  <span
                    className="custom-control-label"
                    style={getFontSizeStyle(settingsSelector.FontSize)}
                  >
                    {t("timelineHistory.newActivityModal.sms")}
                  </span>
                </Label>
              </div>
            </FormGroup>
            <FormGroup>
              <Label style={getFontSizeStyle(settingsSelector.FontSize)}>
                {t("timelineHistory.newActivityModal.email")}
              </Label>
              <div className="custom-controls-stacked">
                <Label className="custom-control custom-radio custom-control-inline">
                  <Field name="timeline_action_type_id">
                    {({ field }) => (
                      <input
                        {...field}
                        type="radio"
                        className="custom-control-input"
                        value="6"
                        defaultChecked={
                          timelineActionsFormValues.timeline_action_type_id ===
                          6
                        }
                        onChange={() => {
                          setIsCommentDisabled(true);
                          setCommentActionTypeId("6");
                        }}
                      />
                    )}
                  </Field>
                  <span
                    className="custom-control-label"
                    style={getFontSizeStyle(settingsSelector.FontSize)}
                  >
                    {t("timelineHistory.newActivityModal.email")}
                  </span>
                </Label>
              </div>
            </FormGroup>
            <FormGroup>
              <Label style={getFontSizeStyle(settingsSelector.FontSize)}>
                {t("timelineHistory.newActivityModal.interview")}
              </Label>
              <div className="custom-controls-stacked d-flex">
                <Label className="custom-control custom-radio custom-control-inline">
                  <Field name="timeline_action_type_id">
                    {({ field }) => (
                      <input
                        {...field}
                        type="radio"
                        className="custom-control-input"
                        value="7"
                        defaultChecked={
                          timelineActionsFormValues.timeline_action_type_id ===
                          7
                        }
                        onChange={() => {
                          setIsCommentDisabled(true);
                          setCommentActionTypeId("7");
                        }}
                      />
                    )}
                  </Field>
                  <span
                    className="custom-control-label"
                    style={getFontSizeStyle(settingsSelector.FontSize)}
                  >
                    {t("timelineHistory.newActivityModal.consent")}
                  </span>
                </Label>
                <Label className="custom-control custom-radio custom-control-inline">
                  <Field name="timeline_action_type_id">
                    {({ field }) => (
                      <input
                        {...field}
                        type="radio"
                        className="custom-control-input"
                        value="8"
                        defaultChecked={
                          timelineActionsFormValues.timeline_action_type_id ===
                          8
                        }
                        onChange={() => {
                          setIsCommentDisabled(true);
                          setCommentActionTypeId("8");
                        }}
                      />
                    )}
                  </Field>
                  <span
                    className="custom-control-label"
                    style={getFontSizeStyle(settingsSelector.FontSize)}
                  >
                    {t("timelineHistory.newActivityModal.completed")}
                  </span>
                </Label>
              </div>
            </FormGroup>
            <FormGroup>
              <Label style={getFontSizeStyle(settingsSelector.FontSize)}>
                {t("timelineHistory.newActivityModal.2ndInterview")}
              </Label>
              <div className="custom-controls-stacked">
                <Label className="custom-control custom-radio custom-control-inline">
                  <Field name="timeline_action_type_id">
                    {({ field }) => (
                      <input
                        {...field}
                        type="radio"
                        className="custom-control-input"
                        value="9"
                        defaultChecked={
                          timelineActionsFormValues.timeline_action_type_id ===
                          9
                        }
                        onChange={() => {
                          setIsCommentDisabled(true);
                          setCommentActionTypeId("9");
                        }}
                      />
                    )}
                  </Field>
                  <span
                    className="custom-control-label"
                    style={getFontSizeStyle(settingsSelector.FontSize)}
                  >
                    {t("timelineHistory.newActivityModal.2ndInterview")}
                  </span>
                </Label>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="custom-controls-stacked">
                <Label className="custom-control custom-radio custom-control-inline">
                  <Field name="timeline_action_type_id">
                    {({ field }) => (
                      <input
                        {...field}
                        type="radio"
                        className="custom-control-input"
                        value="10"
                        defaultChecked={
                          timelineActionsFormValues.timeline_action_type_id ===
                          10
                        }
                        onChange={() => {
                          setIsCommentDisabled(false);
                          setCommentActionTypeId("10");
                        }}
                      />
                    )}
                  </Field>
                  <span
                    className="custom-control-label"
                    style={getFontSizeStyle(settingsSelector.FontSize)}
                  >
                    {t("timelineHistory.newActivityModal.comment")}
                  </span>
                </Label>
                <div className={"w-100"}>
                  <Field
                    as={"textarea"}
                    name={"timeline_action_type_comment"}
                    className={"w-100"}
                    disabled={isCommentDisabled}
                  />
                </div>
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              type={"submit"}
              color="primary"
              style={getFontSizeStyle(settingsSelector.FontSize, "14px")}
            >
              {loading ? <Loader /> : <>{t("buttons.save")}</>}
            </Button>{" "}
            <Button
              color="secondary"
              onClick={timelineToggle}
              style={getFontSizeStyle(settingsSelector.FontSize, "14px")}
            >
              {t("buttons.close")}
            </Button>
          </ModalFooter>
        </Form>
      </Formik>
    </Modal>
  );
};

export default TimelineActionModal;
