const JobBlockData = [
  {
    Id: 1,

    job_name: 'Web Developer',

    match: 40,
    status: 'Shortlisted'
  },
  {
    Id: 2,

    job_name: 'Content Writter',

    match: 70,
    status: 'Offers'
  }
]
export default JobBlockData
