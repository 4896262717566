// candidates
export const SUPPORTED_CANDIDATE_PERSONAL_INFO_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/bmp",
  "image/gif",
  "image/svg+xml",
  "image/webp",
];

export const SUPPORTED_CANDIDATE_FILES_FORMATS = [
  "image/jpg", // .jpg
  "image/jpeg", // .jpeg
  "image/png", // .png
  "application/msword", // .doc
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
  "application/vnd.ms-excel.sheet.macroenabled.12", // .xlsm
  "application/pdf", // .pdf
  "application/rtf", // .rtf
  "application/vnd.ms-excel", // .xls
  "text/plain", // .txt
  "text/csv", // .cvs
  "application/zip", // .zip
  "application/x-xdoc", // .xdoc
  "application/vnd.oasis.opendocument.formula", // .odf
];

// company
export const SUPPORTED_COMPANY_FILES_FORMATS = [
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "text/csv",
  "application/zip",
  "application/pdf",
  "image/jpg",
  "image/png",
];

// jobs
export const SUPPORTED_JOBS_FILES_FORMATS = [
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "text/csv",
  "application/zip",
  "application/pdf",
  "image/jpg",
  "image/png",
];

export const SUPPORTED_JOBS_VIDEO_FILES_FORMATS = [
  "video/mp4",
  "video/mov",
  "video/avi",
  "video/webm",
  "video/mkv",
];

// users
export const SUPPORTED_USERS_FILES_FORMATS = [
  "image/jpg",
  "image/png",
  "image/jpeg",
];
