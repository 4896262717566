import React, { useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { baseURL } from "../../components/Shared/baseURL";
import axios from 'axios';

const UpdatePassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [error, setError] = useState('');
  const history = useHistory();

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  }

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  }

  const handleUpdatePassword = () => {
    setError('');

    if (password === confirmPassword) {
      return axios.post(`${baseURL}api/auth/reset-password-from-token`, {
        token: token,
        password: password,
        password_confirmation: confirmPassword,
      })
      .then((response) => {
        if (response.data.code === 1) {
          setUpdateSuccess(true);
          window.alert('Password has been successfully updated');
          history.push('/');
        } else {
          window.alert('Password update failed. Please try again.');
        }
      })
      .catch((error) => {
        if (error.response.data.errors.password && error.response.data.errors.password.length > 0) {
          setError(error.response.data.errors.password[0]);
        }
      });      
    } else {
      window.alert('Password and confirmation do not match.');
    }    
  }  

  return (
    <div className="auth">
      <div className="auth_left">
        <div className="card">
          <div className="text-center mb-5">
            <Link className="header-brand" to="/">
              <i className="fe fe-command brand-logo" />
            </Link>
          </div>
          <div className="card-body">
            <div className="card-title">Uuenda parooli</div>
            {updateSuccess ? (
              <p className="text-success">Parool on edukalt uuendatud!</p>
            ) : (
              <>
                <div className="form-group">
                  <label className="form-label" htmlFor="password">
                    Uus parool
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Sisesta parool"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="confirmPassword">
                    Kinnita parool
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                  />
                </div>
                {error && <p className="text-danger">{error}</p>}
                <div className="form-footer">
                  <button
                    className="btn btn-primary btn-block"
                    onClick={handleUpdatePassword}
                  >
                    Uuenda parooli
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="auth_right">
        <div className="carousel slide" data-ride="carousel" data-interval={3000}>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="assets/images/slider1.svg" className="img-fluid" alt="login page" />
              <div className="px-4 mt-4">
                <h4>MSHR</h4>
              </div>
            </div>
            <div className="carousel-item">
              <img src="assets/images/slider2.svg" className="img-fluid" alt="login page" />
              <div className="px-4 mt-4">
                <h4>MSHR</h4>
              </div>
            </div>
            <div className="carousel-item">
              <img src="assets/images/slider3.svg" className="img-fluid" alt="login page" />
              <div className="px-4 mt-4">
                <h4>MSHR</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePassword;