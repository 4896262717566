// AUTH USERS
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_PROCESSING = "LOGIN_USER_PROCESSING";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";

export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_PROCESSING = "LOGOUT_USER_PROCESSING";
export const LOGOUT_USER_FAILED = "LOGOUT_USER_FAILED";
// AUTH USERS

// ACTIONS
export const GET_ACTIONS_SUCCESS = "GET_ACTIONS_SUCCESS";
export const GET_ACTIONS_PROCESSING = "GET_ACTIONS_PROCESSING";
export const GET_ACTIONS_FAILED = "GET_ACTIONS_FAILED";

// CANDIDATES
export const ADD_CANDIDATE_SUCCESS = "ADD_CANDIDATE_SUCCESS";
export const ADD_CANDIDATE_PROCESSING = "ADD_CANDIDATE_PROCESSING";
export const ADD_CANDIDATE_FAILED = "ADD_CANDIDATE_FAILED";

export const ADD_CANDIDATE_PERSONAL_INFORMATION_SUCCESS =
  "ADD_CANDIDATE_PERSONAL_INFORMATION_SUCCESS";
export const ADD_CANDIDATE_PERSONAL_INFORMATION_PROCESSING =
  "ADD_CANDIDATE_PERSONAL_INFORMATION_PROCESSING";
export const ADD_CANDIDATE_PERSONAL_INFORMATION_FAILED =
  "ADD_CANDIDATE_PERSONAL_INFORMATION_FAILED";

export const ADD_CANDIDATE_ACTION_ID_SUCCESS =
  "ADD_CANDIDATE_ACTION_ID_SUCCESS";
export const ADD_CANDIDATE_ACTION_ID_PROCESSING =
  "ADD_CANDIDATE_ACTION_ID_PROCESSING";
export const ADD_CANDIDATE_ACTION_ID_FAILED = "ADD_CANDIDATE_ACTION_ID_FAILED";

export const CANDIDATE_APPLY_TO_JOB_SUCCESS = "CANDIDATE_APPLY_TO_JOB_SUCCESS";
export const CANDIDATE_APPLY_TO_JOB_PROCESSING =
  "CANDIDATE_APPLY_TO_JOB_PROCESSING";
export const CANDIDATE_APPLY_TO_JOB_FAILED = "CANDIDATE_APPLY_TO_JOB_FAILED";

export const REJECTED_CANDIDATE_ACTION_SUCCESS =
  "REJECTED_CANDIDATE_ACTION_SUCCESS";
export const REJECTED_CANDIDATE_ACTION_PROCESSING =
  "REJECTED_CANDIDATE_ACTION_PROCESSING";
export const REJECTED_CANDIDATE_ACTION_FAILED =
  "REJECTED_CANDIDATE_ACTION_FAILED";

export const ADD_CANDIDATE_JOB_HISTORY_SUCCESS =
  "ADD_CANDIDATE_JOB_HISTORY_SUCCESS";
export const ADD_CANDIDATE_JOB_HISTORY_PROCESSING =
  "ADD_CANDIDATE_JOB_HISTORY_PROCESSING";
export const ADD_CANDIDATE_JOB_HISTORY_FAILED =
  "ADD_CANDIDATE_JOB_HISTORY_FAILED";

export const UPDATE_CANDIDATE_JOB_HISTORY_SUCCESS =
  "UPDATE_CANDIDATE_JOB_HISTORY_SUCCESS";
export const UPDATE_CANDIDATE_JOB_HISTORY_PROCESSING =
  "UPDATE_CANDIDATE_JOB_HISTORY_PROCESSING";
export const UPDATE_CANDIDATE_JOB_HISTORY_FAILED =
  "UPDATE_CANDIDATE_JOB_HISTORY_FAILED";

export const UPDATE_CANDIDATE_LANGUAGE_SUCCESS =
  "UPDATE_CANDIDATE_LANGUAGE_SUCCESS";
export const UPDATE_CANDIDATE_LANGUAGE_PROCESSING =
  "UPDATE_CANDIDATE_LANGUAGE_PROCESSING";
export const UPDATE_CANDIDATE_LANGUAGE_FAILED =
  "UPDATE_CANDIDATE_LANGUAGE_FAILED";

export const DELETE_CANDIDATE_LANGUAGE_SUCCESS =
  "DELETE_CANDIDATE_LANGUAGE_SUCCESS";
export const DELETE_CANDIDATE_LANGUAGE_PROCESSING =
  "DELETE_CANDIDATE_LANGUAGE_PROCESSING";
export const DELETE_CANDIDATE_LANGUAGE_FAILED =
  "DELETE_CANDIDATE_LANGUAGE_FAILED";

export const ADD_CANDIDATE_EDUCATION_SUCCESS =
  "ADD_CANDIDATE_EDUCATION_SUCCESS";
export const ADD_CANDIDATE_EDUCATION_PROCESSING =
  "ADD_CANDIDATE_EDUCATION_PROCESSING";
export const ADD_CANDIDATE_EDUCATION_FAILED = "ADD_CANDIDATE_EDUCATION_FAILED";

export const UPDATE_CANDIDATE_EDUCATION_SUCCESS =
  "UPDATE_CANDIDATE_EDUCATION_SUCCESS";
export const UPDATE_CANDIDATE_EDUCATION_PROCESSING =
  "UPDATE_CANDIDATE_EDUCATION_PROCESSING";
export const UPDATE_CANDIDATE_EDUCATION_FAILED =
  "UPDATE_CANDIDATE_EDUCATION_FAILED";

export const UPDATE_CANDIDATE_ADDITIONAL_COURSES_SUCCESS =
  "UPDATE_CANDIDATE_ADDITIONAL_COURSES_SUCCESS";
export const UPDATE_CANDIDATE_ADDITIONAL_COURSES_PROCESSING =
  "UPDATE_CANDIDATE_ADDITIONAL_COURSES_PROCESSING";
export const UPDATE_CANDIDATE_ADDITIONAL_COURSES_FAILED =
  "UPDATE_CANDIDATE_ADDITIONAL_COURSES_FAILED";

export const DELETE_CANDIDATE_ADDITIONAL_COURSES_SUCCESS =
  "DELETE_CANDIDATE_ADDITIONAL_COURSES_SUCCESS";
export const DELETE_CANDIDATE_ADDITIONAL_COURSES_PROCESSING =
  "DELETE_CANDIDATE_ADDITIONAL_COURSES_PROCESSING";
export const DELETE_CANDIDATE_ADDITIONAL_COURSES_FAILED =
  "DELETE_CANDIDATE_ADDITIONAL_COURSES_FAILED";

export const GET_ADDITIONAL_COURSES_BY_ID_SUCCESS =
  "GET_ADDITIONAL_COURSES_BY_ID_SUCCESS";
export const GET_ADDITIONAL_COURSES_BY_ID_PROCESSING =
  "GET_ADDITIONAL_COURSES_BY_ID_PROCESSING";
export const GET_ADDITIONAL_COURSES_BY_ID_FAILED =
  "GET_ADDITIONAL_COURSES_BY_ID_FAILED";

export const ADD_ADDITIONAL_COURSES_SUCCESS = "ADD_ADDITIONAL_COURSES_SUCCESS";
export const ADD_ADDITIONAL_COURSES_PROCESSING =
  "ADD_ADDITIONAL_COURSES_PROCESSING";
export const ADD_ADDITIONAL_COURSES_FAILED = "ADD_ADDITIONAL_COURSES_FAILED";

export const GET_CANDIDATE_SUCCESS = "GET_CANDIDATE_SUCCESS";
export const GET_CANDIDATE_PROCESSING = "GET_CANDIDATE_PROCESSING";
export const GET_CANDIDATE_FAILED = "GET_CANDIDATE_FAILED";

export const GET_ALL_CANDIDATES_NEW_SUCCESS = "GET_ALL_CANDIDATES_NEW_SUCCESS";
export const GET_ALL_CANDIDATES_NEW_PROCESSING =
  "GET_ALL_CANDIDATES_NEW_PROCESSING";
export const GET_ALL_CANDIDATES_NEW_FAILED = "GET_ALL_CANDIDATES_NEW_FAILED";

export const GET_CANDIDATE_PROFILE_BY_ID_SUCCESS =
  "GET_CANDIDATE_PROFILE_BY_ID_SUCCESS";
export const GET_CANDIDATE_PROFILE_BY_ID_PROCESSING =
  "GET_CANDIDATE_PROFILE_BY_ID_PROCESSING";
export const GET_CANDIDATE_PROFILE_BY_ID_FAILED =
  "GET_CANDIDATE_PROFILE_BY_ID_FAILED";

export const GET_CANDIDATE_EDUCATION_SUCCESS =
  "GET_CANDIDATE_EDUCATION_SUCCESS";
export const GET_CANDIDATE_JOB_HISTORY_SUCCESS =
  "GET_CANDIDATE_JOB_HISTORY_SUCCESS";
export const GET_CANDIDATE_LANGUAGE_BY_ID_SUCCESS =
  "GET_CANDIDATE_LANGUAGE_BY_ID_SUCCESS";

export const DELETE_CANDIDATE_SUCCESS = "DELETE_CANDIDATE_SUCCESS";
export const DELETE_CANDIDATE_PROCESSING = "DELETE_CANDIDATE_PROCESSING";
export const DELETE_CANDIDATE_FAILED = "DELETE_CANDIDATE_FAILED";

export const DELETE_CANDIDATE_JOB_HISTORY_BY_ID_SUCCESS =
  "DELETE_CANDIDATE_JOB_HISTORY_BY_ID_SUCCESS";
export const DELETE_CANDIDATE_JOB_HISTORY_BY_ID_PROCESSING =
  "DELETE_CANDIDATE_JOB_HISTORY_BY_ID_PROCESSING";
export const DELETE_CANDIDATE_JOB_HISTORY_BY_ID_FAILED =
  "DELETE_CANDIDATE_JOB_HISTORY_BY_ID_FAILED";

export const DELETE_CANDIDATE_EDUCATION_BY_ID_SUCCESS =
  "DELETE_CANDIDATE_EDUCATION_BY_ID_SUCCESS";
export const DELETE_CANDIDATE_EDUCATION_BY_ID_PROCESSING =
  "DELETE_CANDIDATE_EDUCATION_BY_ID_PROCESSING";
export const DELETE_CANDIDATE_EDUCATION_BY_ID_FAILED =
  "DELETE_CANDIDATE_EDUCATION_BY_ID_FAILED";

export const GET_CANDIDATE_TIMELINE_SUCCESS = "GET_CANDIDATE_TIMELINE_SUCCESS";
export const GET_CANDIDATE_TIMELINE_PROCESSING =
  "GET_CANDIDATE_TIMELINE_PROCESSING";
export const GET_CANDIDATE_TIMELINE_FAILED = "GET_CANDIDATE_TIMELINE_FAILED";

export const GET_CANDIDATE_APPLIED_JOBS_SUCCESS =
  "GET_CANDIDATE_APPLIED_JOBS_SUCCESS";
export const GET_CANDIDATE_APPLIED_JOBS_PROCESSING =
  "GET_CANDIDATE_APPLIED_JOBS_PROCESSING";
export const GET_CANDIDATE_APPLIED_JOBS_FAILED =
  "GET_CANDIDATE_APPLIED_JOBS_FAILED";

export const GET_CANDIDATE_PREVIOUS_PHASE_JOBS_SUCCESS =
  "GET_CANDIDATE_PREVIOUS_PHASE_JOBS_SUCCESS";
export const GET_CANDIDATE_PREVIOUS_PHASE_JOBS_PROCESSING =
  "GET_CANDIDATE_PREVIOUS_PHASE_JOBS_PROCESSING";
export const GET_CANDIDATE_PREVIOUS_PHASE_JOBS_FAILED =
  "GET_CANDIDATE_PREVIOUS_PHASE_JOBS_FAILED";

// BEST MATCHING
export const FETCH_BEST_MATCH_JOBS_SUCCESS = "FETCH_BEST_MATCH_JOBS_SUCCESS";
export const FETCH_BEST_MATCH_JOBS_PROCESSING =
  "FETCH_BEST_MATCH_JOBS_PROCESSING";
export const FETCH_BEST_MATCH_JOBS_FAILED = "FETCH_BEST_MATCH_JOBS_FAILED";

export const FETCH_BEST_MATCH_CANDIDATES_SUCCESS =
  "FETCH_BEST_MATCH_CANDIDATES_SUCCESS";
export const FETCH_BEST_MATCH_CANDIDATES_PROCESSING =
  "FETCH_BEST_MATCH_CANDIDATES_PROCESSING";
export const FETCH_BEST_MATCH_CANDIDATES_FAILED =
  "FETCH_BEST_MATCH_CANDIDATES_FAILED";

// JOBS
export const ADD_JOBS_SUCCESS = "ADD_JOBS_SUCCESS";
export const ADD_JOBS_PROCESSING = "ADD_JOBS_PROCESSING";
export const ADD_JOBS_FAILED = "ADD_JOBS_FAILED";
export const ADD_JOBS_UPDATE_SUCCESS = "ADD_JOBS_UPDATE_SUCCESS";
export const ADD_JOBS_UPDATE_Failure = "ADD_JOBS_UPDATE_Failure";

export const GET_JOBS_SUCCESS = "GET_JOBS_SUCCESS";
export const GET_JOBS_PROCESSING = "GET_JOBS_PROCESSING";
export const GET_JOBS_FAILED = "GET_JOBS_FAILED";

export const GET_ALL_ACTIVE_JOBS_SUCCESS = "GET_ALL_ACTIVE_JOBS_SUCCESS";
export const GET_ALL_ACTIVE_JOBS_PROCESSING = "GET_ALL_ACTIVE_JOBS_PROCESSING";
export const GET_ALL_ACTIVE_JOBS_FAILED = "GET_ALL_ACTIVE_JOBS_FAILED";

export const GET_JOBS_APPLICANTS_SUCCESS = "GET_JOBS_APPLICANTS_SUCCESS";
export const GET_JOBS_APPLICANTS_PROCESSING = "GET_JOBS_APPLICANTS_PROCESSING";
export const GET_JOBS_APPLICANTS_FAILED = "GET_JOBS_APPLICANTS_FAILED";

export const GET_JOB_BY_ID_SUCCESS = "GET_JOB_BY_ID_SUCCESS";
export const GET_JOB_BY_ID_PROCESSING = "GET_JOB_BY_ID_PROCESSING";
export const GET_JOB_BY_ID_FAILED = "GET_JOB_BY_ID_FAILED";

export const GET_DESIRED_JOBS_Lang_SUCCESS = "GET_DESIRED_JOBS_Lang_SUCCESS";
export const GET_WORK_JOBS_Lang_SUCCESS = "GET_WORK_JOBS_Lang_SUCCESS";
export const GET_SHIFTS_SUCCESS = "GET_SHIFTS_SUCCESS";

export const GET_JOBSACTIVITY_SUCCESS = "GET_JOBSACTIVITY_SUCCESS";

export const GET_JOBSCANDIDATES_SUCCESS = "GET_JOBSCANDIDATES_SUCCESS";

export const GET_JOBS_TIMELINE_SUCCESS = "GET_JOBS_TIMELINE_SUCCESS";
export const GET_JOBS_TIMELINE_PROCESSING = "GET_JOBS_TIMELINE_PROCESSING";
export const GET_JOBS_TIMELINE_FAILED = "GET_JOBS_TIMELINE_FAILED";

export const UPDATE_JOBS_SUCCESS = "UPDATE_JOBS_SUCCESS";
export const UPDATE_JOBS_PROCESSING = "UPDATE _JOBS_PROCESSING";
export const UPDATE_JOBS_FAILED = "UPDATE _JOBS_FAILED";

export const DUPLICATE_JOB_SUCCESS = "DUPLICATE_JOB_SUCCESS";
export const DUPLICATE_JOB_PROCESSING = "DUPLICATE _JOS_PROCESSING";
export const DUPLICATE_JOB_FAILED = "DUPLICATE _JOS_FAILED";

export const DELETE_JOBS_SUCCESS = "DELETE_JOBS_SUCCESS";
export const DELETE_JOBS_PROCESSING = "DELETE_JOBS_PROCESSING";
export const DELETE_JOBS_FAILED = "DELETE_JOBS_FAILED";

export const DELETE_Work_Lang_SUCCESS = "DELETE_Work_Lang_SUCCESS";
export const DELETE_Desired_Lang_SUCCESS = "DELETE_Desired_Lang_SUCCESS";
export const DELETE_JOBS_Shifts_SUCCESS = "DELETE_JOBS_Shifts_SUCCESS";

export const DELETE_JOB_SINGLE_VIDEO_SUCCESS =
  "DELETE_JOB_SINGLE_VIDEO_SUCCESS";
export const DELETE_JOB_SINGLE_VIDEO_PROCESSING =
  "DELETE_JOB_SINGLE_VIDEO_PROCESSING";
export const DELETE_JOB_SINGLE_VIDEO_FAILED = "DELETE_JOB_SINGLE_VIDEO_FAILED";

export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_PROCESSING = "GET_USERS_PROCESSING";
export const GET_USERS_FAILED = "GET_USERS_FAILED";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_USERS_PROFILE_SUCCESS = "GET_USERS_PROFILE_SUCCESS";

export const GET_SINGLE_USER_SUCCESS = "GET_SINGLE_USER_SUCCESS";
export const GET_SINGLE_USER_PROCESSING = "GET_SINGLE_USER_PROCESSING";
export const GET_SINGLE_USER_FAILED = "GET_SINGLE_USER_FAILED";

export const UPDATE_USERS_PROFILE_SUCCESS = "UPDATE_USERS_PROFILE_SUCCESS";
export const UPDATE_USERS_PROFILE_PROCESSING =
  "UPDATE_USERS_PROFILE_PROCESSING";
export const UPDATE_USERS_PROFILE_FAILED = "UPDATE_USERS_PROFILE_FAILED";

export const CHANGE_USER_PASSWORD_SUCCESS = "CHANGE_USER_PASSWORD_SUCCESS";
export const CHANGE_USER_PASSWORD_PROCESSING =
  "CHANGE_USER_PASSWORD_PROCESSING";
export const CHANGE_USER_PASSWORD_FAILED = "CHANGE_USER_PASSWORD_FAILED";

export const ADD_NEW_USER_SUCCESS = "ADD_NEW_USER_SUCCESS";
export const ADD_NEW_USER_PROCESSING = "ADD_NEW_USER_PROCESSING";
export const ADD_NEW_USER_FAILED = "ADD_NEW_USER_FAILED";

export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_PROCESSING = "UPDATE_USER_PROCESSING";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";

export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_PROCESSING = "DELETE_USER_PROCESSING";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";

export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS";

export const ADD_CANDIDATE_LANGUAGE_SUCCESS = "ADD_CANDIDATE_LANGUAGE_SUCCESS";
export const ADD_CANDIDATE_LANGUAGE_PROCESSING =
  "ADD_CANDIDATE_LANGUAGE_PROCESSING";
export const ADD_CANDIDATE_LANGUAGE_FAILED = "ADD_CANDIDATE_LANGUAGE_FAILED";

export const ADD_CANDIDATE_LANGUAGE_MANUALLY_SUCCESS =
  "ADD_CANDIDATE_LANGUAGE_MANUALLY_SUCCESS";
export const ADD_CANDIDATE_LANGUAGE_MANUALLY_PROCESSING =
  "ADD_CANDIDATE_LANGUAGE_MANUALLY_PROCESSING";
export const ADD_CANDIDATE_LANGUAGE_MANUALLY_FAILED =
  "ADD_CANDIDATE_LANGUAGE_MANUALLY_FAILED";

export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";

export const GET_SKILLS_SUCCESS = "GET_SKILLS_SUCCESS";
export const GET_CANDIDATE_SKILLS_SUCCESS = "GET_CANDIDATE_SKILLS_SUCCESS";

export const ADD_SKILLS_SUCCESS = "ADD_SKILLS_SUCCESS";
export const ADD_SKILLS_PROCESSING = "ADD_SKILLS_PROCESSING";
export const ADD_SKILLS_FAILED = "ADD_SKILLS_FAILED";

export const UPDATE_CANDIDATE_SKILLS_SUCCESS =
  "UPDATE_CANDIDATE_SKILLS_SUCCESS";
export const UPDATE_CANDIDATE_SKILLS_PROCESSING =
  "UPDATE_CANDIDATE_SKILLS_PROCESSING";
export const UPDATE_CANDIDATE_SKILLS_FAILED = "UPDATE_CANDIDATE_SKILLS_FAILED";

export const UPLOAD_FILES_SUCCESS = "UPLOAD_FILES_SUCCESS";
export const UPLOAD_FILES_PROCESSING = "UPLOAD_FILES_PROCESSING";
export const UPLOAD_FILES_FAILED = "UPLOAD_FILES_FAILED";

export const DELETE_UPLOAD_FILES_SUCCESS = "DELETE_UPLOAD_FILES_SUCCESS";
export const DELETE_UPLOAD_FILES_PROCESSING = "DELETE_UPLOAD_FILES_PROCESSING";
export const DELETE_UPLOAD_FILES_FAILED = "DELETE_UPLOAD_FILES_FAILED";

export const GET_CANDIDATE_AGREEMENT_SUCCESS =
  "GET_CANDIDATE_AGREEMENT_SUCCESS";
export const GET_CANDIDATE_AGREEMENT_PROCESSING =
  "GET_CANDIDATE_AGREEMENT_PROCESSING";
export const GET_CANDIDATE_AGREEMENT_FAILED = "GET_CANDIDATE_AGREEMENT_FAILED";

export const CREATE_CANDIDATE_AGREEMENT_SUCCESS =
  "CREATE_CANDIDATE_AGREEMENT_SUCCESS";
export const CREATE_CANDIDATE_AGREEMENT_PROCESSING =
  "CREATE_CANDIDATE_AGREEMENT_PROCESSING";
export const CREATE_CANDIDATE_AGREEMENT_FAILED =
  "CREATE_CANDIDATE_AGREEMENT_FAILED";

export const DELETE_CANDIDATE_AGREEMENT_SUCCESS =
  "DELETE_CANDIDATE_AGREEMENT_SUCCESS";
export const DELETE_CANDIDATE_AGREEMENT_PROCESSING =
  "DELETE_CANDIDATE_AGREEMENT_PROCESSING";
export const DELETE_CANDIDATE_AGREEMENT_FAILED =
  "DELETE_CANDIDATE_AGREEMENT_FAILED";

export const GET_UPLOAD_FILES_BY_ID_SUCCESS = "GET_UPLOAD_FILES_BY_ID_SUCCESS";
export const GET_UPLOAD_FILES_BY_ID_PROCESSING =
  "GET_UPLOAD_FILES_BY_ID_PROCESSING";
export const GET_UPLOAD_FILES_BY_ID_FAILED = "GET_UPLOAD_FILES_BY_ID_FAILED";

// CANDIDATE PDF DOWNLOAD
export const DOWNLOAD_CANDIDATE_CV_PDF_SUCCESS =
  "DOWNLOAD_CANDIDATE_CV_PDF_SUCCESS";
export const DOWNLOAD_CANDIDATE_CV_PDF_PROCESSING =
  "DOWNLOAD_CANDIDATE_CV_PDF_PROCESSING";
export const DOWNLOAD_CANDIDATE_CV_PDF_FAILED =
  "DOWNLOAD_CANDIDATE_CV_PDF_FAILED";
// CANDIDATE PDF DOWNLOAD

// CANDIDATE CUSTOM LIST
export const GET_CUSTOM_LIST_SUCCESS = "GET_CUSTOM_LIST_SUCCESS";
export const GET_CUSTOM_LIST_PROCESSING = "GET_CUSTOM_LIST_PROCESSING";
export const GET_CUSTOM_LIST_FAILED = "GET_CUSTOM_LIST_FAILED";

export const CREATE_CUSTOM_LIST_SUCCESS = "CREATE_CUSTOM_LIST_SUCCESS";
export const CREATE_CUSTOM_LIST_PROCESSING = "CREATE_CUSTOM_LIST_PROCESSING";
export const CREATE_CUSTOM_LIST_FAILED = "CREATE_CUSTOM_LIST_FAILED";

export const DELETE_SINGLE_CUSTOM_LIST_SUCCESS =
  "DELETE_SINGLE_CUSTOM_LIST_SUCCESS";
export const DELETE_SINGLE_CUSTOM_LIST_PROCESSING =
  "DELETE_SINGLE_CUSTOM_LIST_PROCESSING";
export const DELETE_SINGLE_CUSTOM_LIST_FAILED =
  "DELETE_SINGLE_CUSTOM_LIST_FAILED";

export const UPDATE_CUSTOM_LIST_NAME_SUCCESS =
  "UPDATE_CUSTOM_LIST_NAME_SUCCESS";
export const UPDATE_CUSTOM_LIST_NAME_PROCESSING =
  "UPDATE_CUSTOM_LIST_NAME_PROCESSING";
export const UPDATE_CUSTOM_LIST_NAME_FAILED = "UPDATE_CUSTOM_LIST_NAME_FAILED";

export const UPDATE_CUSTOM_LIST_SUCCESS = "UPDATE_CUSTOM_LIST_SUCCESS";
export const UPDATE_CUSTOM_LIST_PROCESSING = "UPDATE_CUSTOM_LIST_PROCESSING";
export const UPDATE_CUSTOM_LIST_FAILED = "UPDATE_CUSTOM_LIST_FAILED";

export const DELETE_CUSTOM_LIST_NAME_SUCCESS =
  "DELETE_CUSTOM_LIST_NAME_SUCCESS";
export const DELETE_CUSTOM_LIST_NAME_PROCESSING =
  "DELETE_CUSTOM_LIST_NAME_PROCESSING";
export const DELETE_CUSTOM_LIST_NAME_FAILED = "DELETE_CUSTOM_LIST_NAME_FAILED";

// CANDIDATE CUSTOM LIST MULTIPLE
export const CREATE_CUSTOM_LIST_MULTIPLE_SUCCESS =
  "CREATE_CUSTOM_LIST_MULTIPLE_SUCCESS";
export const CREATE_CUSTOM_LIST_MULTIPLE_PROCESSING =
  "CREATE_CUSTOM_LIST_MULTIPLE_PROCESSING";
export const CREATE_CUSTOM_LIST_MULTIPLE_FAILED =
  "CREATE_CUSTOM_LIST_MULTIPLE_FAILED";

export const REMOVE_CUSTOM_LIST_MULTIPLE_SUCCESS =
  "REMOVE_CUSTOM_LIST_MULTIPLE_SUCCESS";
export const REMOVE_CUSTOM_LIST_MULTIPLE_PROCESSING =
  "REMOVE_CUSTOM_LIST_MULTIPLE_PROCESSING";
export const REMOVE_CUSTOM_LIST_MULTIPLE_FAILED =
  "REMOVE_CUSTOM_LIST_MULTIPLE_FAILED";
// CANDIDATE CUSTOM LIST MULTIPLE

// CANDIDATE CUSTOM LIST

// ROLES
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_PROCESSING = "GET_ROLES_PROCESSING";
export const GET_ROLES_FAILED = "GET_ROLES_FAILED";

export const GET_SINGLE_ROLE_SUCCESS = "GET_SINGLE_ROLE_SUCCESS";
export const GET_SINGLE_ROLE_PROCESSING = "GET_SINGLE_ROLE_PROCESSING";
export const GET_SINGLE_ROLE_FAILED = "GET_SINGLE_ROLE_FAILED";

export const POST_USER_ROLES_SUCCESS = "POST_USER_ROLES_SUCCESS";
export const POST_USER_ROLES_PROCESSING = "POST_USER_ROLES_PROCESSING";
export const POST_USER_ROLES_FAILED = "POST_USER_ROLES_FAILED";

export const UPDATE_USER_ROLES_SUCCESS = "UPDATE_USER_ROLES_SUCCESS";
export const UPDATE_USER_ROLES_PROCESSING = "UPDATE_USER_ROLES_PROCESSING";
export const UPDATE_USER_ROLES_FAILED = "UPDATE_USER_ROLES_FAILED";

export const DELETE_USER_ROLES_SUCCESS = "DELETE_USER_ROLES_SUCCESS";
export const DELETE_USER_ROLES_PROCESSING = "DELETE_USER_ROLES_PROCESSING";
export const DELETE_USER_ROLES_FAILED = "DELETE_USER_ROLES_FAILED";
// ROLES

//PERMISSIONS
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";
export const GET_PERMISSIONS_PROCESSING = "GET_PERMISSION_PROCESSING";
export const GET_PERMISSIONS_FAILED = "GET_PERMISSION_FAILED";

export const ASSIGN_PERMISSIONS_TO_ROLE_SUCCESS =
  "ASSIGN_PERMISSIONS_TO_ROLE_SUCCESS";
export const ASSIGN_PERMISSIONS_TO_ROLE_PROCESSING =
  "ASSIGN_PERMISSIONS_TO_ROLE_PROCESSING";
export const ASSIGN_PERMISSIONS_TO_ROLE_FAILED =
  "ASSIGN_PERMISSIONS_TO_ROLE_FAILED";

export const ASSIGN_PERMISSIONS_TO_USER_SUCCESS =
  "ASSIGN_PERMISSIONS_TO_USER_SUCCESS";
export const ASSIGN_PERMISSIONS_TO_USER_PROCESSING =
  "ASSIGN_PERMISSIONS_TO_USER_PROCESSING";
export const ASSIGN_PERMISSIONS_TO_USER_FAILED =
  "ASSIGN_PERMISSIONS_TO_USER_FAILED";
//PERMISSIONS

export const GET_TIMEZONE = "GET_TIMEZONE";

export const GET_JOB_TYPES = "GET_JOB_TYPES";

export const GET_STATUS = "GET_STATUS";
export const GET_EDUCATION_DEGREE = "GET_EDUCATION_DEGREE";

export const GET_CANDIDATE_EMAIL_TEMPLATE_DATA_SUCCESS =
  "GET_CANDIDATE_EMAIL_TEMPLATE_DATA_SUCCESS";
export const GET_CANDIDATE_EMAIL_TEMPLATE_DATA_PROCESSING =
  "GET_CANDIDATE_EMAIL_TEMPLATE_DATA_PROCESSING";
export const GET_CANDIDATE_EMAIL_TEMPLATE_DATA_FAILED =
  "GET_CANDIDATE_EMAIL_TEMPLATE_DATA_FAILED";

// EMAIL TEMPLATE
export const GET_EMAIL_TEMPLATES_SUCCESS = "GET_EMAIL_TEMPLATES_SUCCESS";
export const GET_EMAIL_TEMPLATES_PROCESSING = "GET_EMAIL_TEMPLATES_PROCESSING";
export const GET_EMAIL_TEMPLATES_FAILED = "GET_EMAIL_TEMPLATES_FAILED";

export const GET_EMAIL_TEMPLATES_LINK_SUCCESS =
  "GET_EMAIL_TEMPLATES_LINK_SUCCESS";
export const GET_EMAIL_TEMPLATES_LINK_PROCESSING =
  "GET_EMAIL_TEMPLATES_LINK_PROCESSING";
export const GET_EMAIL_TEMPLATES_LINK_FAILED =
  "GET_EMAIL_TEMPLATES_LINK_FAILED";

export const GET_EMAIL_VERIFICATION_TEMPLATES_LINK_SUCCESS =
  "GET_EMAIL_VERIFICATION_TEMPLATES_LINK_SUCCESS";
export const GET_EMAIL_VERIFICATION_TEMPLATES_LINK_PROCESSING =
  "GET_EMAIL_VERIFICATION_TEMPLATES_LINK_PROCESSING";
export const GET_EMAIL_VERIFICATION_TEMPLATES_LINK_FAILED =
  "GET_EMAIL_VERIFICATION_TEMPLATES_LINK_FAILED";

export const GET_CANDIDATE_EMAIL_TEMPLATES_LINK_SUCCESS =
  "GET_CANDIDATE_EMAIL_TEMPLATES_LINK_SUCCESS";
export const GET_CANDIDATE_EMAIL_TEMPLATES_LINK_PROCESSING =
  "GET_CANDIDATE_EMAIL_TEMPLATES_LINK_PROCESSING";
export const GET_CANDIDATE_EMAIL_TEMPLATES_LINK_FAILED =
  "GET_CANDIDATE_EMAIL_TEMPLATES_LINK_FAILED";

export const ADD_EMAIL_TEMPLATES_SUCCESS = "ADD_EMAIL_TEMPLATES_SUCCESS";
export const ADD_EMAIL_TEMPLATES_PROCESSING = "ADD_EMAIL_TEMPLATES_PROCESSING";
export const ADD_EMAIL_TEMPLATES_FAILED = "ADD_EMAIL_TEMPLATES_FAILED";

export const UPDATE_EMAIL_TEMPLATES_SUCCESS = "UPDATE_EMAIL_TEMPLATES_SUCCESS";
export const UPDATE_EMAIL_TEMPLATES_PROCESSING =
  "UPDATE_EMAIL_TEMPLATES_PROCESSING";
export const UPDATE_EMAIL_TEMPLATES_FAILED = "UPDATE_EMAIL_TEMPLATES_FAILED";

export const DELETE_EMAIL_TEMPLATES_SUCCESS = "DELETE_EMAIL_TEMPLATES_SUCCESS";
export const DELETE_EMAIL_TEMPLATES_PROCESSING =
  "DELETE_EMAIL_TEMPLATES_PROCESSING";
export const DELETE_EMAIL_TEMPLATES_FAILED = "DELETE_EMAIL_TEMPLATES_FAILED";

export const GET_WILDCARDS_SUCCESS = "GET_WILDCARDS_SUCCESS";
export const GET_WILDCARDS_PROCESSING = "GET_WILDCARDS_PROCESSING";
export const GET_WILDCARDS_FAILED = "GET_WILDCARDS_FAILED";
// EMAIL TEMPLATE

// COMPANY
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_PROCESSING = "GET_COMPANIES_PROCESSING";
export const GET_COMPANIES_FAILED = "GET_COMPANIES_FAILED";

export const FETCH_COMPANY_DATA_SUCCESS = "FETCH_COMPANY_DATA_SUCCESS";
export const FETCH_COMPANY_DATA_PROCESSING = "FETCH_COMPANY_DATA_PROCESSING";
export const FETCH_COMPANY_DATA_FAILED = "FETCH_COMPANY_DATA_FAILED";

export const GET_COMPANIES_JOBS_SUCCESS = "GET_COMPANIES_JOBS_SUCCESS";
export const GET_COMPANIES_JOBS_PROCESSING = "GET_COMPANIES_JOBS_PROCESSING";
export const GET_COMPANIES_JOBS_FAILED = "GET_COMPANIES_JOBS_FAILED";

export const GET_COMPANY_BY_ID_SUCCESS = "GET_COMPANY_BY_ID_SUCCESS";
export const GET_COMPANY_BY_ID_PROCESSING = "GET_COMPANY_BY_ID_PROCESSING";
export const GET_COMPANY_BY_ID_FAILED = "GET_COMPANY_BY_ID_FAILED";

export const GET_COMPANY_RELEVANT_JOBS_SUCCESS =
  "GET_COMPANY_RELEVANT_JOBS_SUCCESS";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_RELEVANT_JOBS_PROCESSING =
  "GET_COMPANY_RELEVANT_JOBS_PROCESSING";
export const GET_COMPANY_RELEVANT_JOBS_FAILED =
  "GET_COMPANY_RELEVANT_JOBS_FAILED";
export const GET_COMPANY_FAILED = "GET_COMPANY_FAILED";

export const GET_EMAIL_TEMPLATE_BY_ID_SUCCESS =
  "GET_EMAIL_TEMPLATE_BY_ID_SUCCESS";
export const GET_EMAIL_TEMPLATE_BY_ID_PROCESSING =
  "GET_EMAIL_TEMPLATE_BY_ID_PROCESSING";
export const GET_EMAIL_TEMPLATE_BY_ID_FAILED =
  "GET_EMAIL_TEMPLATE_BY_ID_FAILED";

export const GET_COMPANY_EMAIL_TEMPLATE_DATA_SUCCESS =
  "GET_COMPANY_EMAIL_TEMPLATE_DATA_SUCCESS";
export const GET_COMPANY_EMAIL_TEMPLATE_DATA_PROCESSING =
  "GET_COMPANY_EMAIL_TEMPLATE_DATA_PROCESSING";
export const GET_COMPANY_EMAIL_TEMPLATE_DATA_FAILED =
  "GET_COMPANY_EMAIL_TEMPLATE_DATA_FAILED";

export const GET_COMPANY_TIMELINE_SUCCESS = "GET_COMPANY_TIMELINE_SUCCESS";
export const GET_COMPANY_TIMELINE_PROCESSING =
  "GET_COMPANY_TIMELINE_PROCESSING";
export const GET_COMPANY_TIMELINE_FAILED = "GET_COMPANY_TIMELINE_FAILED";

export const POST_COMPANIES_SUCCESS = "POST_COMPANIES_SUCCESS";
export const POST_COMPANIES_PROCESSING = "POST_COMPANIES_PROCESSING";
export const POST_COMPANIES_FAILED = "POST_COMPANIES_FAILED";

export const POST_COMPANY_TIMELINE_COMMENTS_SUCCESS =
  "POST_COMPANY_TIMELINE_COMMENTS_SUCCESS";
export const POST_COMPANY_TIMELINE_COMMENTS_PROCESSING =
  "POST_COMPANY_TIMELINE_COMMENTS_PROCESSING";
export const POST_COMPANY_TIMELINE_COMMENTS_FAILED =
  "POST_COMPANY_TIMELINE_COMMENTS_FAILED";

export const GET_COMPANIES_INDUSTRY_SUCCESS = "GET_COMPANIES_INDUSTRY_SUCCESS";
export const GET_COMPANIES_INDUSTRY_PROCESSING =
  "GET_COMPANIES_INDUSTRY_PROCESSING";
export const GET_COMPANIES_INDUSTRY_FAILED = "GET_COMPANIES_INDUSTRY_FAILED";

export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_PROCESSING = "UPDATE_COMPANY_PROCESSING";
export const UPDATE_COMPANY_FAILED = "UPDATE_COMPANY_FAILED";

export const DELETE_COMPANIES_SUCCESS = "DELETE_COMPANIES_SUCCESS";
export const DELETE_COMPANIES_PROCESSING = "DELETE_COMPANIES_PROCESSING";
export const DELETE_COMPANIES_FAILED = "DELETE_COMPANIES_FAILED";

export const DELETE_VIEW_COMPANIES_UPLOADS_SUCCESS =
  "DELETE_VIEW_COMPANIES_UPLOADS_SUCCESS";
export const DELETE_VIEW_COMPANIES_UPLOADS_PROCESSING =
  "DELETE_VIEW_COMPANIES_UPLOADS_PROCESSING";
export const DELETE_VIEW_COMPANIES_UPLOADS_FAILED =
  "DELETE_VIEW_COMPANIES_UPLOADS_FAILED";

export const Add_NEW_COMPANY_CONTACT_SUCCESS =
  "Add_NEW_COMPANY_CONTACT_SUCCESS";
export const Add_NEW_COMPANY_CONTACT_PROCESSING =
  "Add_NEW_COMPANY_CONTACT_PROCESSING";
export const Add_NEW_COMPANY_CONTACT_FAILED = "Add_NEW_COMPANY_CONTACT_FAILED";
// COMPANY

export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_PROCESSING = "SEND_EMAIL_PROCESSING";
export const SEND_EMAIL_FAILED = "SEND_EMAIL_FAILED";

export const SEND_COMPANY_EMAIL_LINK_SUCCESS =
  "SEND_COMPANY_EMAIL_LINK_SUCCESS";
export const SEND_COMPANY_EMAIL_LINK_PROCESSING =
  "SEND_COMPANY_EMAIL_LINK_PROCESSING";
export const SEND_COMPANY_EMAIL_LINK_FAILED = "SEND_COMPANY_EMAIL_LINK_FAILED";

//IMPORT DATA FROM FILE
export const IMPORT_DATA_FROM_FILE_SUCCESS = "IMPORT_DATA_FROM_FILE_SUCCESS";
export const IMPORT_DATA_FROM_FILE_PROCESSING =
  "IMPORT_DATA_FROM_FILE_PROCESSING";
export const IMPORT_DATA_FROM_FILE_FAILED = "IMPORT_DATA_FROM_FILE_FAILED";
//IMPORT DATA FROM FILE

// TIMELINE COMMENTS
export const POST_TIMELINE_COMMENTS_SUCCESS = "POST_TIMELINE_COMMENTS_SUCCESS";
export const POST_TIMELINE_COMMENTS_PROCESSING =
  "POST_TIMELINE_COMMENTS_PROCESSING";
export const POST_TIMELINE_COMMENTS_FAILED = "POST_TIMELINE_COMMENTS_FAILED";
// TIMELINE COMMENTS

// TASK MANAGEMENT
export const GET_ALL_TASKS_SUCCESS = "GET_ALL_TASKS_SUCCESS";
export const GET_ALL_TASKS_PROCESSING = "GET_ALL_TASKS_PROCESSING";
export const GET_ALL_TASKS_FAILED = "GET_ALL_TASKS_FAILED";

export const GET_ALL_TASKS_COUNTS_SUCCESS = "GET_ALL_TASKS_COUNTS_SUCCESS";
export const GET_ALL_TASKS_COUNTS_PROCESSING =
  "GET_ALL_TASKS_COUNTS_PROCESSING";
export const GET_ALL_TASKS_COUNTS_FAILED = "GET_ALL_TASKS_COUNTS_FAILED";

export const GET_ALL_CREATED_TASKS_SUCCESS = "GET_ALL_CREATED_TASKS_SUCCESS";
export const GET_ALL_CREATED_TASKS_PROCESSING =
  "GET_ALL_CREATED_TASKS_PROCESSING";
export const GET_ALL_CREATED_TASKS_FAILED = "GET_ALL_CREATED_TASKS_FAILED";

export const GET_ALL_ASSIGNED_TASKS_SUCCESS = "GET_ALL_ASSIGNED_TASKS_SUCCESS";
export const GET_ALL_ASSIGNED_TASKS_PROCESSING =
  "GET_ALL_ASSIGNED_TASKS_PROCESSING";
export const GET_ALL_ASSIGNED_TASKS_FAILED = "GET_ALL_ASSIGNED_TASKS_FAILED";

export const GET_ALL_NOTIFICATIONS_SUCCESS = "GET_ALL_NOTIFICATIONS_SUCCESS";
export const GET_ALL_NOTIFICATIONS_PROCESSING =
  "GET_ALL_NOTIFICATIONS_PROCESSING";
export const GET_ALL_NOTIFICATIONS_FAILED = "GET_ALL_NOTIFICATIONS_FAILED";

export const GET_NOTIFICATIONS_COUNT_SUCCESS =
  "GET_NOTIFICATIONS_COUNT_SUCCESS";
export const GET_NOTIFICATIONS_COUNT_PROCESSING =
  "GET_NOTIFICATIONS_COUNT_PROCESSING";
export const GET_NOTIFICATIONS_COUNT_FAILED = "GET_NOTIFICATIONS_COUNT_FAILED";

export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS";
export const CREATE_TASK_PROCESSING = "CREATE_TASK_PROCESSING";
export const CREATE_TASK_FAILED = "CREATE_TASK_FAILED";

export const MARK_TASK_COMPLETE_SUCCESS = "MARK_TASK_COMPLETE_SUCCESS";
export const MARK_TASK_COMPLETE_PROCESSING = "MARK_TASK_COMPLETE_PROCESSING";
export const MARK_TASK_COMPLETE_FAILED = "MARK_TASK_COMPLETE_FAILED";

export const MARK_ALL_NOTIFICATION_READ_SUCCESS =
  "MARK_ALL_NOTIFICATION_READ_SUCCESS";
export const MARK_ALL_NOTIFICATION_READ_PROCESSING =
  "MARK_ALL_NOTIFICATION_READ_PROCESSING";
export const MARK_ALL_NOTIFICATION_READ_FAILED =
  "MARK_ALL_NOTIFICATION_READ_FAILED";

export const MARK_NOTIFICATION_READ_SUCCESS = "MARK_NOTIFICATION_READ_SUCCESS";
export const MARK_NOTIFICATION_READ_PROCESSING =
  "MARK_NOTIFICATION_READ_PROCESSING";
export const MARK_NOTIFICATION_READ_FAILED = "MARK_NOTIFICATION_READ_FAILED";

export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const DELETE_TASK_PROCESSING = "DELETE_TASK_PROCESSING";
export const DELETE_TASK_FAILED = "DELETE_TASK_FAILED";

export const GET_TASK_MENTIONS_SUCCESS = "GET_TASK_MENTIONS_SUCCESS";
export const GET_TASK_MENTIONS_PROCESSING = "GET_TASK_MENTIONS_PROCESSING";
export const GET_TASK_MENTIONS_FAILED = "GET_TASK_MENTIONS_FAILED";
// TASK MANAGEMENT

// NATIONALITY
export const GET_NATIONALITIES_SUCCESS = "GET_NATIONALITIES_SUCCESS";
export const GET_NATIONALITIES_PROCESSING = "GET_NATIONALITIES_PROCESSING";
export const GET_NATIONALITIES_FAILED = "GET_NATIONALITIES_FAILED";
// NATIONALITY

// GOOGLE CALENDAR
export const GET_CALENDAR_EVENT_SUCCESS = "GET_CALENDAR_EVENT_SUCCESS";
export const GET_CALENDAR_EVENT_PROCESSING = "GET_CALENDAR_EVENT_PROCESSING";
export const GET_CALENDAR_EVENT_FAILED = "GET_CALENDAR_EVENT_FAILED";

export const CREATE_CALENDAR_EVENT_SUCCESS = "CREATE_CALENDAR_EVENT_SUCCESS";
export const CREATE_CALENDAR_EVENT_PROCESSING =
  "CREATE_CALENDAR_EVENT_PROCESSING";
export const CREATE_CALENDAR_EVENT_FAILED = "CREATE_CALENDAR_EVENT_FAILED";

export const UPDATE_CALENDAR_EVENT_SUCCESS = "UPDATE_CALENDAR_EVENT_SUCCESS";
export const UPDATE_CALENDAR_EVENT_PROCESSING =
  "UPDATE_CALENDAR_EVENT_PROCESSING";
export const UPDATE_CALENDAR_EVENT_FAILED = "UPDATE_CALENDAR_EVENT_FAILED";

export const DELETE_CALENDAR_EVENT_SUCCESS = "DELETE_CALENDAR_EVENT_SUCCESS";
export const DELETE_CALENDAR_EVENT_PROCESSING =
  "DELETE_CALENDAR_EVENT_PROCESSING";
export const DELETE_CALENDAR_EVENT_FAILED = "DELETE_CALENDAR_EVENT_FAILED";

export const GET_CALENDARS_SUCCESS = "GET_CALENDARS_SUCCESS";
export const GET_CALENDARS_PROCESSING = "GET_CALENDARS_PROCESSING";
export const GET_CALENDARS_FAILED = "GET_CALENDARS_FAILED";

export const CREATE_NEW_CALENDAR_SUCCESS = "CREATE_NEW_CALENDAR_SUCCESS";
export const CREATE_NEW_CALENDAR_PROCESSING = "CREATE_NEW_CALENDAR_PROCESSING";
export const CREATE_NEW_CALENDAR_FAILED = "CREATE_NEW_CALENDAR_FAILED";

export const DELETE_CALENDAR_SUCCESS = "DELETE_CALENDAR_SUCCESS";
export const DELETE_CALENDAR_PROCESSING = "DELETE_CALENDAR_PROCESSING";
export const DELETE_CALENDAR_FAILED = "DELETE_CALENDAR_FAILED";

export const SHARE_CALENDAR_SUCCESS = "SHARE_CALENDAR_SUCCESS";
export const SHARE_CALENDAR_PROCESSING = "SHARE_CALENDAR_PROCESSING";
export const SHARE_CALENDAR_FAILED = "SHARE_CALENDAR_FAILED";

export const SHARE_CALENDAR_WITH_SUCCESS = "SHARE_CALENDAR_WITH_SUCCESS";
export const SHARE_CALENDAR_WITH_PROCESSING = "SHARE_CALENDAR_WITH_PROCESSING";
export const SHARE_CALENDAR_WITH_FAILED = "SHARE_CALENDAR_WITH_FAILED";

export const DELETE_SHARE_CALENDAR_WITH_SUCCESS =
  "DELETE_SHARE_CALENDAR_WITH_SUCCESS";
export const DELETE_SHARE_CALENDAR_WITH_PROCESSING =
  "DELETE_SHARE_CALENDAR_WITH_PROCESSING";
export const DELETE_SHARE_CALENDAR_WITH_FAILED =
  "DELETE_SHARE_CALENDAR_WITH_FAILED";
