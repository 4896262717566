import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

class Icons extends Component {

  render() {
    const { fixNavbar } = this.props;
    return (
      <>
        <div>
          <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
            <div className="container-fluid">
              <div className="d-flex justify-content-between align-items-center">
                <ul className="nav nav-tabs page-header-tab">
                  <li className="nav-item"><NavLink to="/icons" className="nav-link active">Fontawesome</NavLink></li>
                  <li className="nav-item"><NavLink to="/icons-feather" className="nav-link" >Feather</NavLink></li>
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="/#" role="button" aria-haspopup="true" aria-expanded="false">More</a>
                    <div className="dropdown-menu">
                      <NavLink to="/icons-line" className="dropdown-item">Line Icons</NavLink>
                      <NavLink to="/icons-flag" className="dropdown-item">Flags Icons</NavLink>
                      <NavLink to="/icons-payments" className="dropdown-item">Payments Icon</NavLink>
                    </div>
                  </li>
                </ul>
                <div className="header-action">
                  <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal"><i className="fe fe-plus mr-2" />Add</button>
                </div>
              </div>
            </div>
          </div>
          <div className="section-body mt-3">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="alert alert-warning alert-dismissible fade show" role="alert">
                    <span>Powered by Font Awesome set. For more info <a href="/#" target="_blank">click here</a>.</span>
                    <div><code>&lt;i class="fa fa-ICON_NAME"&gt;&lt;/i&gt;</code></div>
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" />
                  </div>
                  <div className="card">
                    <div className="card-body icons-list-wrap">
                      <ul className="icons-list">
                        <li className="icons-list-item"><i className="fa fa-500px" data-toggle="tooltip" title="fa fa-500px" /></li>
                        <li className="icons-list-item"><i className="fa fa-address-book" data-toggle="tooltip" title="fa fa-address-book" /></li>
                        <li className="icons-list-item"><i className="fa fa-address-book-o" data-toggle="tooltip" title="fa fa-address-book-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-address-card" data-toggle="tooltip" title="fa fa-address-card" /></li>
                        <li className="icons-list-item"><i className="fa fa-address-card-o" data-toggle="tooltip" title="fa fa-address-card-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-adjust" data-toggle="tooltip" title="fa fa-adjust" /></li>
                        <li className="icons-list-item"><i className="fa fa-adn" data-toggle="tooltip" title="fa fa-adn" /></li>
                        <li className="icons-list-item"><i className="fa fa-align-center" data-toggle="tooltip" title="fa fa-align-center" /></li>
                        <li className="icons-list-item"><i className="fa fa-align-justify" data-toggle="tooltip" title="fa fa-align-justify" /></li>
                        <li className="icons-list-item"><i className="fa fa-align-left" data-toggle="tooltip" title="fa fa-align-left" /></li>
                        <li className="icons-list-item"><i className="fa fa-align-right" data-toggle="tooltip" title="fa fa-align-right" /></li>
                        <li className="icons-list-item"><i className="fa fa-amazon" data-toggle="tooltip" title="fa fa-amazon" /></li>
                        <li className="icons-list-item"><i className="fa fa-ambulance" data-toggle="tooltip" title="fa fa-ambulance" /></li>
                        <li className="icons-list-item"><i className="fa fa-american-sign-language-interpreting" data-toggle="tooltip" title="fa fa-american-sign-language-interpreting" /></li>
                        <li className="icons-list-item"><i className="fa fa-anchor" data-toggle="tooltip" title="fa fa-anchor" /></li>
                        <li className="icons-list-item"><i className="fa fa-android" data-toggle="tooltip" title="fa fa-android" /></li>
                        <li className="icons-list-item"><i className="fa fa-angellist" data-toggle="tooltip" title="fa fa-angellist" /></li>
                        <li className="icons-list-item"><i className="fa fa-angle-double-down" data-toggle="tooltip" title="fa fa-angle-double-down" /></li>
                        <li className="icons-list-item"><i className="fa fa-angle-double-left" data-toggle="tooltip" title="fa fa-angle-double-left" /></li>
                        <li className="icons-list-item"><i className="fa fa-angle-double-right" data-toggle="tooltip" title="fa fa-angle-double-right" /></li>
                        <li className="icons-list-item"><i className="fa fa-angle-double-up" data-toggle="tooltip" title="fa fa-angle-double-up" /></li>
                        <li className="icons-list-item"><i className="fa fa-angle-down" data-toggle="tooltip" title="fa fa-angle-down" /></li>
                        <li className="icons-list-item"><i className="fa fa-angle-left" data-toggle="tooltip" title="fa fa-angle-left" /></li>
                        <li className="icons-list-item"><i className="fa fa-angle-right" data-toggle="tooltip" title="fa fa-angle-right" /></li>
                        <li className="icons-list-item"><i className="fa fa-angle-up" data-toggle="tooltip" title="fa fa-angle-up" /></li>
                        <li className="icons-list-item"><i className="fa fa-apple" data-toggle="tooltip" title="fa fa-apple" /></li>
                        <li className="icons-list-item"><i className="fa fa-archive" data-toggle="tooltip" title="fa fa-archive" /></li>
                        <li className="icons-list-item"><i className="fa fa-area-chart" data-toggle="tooltip" title="fa fa-area-chart" /></li>
                        <li className="icons-list-item"><i className="fa fa-arrow-circle-down" data-toggle="tooltip" title="fa fa-arrow-circle-down" /></li>
                        <li className="icons-list-item"><i className="fa fa-arrow-circle-left" data-toggle="tooltip" title="fa fa-arrow-circle-left" /></li>
                        <li className="icons-list-item"><i className="fa fa-arrow-circle-o-down" data-toggle="tooltip" title="fa fa-arrow-circle-o-down" /></li>
                        <li className="icons-list-item"><i className="fa fa-arrow-circle-o-left" data-toggle="tooltip" title="fa fa-arrow-circle-o-left" /></li>
                        <li className="icons-list-item"><i className="fa fa-arrow-circle-o-right" data-toggle="tooltip" title="fa fa-arrow-circle-o-right" /></li>
                        <li className="icons-list-item"><i className="fa fa-arrow-circle-o-up" data-toggle="tooltip" title="fa fa-arrow-circle-o-up" /></li>
                        <li className="icons-list-item"><i className="fa fa-arrow-circle-right" data-toggle="tooltip" title="fa fa-arrow-circle-right" /></li>
                        <li className="icons-list-item"><i className="fa fa-arrow-circle-up" data-toggle="tooltip" title="fa fa-arrow-circle-up" /></li>
                        <li className="icons-list-item"><i className="fa fa-arrow-down" data-toggle="tooltip" title="fa fa-arrow-down" /></li>
                        <li className="icons-list-item"><i className="fa fa-arrow-left" data-toggle="tooltip" title="fa fa-arrow-left" /></li>
                        <li className="icons-list-item"><i className="fa fa-arrow-right" data-toggle="tooltip" title="fa fa-arrow-right" /></li>
                        <li className="icons-list-item"><i className="fa fa-arrow-up" data-toggle="tooltip" title="fa fa-arrow-up" /></li>
                        <li className="icons-list-item"><i className="fa fa-arrows" data-toggle="tooltip" title="fa fa-arrows" /></li>
                        <li className="icons-list-item"><i className="fa fa-arrows-alt" data-toggle="tooltip" title="fa fa-arrows-alt" /></li>
                        <li className="icons-list-item"><i className="fa fa-arrows-h" data-toggle="tooltip" title="fa fa-arrows-h" /></li>
                        <li className="icons-list-item"><i className="fa fa-arrows-v" data-toggle="tooltip" title="fa fa-arrows-v" /></li>
                        <li className="icons-list-item"><i className="fa fa-asl-interpreting" data-toggle="tooltip" title="fa fa-asl-interpreting" /></li>
                        <li className="icons-list-item"><i className="fa fa-assistive-listening-systems" data-toggle="tooltip" title="fa fa-assistive-listening-systems" /></li>
                        <li className="icons-list-item"><i className="fa fa-asterisk" data-toggle="tooltip" title="fa fa-asterisk" /></li>
                        <li className="icons-list-item"><i className="fa fa-at" data-toggle="tooltip" title="fa fa-at" /></li>
                        <li className="icons-list-item"><i className="fa fa-audio-description" data-toggle="tooltip" title="fa fa-audio-description" /></li>
                        <li className="icons-list-item"><i className="fa fa-automobile" data-toggle="tooltip" title="fa fa-automobile" /></li>
                        <li className="icons-list-item"><i className="fa fa-backward" data-toggle="tooltip" title="fa fa-backward" /></li>
                        <li className="icons-list-item"><i className="fa fa-balance-scale" data-toggle="tooltip" title="fa fa-balance-scale" /></li>
                        <li className="icons-list-item"><i className="fa fa-ban" data-toggle="tooltip" title="fa fa-ban" /></li>
                        <li className="icons-list-item"><i className="fa fa-bandcamp" data-toggle="tooltip" title="fa fa-bandcamp" /></li>
                        <li className="icons-list-item"><i className="fa fa-bank" data-toggle="tooltip" title="fa fa-bank" /></li>
                        <li className="icons-list-item"><i className="fa fa-bar-chart" data-toggle="tooltip" title="fa fa-bar-chart" /></li>
                        <li className="icons-list-item"><i className="fa fa-bar-chart-o" data-toggle="tooltip" title="fa fa-bar-chart-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-barcode" data-toggle="tooltip" title="fa fa-barcode" /></li>
                        <li className="icons-list-item"><i className="fa fa-bars" data-toggle="tooltip" title="fa fa-bars" /></li>
                        <li className="icons-list-item"><i className="fa fa-bath" data-toggle="tooltip" title="fa fa-bath" /></li>
                        <li className="icons-list-item"><i className="fa fa-bathtub" data-toggle="tooltip" title="fa fa-bathtub" /></li>
                        <li className="icons-list-item"><i className="fa fa-battery" data-toggle="tooltip" title="fa fa-battery" /></li>
                        <li className="icons-list-item"><i className="fa fa-battery-0" data-toggle="tooltip" title="fa fa-battery-0" /></li>
                        <li className="icons-list-item"><i className="fa fa-battery-1" data-toggle="tooltip" title="fa fa-battery-1" /></li>
                        <li className="icons-list-item"><i className="fa fa-battery-2" data-toggle="tooltip" title="fa fa-battery-2" /></li>
                        <li className="icons-list-item"><i className="fa fa-battery-3" data-toggle="tooltip" title="fa fa-battery-3" /></li>
                        <li className="icons-list-item"><i className="fa fa-battery-4" data-toggle="tooltip" title="fa fa-battery-4" /></li>
                        <li className="icons-list-item"><i className="fa fa-battery-empty" data-toggle="tooltip" title="fa fa-battery-empty" /></li>
                        <li className="icons-list-item"><i className="fa fa-battery-full" data-toggle="tooltip" title="fa fa-battery-full" /></li>
                        <li className="icons-list-item"><i className="fa fa-battery-half" data-toggle="tooltip" title="fa fa-battery-half" /></li>
                        <li className="icons-list-item"><i className="fa fa-battery-quarter" data-toggle="tooltip" title="fa fa-battery-quarter" /></li>
                        <li className="icons-list-item"><i className="fa fa-battery-three-quarters" data-toggle="tooltip" title="fa fa-battery-three-quarters" /></li>
                        <li className="icons-list-item"><i className="fa fa-bed" data-toggle="tooltip" title="fa fa-bed" /></li>
                        <li className="icons-list-item"><i className="fa fa-beer" data-toggle="tooltip" title="fa fa-beer" /></li>
                        <li className="icons-list-item"><i className="fa fa-behance" data-toggle="tooltip" title="fa fa-behance" /></li>
                        <li className="icons-list-item"><i className="fa fa-behance-square" data-toggle="tooltip" title="fa fa-behance-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-bell" data-toggle="tooltip" title="fa fa-bell" /></li>
                        <li className="icons-list-item"><i className="fa fa-bell-o" data-toggle="tooltip" title="fa fa-bell-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-bell-slash" data-toggle="tooltip" title="fa fa-bell-slash" /></li>
                        <li className="icons-list-item"><i className="fa fa-bell-slash-o" data-toggle="tooltip" title="fa fa-bell-slash-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-bicycle" data-toggle="tooltip" title="fa fa-bicycle" /></li>
                        <li className="icons-list-item"><i className="fa fa-binoculars" data-toggle="tooltip" title="fa fa-binoculars" /></li>
                        <li className="icons-list-item"><i className="fa fa-birthday-cake" data-toggle="tooltip" title="fa fa-birthday-cake" /></li>
                        <li className="icons-list-item"><i className="fa fa-bitbucket" data-toggle="tooltip" title="fa fa-bitbucket" /></li>
                        <li className="icons-list-item"><i className="fa fa-bitbucket-square" data-toggle="tooltip" title="fa fa-bitbucket-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-bitcoin" data-toggle="tooltip" title="fa fa-bitcoin" /></li>
                        <li className="icons-list-item"><i className="fa fa-black-tie" data-toggle="tooltip" title="fa fa-black-tie" /></li>
                        <li className="icons-list-item"><i className="fa fa-blind" data-toggle="tooltip" title="fa fa-blind" /></li>
                        <li className="icons-list-item"><i className="fa fa-bluetooth" data-toggle="tooltip" title="fa fa-bluetooth" /></li>
                        <li className="icons-list-item"><i className="fa fa-bluetooth-b" data-toggle="tooltip" title="fa fa-bluetooth-b" /></li>
                        <li className="icons-list-item"><i className="fa fa-bold" data-toggle="tooltip" title="fa fa-bold" /></li>
                        <li className="icons-list-item"><i className="fa fa-bolt" data-toggle="tooltip" title="fa fa-bolt" /></li>
                        <li className="icons-list-item"><i className="fa fa-bomb" data-toggle="tooltip" title="fa fa-bomb" /></li>
                        <li className="icons-list-item"><i className="fa fa-book" data-toggle="tooltip" title="fa fa-book" /></li>
                        <li className="icons-list-item"><i className="fa fa-bookmark" data-toggle="tooltip" title="fa fa-bookmark" /></li>
                        <li className="icons-list-item"><i className="fa fa-bookmark-o" data-toggle="tooltip" title="fa fa-bookmark-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-braille" data-toggle="tooltip" title="fa fa-braille" /></li>
                        <li className="icons-list-item"><i className="fa fa-briefcase" data-toggle="tooltip" title="fa fa-briefcase" /></li>
                        <li className="icons-list-item"><i className="fa fa-btc" data-toggle="tooltip" title="fa fa-btc" /></li>
                        <li className="icons-list-item"><i className="fa fa-bug" data-toggle="tooltip" title="fa fa-bug" /></li>
                        <li className="icons-list-item"><i className="fa fa-building" data-toggle="tooltip" title="fa fa-building" /></li>
                        <li className="icons-list-item"><i className="fa fa-building-o" data-toggle="tooltip" title="fa fa-building-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-bullhorn" data-toggle="tooltip" title="fa fa-bullhorn" /></li>
                        <li className="icons-list-item"><i className="fa fa-bullseye" data-toggle="tooltip" title="fa fa-bullseye" /></li>
                        <li className="icons-list-item"><i className="fa fa-bus" data-toggle="tooltip" title="fa fa-bus" /></li>
                        <li className="icons-list-item"><i className="fa fa-buysellads" data-toggle="tooltip" title="fa fa-buysellads" /></li>
                        <li className="icons-list-item"><i className="fa fa-cab" data-toggle="tooltip" title="fa fa-cab" /></li>
                        <li className="icons-list-item"><i className="fa fa-calculator" data-toggle="tooltip" title="fa fa-calculator" /></li>
                        <li className="icons-list-item"><i className="fa fa-calendar" data-toggle="tooltip" title="fa fa-calendar" /></li>
                        <li className="icons-list-item"><i className="fa fa-calendar-check-o" data-toggle="tooltip" title="fa fa-calendar-check-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-calendar-minus-o" data-toggle="tooltip" title="fa fa-calendar-minus-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-calendar-o" data-toggle="tooltip" title="fa fa-calendar-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-calendar-plus-o" data-toggle="tooltip" title="fa fa-calendar-plus-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-calendar-times-o" data-toggle="tooltip" title="fa fa-calendar-times-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-camera" data-toggle="tooltip" title="fa fa-camera" /></li>
                        <li className="icons-list-item"><i className="fa fa-camera-retro" data-toggle="tooltip" title="fa fa-camera-retro" /></li>
                        <li className="icons-list-item"><i className="fa fa-car" data-toggle="tooltip" title="fa fa-car" /></li>
                        <li className="icons-list-item"><i className="fa fa-caret-down" data-toggle="tooltip" title="fa fa-caret-down" /></li>
                        <li className="icons-list-item"><i className="fa fa-caret-left" data-toggle="tooltip" title="fa fa-caret-left" /></li>
                        <li className="icons-list-item"><i className="fa fa-caret-right" data-toggle="tooltip" title="fa fa-caret-right" /></li>
                        <li className="icons-list-item"><i className="fa fa-caret-square-o-down" data-toggle="tooltip" title="fa fa-caret-square-o-down" /></li>
                        <li className="icons-list-item"><i className="fa fa-caret-square-o-left" data-toggle="tooltip" title="fa fa-caret-square-o-left" /></li>
                        <li className="icons-list-item"><i className="fa fa-caret-square-o-right" data-toggle="tooltip" title="fa fa-caret-square-o-right" /></li>
                        <li className="icons-list-item"><i className="fa fa-caret-square-o-up" data-toggle="tooltip" title="fa fa-caret-square-o-up" /></li>
                        <li className="icons-list-item"><i className="fa fa-caret-up" data-toggle="tooltip" title="fa fa-caret-up" /></li>
                        <li className="icons-list-item"><i className="fa fa-cart-arrow-down" data-toggle="tooltip" title="fa fa-cart-arrow-down" /></li>
                        <li className="icons-list-item"><i className="fa fa-cart-plus" data-toggle="tooltip" title="fa fa-cart-plus" /></li>
                        <li className="icons-list-item"><i className="fa fa-cc" data-toggle="tooltip" title="fa fa-cc" /></li>
                        <li className="icons-list-item"><i className="fa fa-cc-amex" data-toggle="tooltip" title="fa fa-cc-amex" /></li>
                        <li className="icons-list-item"><i className="fa fa-cc-diners-club" data-toggle="tooltip" title="fa fa-cc-diners-club" /></li>
                        <li className="icons-list-item"><i className="fa fa-cc-discover" data-toggle="tooltip" title="fa fa-cc-discover" /></li>
                        <li className="icons-list-item"><i className="fa fa-cc-jcb" data-toggle="tooltip" title="fa fa-cc-jcb" /></li>
                        <li className="icons-list-item"><i className="fa fa-cc-mastercard" data-toggle="tooltip" title="fa fa-cc-mastercard" /></li>
                        <li className="icons-list-item"><i className="fa fa-cc-paypal" data-toggle="tooltip" title="fa fa-cc-paypal" /></li>
                        <li className="icons-list-item"><i className="fa fa-cc-stripe" data-toggle="tooltip" title="fa fa-cc-stripe" /></li>
                        <li className="icons-list-item"><i className="fa fa-cc-visa" data-toggle="tooltip" title="fa fa-cc-visa" /></li>
                        <li className="icons-list-item"><i className="fa fa-certificate" data-toggle="tooltip" title="fa fa-certificate" /></li>
                        <li className="icons-list-item"><i className="fa fa-chain" data-toggle="tooltip" title="fa fa-chain" /></li>
                        <li className="icons-list-item"><i className="fa fa-chain-broken" data-toggle="tooltip" title="fa fa-chain-broken" /></li>
                        <li className="icons-list-item"><i className="fa fa-check" data-toggle="tooltip" title="fa fa-check" /></li>
                        <li className="icons-list-item"><i className="fa fa-check-circle" data-toggle="tooltip" title="fa fa-check-circle" /></li>
                        <li className="icons-list-item"><i className="fa fa-check-circle-o" data-toggle="tooltip" title="fa fa-check-circle-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-check-square" data-toggle="tooltip" title="fa fa-check-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-check-square-o" data-toggle="tooltip" title="fa fa-check-square-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-chevron-circle-down" data-toggle="tooltip" title="fa fa-chevron-circle-down" /></li>
                        <li className="icons-list-item"><i className="fa fa-chevron-circle-left" data-toggle="tooltip" title="fa fa-chevron-circle-left" /></li>
                        <li className="icons-list-item"><i className="fa fa-chevron-circle-right" data-toggle="tooltip" title="fa fa-chevron-circle-right" /></li>
                        <li className="icons-list-item"><i className="fa fa-chevron-circle-up" data-toggle="tooltip" title="fa fa-chevron-circle-up" /></li>
                        <li className="icons-list-item"><i className="fa fa-chevron-down" data-toggle="tooltip" title="fa fa-chevron-down" /></li>
                        <li className="icons-list-item"><i className="fa fa-chevron-left" data-toggle="tooltip" title="fa fa-chevron-left" /></li>
                        <li className="icons-list-item"><i className="fa fa-chevron-right" data-toggle="tooltip" title="fa fa-chevron-right" /></li>
                        <li className="icons-list-item"><i className="fa fa-chevron-up" data-toggle="tooltip" title="fa fa-chevron-up" /></li>
                        <li className="icons-list-item"><i className="fa fa-child" data-toggle="tooltip" title="fa fa-child" /></li>
                        <li className="icons-list-item"><i className="fa fa-chrome" data-toggle="tooltip" title="fa fa-chrome" /></li>
                        <li className="icons-list-item"><i className="fa fa-circle" data-toggle="tooltip" title="fa fa-circle" /></li>
                        <li className="icons-list-item"><i className="fa fa-circle-o" data-toggle="tooltip" title="fa fa-circle-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-circle-o-notch" data-toggle="tooltip" title="fa fa-circle-o-notch" /></li>
                        <li className="icons-list-item"><i className="fa fa-circle-thin" data-toggle="tooltip" title="fa fa-circle-thin" /></li>
                        <li className="icons-list-item"><i className="fa fa-clipboard" data-toggle="tooltip" title="fa fa-clipboard" /></li>
                        <li className="icons-list-item"><i className="fa fa-clock-o" data-toggle="tooltip" title="fa fa-clock-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-clone" data-toggle="tooltip" title="fa fa-clone" /></li>
                        <li className="icons-list-item"><i className="fa fa-close" data-toggle="tooltip" title="fa fa-close" /></li>
                        <li className="icons-list-item"><i className="fa fa-cloud" data-toggle="tooltip" title="fa fa-cloud" /></li>
                        <li className="icons-list-item"><i className="fa fa-cloud-download" data-toggle="tooltip" title="fa fa-cloud-download" /></li>
                        <li className="icons-list-item"><i className="fa fa-cloud-upload" data-toggle="tooltip" title="fa fa-cloud-upload" /></li>
                        <li className="icons-list-item"><i className="fa fa-cny" data-toggle="tooltip" title="fa fa-cny" /></li>
                        <li className="icons-list-item"><i className="fa fa-code" data-toggle="tooltip" title="fa fa-code" /></li>
                        <li className="icons-list-item"><i className="fa fa-code-fork" data-toggle="tooltip" title="fa fa-code-fork" /></li>
                        <li className="icons-list-item"><i className="fa fa-codepen" data-toggle="tooltip" title="fa fa-codepen" /></li>
                        <li className="icons-list-item"><i className="fa fa-codiepie" data-toggle="tooltip" title="fa fa-codiepie" /></li>
                        <li className="icons-list-item"><i className="fa fa-coffee" data-toggle="tooltip" title="fa fa-coffee" /></li>
                        <li className="icons-list-item"><i className="fa fa-cog" data-toggle="tooltip" title="fa fa-cog" /></li>
                        <li className="icons-list-item"><i className="fa fa-cogs" data-toggle="tooltip" title="fa fa-cogs" /></li>
                        <li className="icons-list-item"><i className="fa fa-columns" data-toggle="tooltip" title="fa fa-columns" /></li>
                        <li className="icons-list-item"><i className="fa fa-comment" data-toggle="tooltip" title="fa fa-comment" /></li>
                        <li className="icons-list-item"><i className="fa fa-comment-o" data-toggle="tooltip" title="fa fa-comment-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-commenting" data-toggle="tooltip" title="fa fa-commenting" /></li>
                        <li className="icons-list-item"><i className="fa fa-commenting-o" data-toggle="tooltip" title="fa fa-commenting-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-comments" data-toggle="tooltip" title="fa fa-comments" /></li>
                        <li className="icons-list-item"><i className="fa fa-comments-o" data-toggle="tooltip" title="fa fa-comments-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-compass" data-toggle="tooltip" title="fa fa-compass" /></li>
                        <li className="icons-list-item"><i className="fa fa-compress" data-toggle="tooltip" title="fa fa-compress" /></li>
                        <li className="icons-list-item"><i className="fa fa-connectdevelop" data-toggle="tooltip" title="fa fa-connectdevelop" /></li>
                        <li className="icons-list-item"><i className="fa fa-contao" data-toggle="tooltip" title="fa fa-contao" /></li>
                        <li className="icons-list-item"><i className="fa fa-copy" data-toggle="tooltip" title="fa fa-copy" /></li>
                        <li className="icons-list-item"><i className="fa fa-copyright" data-toggle="tooltip" title="fa fa-copyright" /></li>
                        <li className="icons-list-item"><i className="fa fa-creative-commons" data-toggle="tooltip" title="fa fa-creative-commons" /></li>
                        <li className="icons-list-item"><i className="fa fa-credit-card" data-toggle="tooltip" title="fa fa-credit-card" /></li>
                        <li className="icons-list-item"><i className="fa fa-credit-card-alt" data-toggle="tooltip" title="fa fa-credit-card-alt" /></li>
                        <li className="icons-list-item"><i className="fa fa-crop" data-toggle="tooltip" title="fa fa-crop" /></li>
                        <li className="icons-list-item"><i className="fa fa-crosshairs" data-toggle="tooltip" title="fa fa-crosshairs" /></li>
                        <li className="icons-list-item"><i className="fa fa-css3" data-toggle="tooltip" title="fa fa-css3" /></li>
                        <li className="icons-list-item"><i className="fa fa-cube" data-toggle="tooltip" title="fa fa-cube" /></li>
                        <li className="icons-list-item"><i className="fa fa-cubes" data-toggle="tooltip" title="fa fa-cubes" /></li>
                        <li className="icons-list-item"><i className="fa fa-cut" data-toggle="tooltip" title="fa fa-cut" /></li>
                        <li className="icons-list-item"><i className="fa fa-cutlery" data-toggle="tooltip" title="fa fa-cutlery" /></li>
                        <li className="icons-list-item"><i className="fa fa-dashboard" data-toggle="tooltip" title="fa fa-dashboard" /></li>
                        <li className="icons-list-item"><i className="fa fa-dashcube" data-toggle="tooltip" title="fa fa-dashcube" /></li>
                        <li className="icons-list-item"><i className="fa fa-database" data-toggle="tooltip" title="fa fa-database" /></li>
                        <li className="icons-list-item"><i className="fa fa-deaf" data-toggle="tooltip" title="fa fa-deaf" /></li>
                        <li className="icons-list-item"><i className="fa fa-deafness" data-toggle="tooltip" title="fa fa-deafness" /></li>
                        <li className="icons-list-item"><i className="fa fa-dedent" data-toggle="tooltip" title="fa fa-dedent" /></li>
                        <li className="icons-list-item"><i className="fa fa-delicious" data-toggle="tooltip" title="fa fa-delicious" /></li>
                        <li className="icons-list-item"><i className="fa fa-desktop" data-toggle="tooltip" title="fa fa-desktop" /></li>
                        <li className="icons-list-item"><i className="fa fa-deviantart" data-toggle="tooltip" title="fa fa-deviantart" /></li>
                        <li className="icons-list-item"><i className="fa fa-diamond" data-toggle="tooltip" title="fa fa-diamond" /></li>
                        <li className="icons-list-item"><i className="fa fa-digg" data-toggle="tooltip" title="fa fa-digg" /></li>
                        <li className="icons-list-item"><i className="fa fa-dollar" data-toggle="tooltip" title="fa fa-dollar" /></li>
                        <li className="icons-list-item"><i className="fa fa-dot-circle-o" data-toggle="tooltip" title="fa fa-dot-circle-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-download" data-toggle="tooltip" title="fa fa-download" /></li>
                        <li className="icons-list-item"><i className="fa fa-dribbble" data-toggle="tooltip" title="fa fa-dribbble" /></li>
                        <li className="icons-list-item"><i className="fa fa-drivers-license" data-toggle="tooltip" title="fa fa-drivers-license" /></li>
                        <li className="icons-list-item"><i className="fa fa-drivers-license-o" data-toggle="tooltip" title="fa fa-drivers-license-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-dropbox" data-toggle="tooltip" title="fa fa-dropbox" /></li>
                        <li className="icons-list-item"><i className="fa fa-drupal" data-toggle="tooltip" title="fa fa-drupal" /></li>
                        <li className="icons-list-item"><i className="fa fa-edge" data-toggle="tooltip" title="fa fa-edge" /></li>
                        <li className="icons-list-item"><i className="fa fa-edit" data-toggle="tooltip" title="fa fa-edit" /></li>
                        <li className="icons-list-item"><i className="fa fa-eercast" data-toggle="tooltip" title="fa fa-eercast" /></li>
                        <li className="icons-list-item"><i className="fa fa-eject" data-toggle="tooltip" title="fa fa-eject" /></li>
                        <li className="icons-list-item"><i className="fa fa-ellipsis-h" data-toggle="tooltip" title="fa fa-ellipsis-h" /></li>
                        <li className="icons-list-item"><i className="fa fa-ellipsis-v" data-toggle="tooltip" title="fa fa-ellipsis-v" /></li>
                        <li className="icons-list-item"><i className="fa fa-empire" data-toggle="tooltip" title="fa fa-empire" /></li>
                        <li className="icons-list-item"><i className="fa fa-envelope" data-toggle="tooltip" title="fa fa-envelope" /></li>
                        <li className="icons-list-item"><i className="fa fa-envelope-o" data-toggle="tooltip" title="fa fa-envelope-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-envelope-open" data-toggle="tooltip" title="fa fa-envelope-open" /></li>
                        <li className="icons-list-item"><i className="fa fa-envelope-open-o" data-toggle="tooltip" title="fa fa-envelope-open-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-envelope-square" data-toggle="tooltip" title="fa fa-envelope-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-envira" data-toggle="tooltip" title="fa fa-envira" /></li>
                        <li className="icons-list-item"><i className="fa fa-eraser" data-toggle="tooltip" title="fa fa-eraser" /></li>
                        <li className="icons-list-item"><i className="fa fa-etsy" data-toggle="tooltip" title="fa fa-etsy" /></li>
                        <li className="icons-list-item"><i className="fa fa-eur" data-toggle="tooltip" title="fa fa-eur" /></li>
                        <li className="icons-list-item"><i className="fa fa-euro" data-toggle="tooltip" title="fa fa-euro" /></li>
                        <li className="icons-list-item"><i className="fa fa-exchange" data-toggle="tooltip" title="fa fa-exchange" /></li>
                        <li className="icons-list-item"><i className="fa fa-exclamation" data-toggle="tooltip" title="fa fa-exclamation" /></li>
                        <li className="icons-list-item"><i className="fa fa-exclamation-circle" data-toggle="tooltip" title="fa fa-exclamation-circle" /></li>
                        <li className="icons-list-item"><i className="fa fa-exclamation-triangle" data-toggle="tooltip" title="fa fa-exclamation-triangle" /></li>
                        <li className="icons-list-item"><i className="fa fa-expand" data-toggle="tooltip" title="fa fa-expand" /></li>
                        <li className="icons-list-item"><i className="fa fa-expeditedssl" data-toggle="tooltip" title="fa fa-expeditedssl" /></li>
                        <li className="icons-list-item"><i className="fa fa-external-link" data-toggle="tooltip" title="fa fa-external-link" /></li>
                        <li className="icons-list-item"><i className="fa fa-external-link-square" data-toggle="tooltip" title="fa fa-external-link-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-eye" data-toggle="tooltip" title="fa fa-eye" /></li>
                        <li className="icons-list-item"><i className="fa fa-eye-slash" data-toggle="tooltip" title="fa fa-eye-slash" /></li>
                        <li className="icons-list-item"><i className="fa fa-eyedropper" data-toggle="tooltip" title="fa fa-eyedropper" /></li>
                        <li className="icons-list-item"><i className="fa fa-fa" data-toggle="tooltip" title="fa fa-fa" /></li>
                        <li className="icons-list-item"><i className="fa fa-facebook" data-toggle="tooltip" title="fa fa-facebook" /></li>
                        <li className="icons-list-item"><i className="fa fa-facebook-f" data-toggle="tooltip" title="fa fa-facebook-f" /></li>
                        <li className="icons-list-item"><i className="fa fa-facebook-official" data-toggle="tooltip" title="fa fa-facebook-official" /></li>
                        <li className="icons-list-item"><i className="fa fa-facebook-square" data-toggle="tooltip" title="fa fa-facebook-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-fast-backward" data-toggle="tooltip" title="fa fa-fast-backward" /></li>
                        <li className="icons-list-item"><i className="fa fa-fast-forward" data-toggle="tooltip" title="fa fa-fast-forward" /></li>
                        <li className="icons-list-item"><i className="fa fa-fax" data-toggle="tooltip" title="fa fa-fax" /></li>
                        <li className="icons-list-item"><i className="fa fa-feed" data-toggle="tooltip" title="fa fa-feed" /></li>
                        <li className="icons-list-item"><i className="fa fa-female" data-toggle="tooltip" title="fa fa-female" /></li>
                        <li className="icons-list-item"><i className="fa fa-fighter-jet" data-toggle="tooltip" title="fa fa-fighter-jet" /></li>
                        <li className="icons-list-item"><i className="fa fa-file" data-toggle="tooltip" title="fa fa-file" /></li>
                        <li className="icons-list-item"><i className="fa fa-file-archive-o" data-toggle="tooltip" title="fa fa-file-archive-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-file-audio-o" data-toggle="tooltip" title="fa fa-file-audio-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-file-code-o" data-toggle="tooltip" title="fa fa-file-code-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-file-excel-o" data-toggle="tooltip" title="fa fa-file-excel-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-file-image-o" data-toggle="tooltip" title="fa fa-file-image-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-file-movie-o" data-toggle="tooltip" title="fa fa-file-movie-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-file-o" data-toggle="tooltip" title="fa fa-file-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-file-pdf-o" data-toggle="tooltip" title="fa fa-file-pdf-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-file-photo-o" data-toggle="tooltip" title="fa fa-file-photo-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-file-picture-o" data-toggle="tooltip" title="fa fa-file-picture-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-file-powerpoint-o" data-toggle="tooltip" title="fa fa-file-powerpoint-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-file-sound-o" data-toggle="tooltip" title="fa fa-file-sound-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-file-text" data-toggle="tooltip" title="fa fa-file-text" /></li>
                        <li className="icons-list-item"><i className="fa fa-file-text-o" data-toggle="tooltip" title="fa fa-file-text-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-file-video-o" data-toggle="tooltip" title="fa fa-file-video-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-file-word-o" data-toggle="tooltip" title="fa fa-file-word-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-file-zip-o" data-toggle="tooltip" title="fa fa-file-zip-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-files-o" data-toggle="tooltip" title="fa fa-files-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-film" data-toggle="tooltip" title="fa fa-film" /></li>
                        <li className="icons-list-item"><i className="fa fa-filter" data-toggle="tooltip" title="fa fa-filter" /></li>
                        <li className="icons-list-item"><i className="fa fa-fire" data-toggle="tooltip" title="fa fa-fire" /></li>
                        <li className="icons-list-item"><i className="fa fa-fire-extinguisher" data-toggle="tooltip" title="fa fa-fire-extinguisher" /></li>
                        <li className="icons-list-item"><i className="fa fa-firefox" data-toggle="tooltip" title="fa fa-firefox" /></li>
                        <li className="icons-list-item"><i className="fa fa-first-order" data-toggle="tooltip" title="fa fa-first-order" /></li>
                        <li className="icons-list-item"><i className="fa fa-flag" data-toggle="tooltip" title="fa fa-flag" /></li>
                        <li className="icons-list-item"><i className="fa fa-flag-checkered" data-toggle="tooltip" title="fa fa-flag-checkered" /></li>
                        <li className="icons-list-item"><i className="fa fa-flag-o" data-toggle="tooltip" title="fa fa-flag-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-flash" data-toggle="tooltip" title="fa fa-flash" /></li>
                        <li className="icons-list-item"><i className="fa fa-flask" data-toggle="tooltip" title="fa fa-flask" /></li>
                        <li className="icons-list-item"><i className="fa fa-flickr" data-toggle="tooltip" title="fa fa-flickr" /></li>
                        <li className="icons-list-item"><i className="fa fa-floppy-o" data-toggle="tooltip" title="fa fa-floppy-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-folder" data-toggle="tooltip" title="fa fa-folder" /></li>
                        <li className="icons-list-item"><i className="fa fa-folder-o" data-toggle="tooltip" title="fa fa-folder-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-folder-open" data-toggle="tooltip" title="fa fa-folder-open" /></li>
                        <li className="icons-list-item"><i className="fa fa-folder-open-o" data-toggle="tooltip" title="fa fa-folder-open-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-font" data-toggle="tooltip" title="fa fa-font" /></li>
                        <li className="icons-list-item"><i className="fa fa-font-awesome" data-toggle="tooltip" title="fa fa-font-awesome" /></li>
                        <li className="icons-list-item"><i className="fa fa-fonticons" data-toggle="tooltip" title="fa fa-fonticons" /></li>
                        <li className="icons-list-item"><i className="fa fa-fort-awesome" data-toggle="tooltip" title="fa fa-fort-awesome" /></li>
                        <li className="icons-list-item"><i className="fa fa-forumbee" data-toggle="tooltip" title="fa fa-forumbee" /></li>
                        <li className="icons-list-item"><i className="fa fa-forward" data-toggle="tooltip" title="fa fa-forward" /></li>
                        <li className="icons-list-item"><i className="fa fa-foursquare" data-toggle="tooltip" title="fa fa-foursquare" /></li>
                        <li className="icons-list-item"><i className="fa fa-free-code-camp" data-toggle="tooltip" title="fa fa-free-code-camp" /></li>
                        <li className="icons-list-item"><i className="fa fa-frown-o" data-toggle="tooltip" title="fa fa-frown-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-futbol-o" data-toggle="tooltip" title="fa fa-futbol-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-gamepad" data-toggle="tooltip" title="fa fa-gamepad" /></li>
                        <li className="icons-list-item"><i className="fa fa-gavel" data-toggle="tooltip" title="fa fa-gavel" /></li>
                        <li className="icons-list-item"><i className="fa fa-gbp" data-toggle="tooltip" title="fa fa-gbp" /></li>
                        <li className="icons-list-item"><i className="fa fa-ge" data-toggle="tooltip" title="fa fa-ge" /></li>
                        <li className="icons-list-item"><i className="fa fa-gear" data-toggle="tooltip" title="fa fa-gear" /></li>
                        <li className="icons-list-item"><i className="fa fa-gears" data-toggle="tooltip" title="fa fa-gears" /></li>
                        <li className="icons-list-item"><i className="fa fa-genderless" data-toggle="tooltip" title="fa fa-genderless" /></li>
                        <li className="icons-list-item"><i className="fa fa-get-pocket" data-toggle="tooltip" title="fa fa-get-pocket" /></li>
                        <li className="icons-list-item"><i className="fa fa-gg" data-toggle="tooltip" title="fa fa-gg" /></li>
                        <li className="icons-list-item"><i className="fa fa-gg-circle" data-toggle="tooltip" title="fa fa-gg-circle" /></li>
                        <li className="icons-list-item"><i className="fa fa-gift" data-toggle="tooltip" title="fa fa-gift" /></li>
                        <li className="icons-list-item"><i className="fa fa-git" data-toggle="tooltip" title="fa fa-git" /></li>
                        <li className="icons-list-item"><i className="fa fa-git-square" data-toggle="tooltip" title="fa fa-git-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-github" data-toggle="tooltip" title="fa fa-github" /></li>
                        <li className="icons-list-item"><i className="fa fa-github-alt" data-toggle="tooltip" title="fa fa-github-alt" /></li>
                        <li className="icons-list-item"><i className="fa fa-github-square" data-toggle="tooltip" title="fa fa-github-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-gitlab" data-toggle="tooltip" title="fa fa-gitlab" /></li>
                        <li className="icons-list-item"><i className="fa fa-gittip" data-toggle="tooltip" title="fa fa-gittip" /></li>
                        <li className="icons-list-item"><i className="fa fa-glass" data-toggle="tooltip" title="fa fa-glass" /></li>
                        <li className="icons-list-item"><i className="fa fa-glide" data-toggle="tooltip" title="fa fa-glide" /></li>
                        <li className="icons-list-item"><i className="fa fa-glide-g" data-toggle="tooltip" title="fa fa-glide-g" /></li>
                        <li className="icons-list-item"><i className="fa fa-globe" data-toggle="tooltip" title="fa fa-globe" /></li>
                        <li className="icons-list-item"><i className="fa fa-google" data-toggle="tooltip" title="fa fa-google" /></li>
                        <li className="icons-list-item"><i className="fa fa-google-plus" data-toggle="tooltip" title="fa fa-google-plus" /></li>
                        <li className="icons-list-item"><i className="fa fa-google-plus-circle" data-toggle="tooltip" title="fa fa-google-plus-circle" /></li>
                        <li className="icons-list-item"><i className="fa fa-google-plus-official" data-toggle="tooltip" title="fa fa-google-plus-official" /></li>
                        <li className="icons-list-item"><i className="fa fa-google-plus-square" data-toggle="tooltip" title="fa fa-google-plus-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-google-wallet" data-toggle="tooltip" title="fa fa-google-wallet" /></li>
                        <li className="icons-list-item"><i className="fa fa-graduation-cap" data-toggle="tooltip" title="fa fa-graduation-cap" /></li>
                        <li className="icons-list-item"><i className="fa fa-gratipay" data-toggle="tooltip" title="fa fa-gratipay" /></li>
                        <li className="icons-list-item"><i className="fa fa-grav" data-toggle="tooltip" title="fa fa-grav" /></li>
                        <li className="icons-list-item"><i className="fa fa-group" data-toggle="tooltip" title="fa fa-group" /></li>
                        <li className="icons-list-item"><i className="fa fa-h-square" data-toggle="tooltip" title="fa fa-h-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-hacker-news" data-toggle="tooltip" title="fa fa-hacker-news" /></li>
                        <li className="icons-list-item"><i className="fa fa-hand-grab-o" data-toggle="tooltip" title="fa fa-hand-grab-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-hand-lizard-o" data-toggle="tooltip" title="fa fa-hand-lizard-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-hand-o-down" data-toggle="tooltip" title="fa fa-hand-o-down" /></li>
                        <li className="icons-list-item"><i className="fa fa-hand-o-left" data-toggle="tooltip" title="fa fa-hand-o-left" /></li>
                        <li className="icons-list-item"><i className="fa fa-hand-o-right" data-toggle="tooltip" title="fa fa-hand-o-right" /></li>
                        <li className="icons-list-item"><i className="fa fa-hand-o-up" data-toggle="tooltip" title="fa fa-hand-o-up" /></li>
                        <li className="icons-list-item"><i className="fa fa-hand-paper-o" data-toggle="tooltip" title="fa fa-hand-paper-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-hand-peace-o" data-toggle="tooltip" title="fa fa-hand-peace-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-hand-pointer-o" data-toggle="tooltip" title="fa fa-hand-pointer-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-hand-rock-o" data-toggle="tooltip" title="fa fa-hand-rock-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-hand-scissors-o" data-toggle="tooltip" title="fa fa-hand-scissors-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-hand-spock-o" data-toggle="tooltip" title="fa fa-hand-spock-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-hand-stop-o" data-toggle="tooltip" title="fa fa-hand-stop-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-handshake-o" data-toggle="tooltip" title="fa fa-handshake-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-hard-of-hearing" data-toggle="tooltip" title="fa fa-hard-of-hearing" /></li>
                        <li className="icons-list-item"><i className="fa fa-hashtag" data-toggle="tooltip" title="fa fa-hashtag" /></li>
                        <li className="icons-list-item"><i className="fa fa-hdd-o" data-toggle="tooltip" title="fa fa-hdd-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-header" data-toggle="tooltip" title="fa fa-header" /></li>
                        <li className="icons-list-item"><i className="fa fa-headphones" data-toggle="tooltip" title="fa fa-headphones" /></li>
                        <li className="icons-list-item"><i className="fa fa-heart" data-toggle="tooltip" title="fa fa-heart" /></li>
                        <li className="icons-list-item"><i className="fa fa-heart-o" data-toggle="tooltip" title="fa fa-heart-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-heartbeat" data-toggle="tooltip" title="fa fa-heartbeat" /></li>
                        <li className="icons-list-item"><i className="fa fa-history" data-toggle="tooltip" title="fa fa-history" /></li>
                        <li className="icons-list-item"><i className="fa fa-home" data-toggle="tooltip" title="fa fa-home" /></li>
                        <li className="icons-list-item"><i className="fa fa-hospital-o" data-toggle="tooltip" title="fa fa-hospital-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-hotel" data-toggle="tooltip" title="fa fa-hotel" /></li>
                        <li className="icons-list-item"><i className="fa fa-hourglass" data-toggle="tooltip" title="fa fa-hourglass" /></li>
                        <li className="icons-list-item"><i className="fa fa-hourglass-1" data-toggle="tooltip" title="fa fa-hourglass-1" /></li>
                        <li className="icons-list-item"><i className="fa fa-hourglass-2" data-toggle="tooltip" title="fa fa-hourglass-2" /></li>
                        <li className="icons-list-item"><i className="fa fa-hourglass-3" data-toggle="tooltip" title="fa fa-hourglass-3" /></li>
                        <li className="icons-list-item"><i className="fa fa-hourglass-end" data-toggle="tooltip" title="fa fa-hourglass-end" /></li>
                        <li className="icons-list-item"><i className="fa fa-hourglass-half" data-toggle="tooltip" title="fa fa-hourglass-half" /></li>
                        <li className="icons-list-item"><i className="fa fa-hourglass-o" data-toggle="tooltip" title="fa fa-hourglass-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-hourglass-start" data-toggle="tooltip" title="fa fa-hourglass-start" /></li>
                        <li className="icons-list-item"><i className="fa fa-houzz" data-toggle="tooltip" title="fa fa-houzz" /></li>
                        <li className="icons-list-item"><i className="fa fa-html5" data-toggle="tooltip" title="fa fa-html5" /></li>
                        <li className="icons-list-item"><i className="fa fa-i-cursor" data-toggle="tooltip" title="fa fa-i-cursor" /></li>
                        <li className="icons-list-item"><i className="fa fa-id-badge" data-toggle="tooltip" title="fa fa-id-badge" /></li>
                        <li className="icons-list-item"><i className="fa fa-id-card" data-toggle="tooltip" title="fa fa-id-card" /></li>
                        <li className="icons-list-item"><i className="fa fa-id-card-o" data-toggle="tooltip" title="fa fa-id-card-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-ils" data-toggle="tooltip" title="fa fa-ils" /></li>
                        <li className="icons-list-item"><i className="fa fa-image" data-toggle="tooltip" title="fa fa-image" /></li>
                        <li className="icons-list-item"><i className="fa fa-imdb" data-toggle="tooltip" title="fa fa-imdb" /></li>
                        <li className="icons-list-item"><i className="fa fa-inbox" data-toggle="tooltip" title="fa fa-inbox" /></li>
                        <li className="icons-list-item"><i className="fa fa-indent" data-toggle="tooltip" title="fa fa-indent" /></li>
                        <li className="icons-list-item"><i className="fa fa-industry" data-toggle="tooltip" title="fa fa-industry" /></li>
                        <li className="icons-list-item"><i className="fa fa-info" data-toggle="tooltip" title="fa fa-info" /></li>
                        <li className="icons-list-item"><i className="fa fa-info-circle" data-toggle="tooltip" title="fa fa-info-circle" /></li>
                        <li className="icons-list-item"><i className="fa fa-inr" data-toggle="tooltip" title="fa fa-inr" /></li>
                        <li className="icons-list-item"><i className="fa fa-instagram" data-toggle="tooltip" title="fa fa-instagram" /></li>
                        <li className="icons-list-item"><i className="fa fa-institution" data-toggle="tooltip" title="fa fa-institution" /></li>
                        <li className="icons-list-item"><i className="fa fa-internet-explorer" data-toggle="tooltip" title="fa fa-internet-explorer" /></li>
                        <li className="icons-list-item"><i className="fa fa-intersex" data-toggle="tooltip" title="fa fa-intersex" /></li>
                        <li className="icons-list-item"><i className="fa fa-ioxhost" data-toggle="tooltip" title="fa fa-ioxhost" /></li>
                        <li className="icons-list-item"><i className="fa fa-italic" data-toggle="tooltip" title="fa fa-italic" /></li>
                        <li className="icons-list-item"><i className="fa fa-joomla" data-toggle="tooltip" title="fa fa-joomla" /></li>
                        <li className="icons-list-item"><i className="fa fa-jpy" data-toggle="tooltip" title="fa fa-jpy" /></li>
                        <li className="icons-list-item"><i className="fa fa-jsfiddle" data-toggle="tooltip" title="fa fa-jsfiddle" /></li>
                        <li className="icons-list-item"><i className="fa fa-key" data-toggle="tooltip" title="fa fa-key" /></li>
                        <li className="icons-list-item"><i className="fa fa-keyboard-o" data-toggle="tooltip" title="fa fa-keyboard-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-krw" data-toggle="tooltip" title="fa fa-krw" /></li>
                        <li className="icons-list-item"><i className="fa fa-language" data-toggle="tooltip" title="fa fa-language" /></li>
                        <li className="icons-list-item"><i className="fa fa-laptop" data-toggle="tooltip" title="fa fa-laptop" /></li>
                        <li className="icons-list-item"><i className="fa fa-lastfm" data-toggle="tooltip" title="fa fa-lastfm" /></li>
                        <li className="icons-list-item"><i className="fa fa-lastfm-square" data-toggle="tooltip" title="fa fa-lastfm-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-leaf" data-toggle="tooltip" title="fa fa-leaf" /></li>
                        <li className="icons-list-item"><i className="fa fa-leanpub" data-toggle="tooltip" title="fa fa-leanpub" /></li>
                        <li className="icons-list-item"><i className="fa fa-legal" data-toggle="tooltip" title="fa fa-legal" /></li>
                        <li className="icons-list-item"><i className="fa fa-lemon-o" data-toggle="tooltip" title="fa fa-lemon-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-level-down" data-toggle="tooltip" title="fa fa-level-down" /></li>
                        <li className="icons-list-item"><i className="fa fa-level-up" data-toggle="tooltip" title="fa fa-level-up" /></li>
                        <li className="icons-list-item"><i className="fa fa-life-bouy" data-toggle="tooltip" title="fa fa-life-bouy" /></li>
                        <li className="icons-list-item"><i className="fa fa-life-buoy" data-toggle="tooltip" title="fa fa-life-buoy" /></li>
                        <li className="icons-list-item"><i className="fa fa-life-ring" data-toggle="tooltip" title="fa fa-life-ring" /></li>
                        <li className="icons-list-item"><i className="fa fa-life-saver" data-toggle="tooltip" title="fa fa-life-saver" /></li>
                        <li className="icons-list-item"><i className="fa fa-lightbulb-o" data-toggle="tooltip" title="fa fa-lightbulb-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-line-chart" data-toggle="tooltip" title="fa fa-line-chart" /></li>
                        <li className="icons-list-item"><i className="fa fa-link" data-toggle="tooltip" title="fa fa-link" /></li>
                        <li className="icons-list-item"><i className="fa fa-linkedin" data-toggle="tooltip" title="fa fa-linkedin" /></li>
                        <li className="icons-list-item"><i className="fa fa-linkedin-square" data-toggle="tooltip" title="fa fa-linkedin-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-linode" data-toggle="tooltip" title="fa fa-linode" /></li>
                        <li className="icons-list-item"><i className="fa fa-linux" data-toggle="tooltip" title="fa fa-linux" /></li>
                        <li className="icons-list-item"><i className="fa fa-list" data-toggle="tooltip" title="fa fa-list" /></li>
                        <li className="icons-list-item"><i className="fa fa-list-alt" data-toggle="tooltip" title="fa fa-list-alt" /></li>
                        <li className="icons-list-item"><i className="fa fa-list-ol" data-toggle="tooltip" title="fa fa-list-ol" /></li>
                        <li className="icons-list-item"><i className="fa fa-list-ul" data-toggle="tooltip" title="fa fa-list-ul" /></li>
                        <li className="icons-list-item"><i className="fa fa-location-arrow" data-toggle="tooltip" title="fa fa-location-arrow" /></li>
                        <li className="icons-list-item"><i className="fa fa-lock" data-toggle="tooltip" title="fa fa-lock" /></li>
                        <li className="icons-list-item"><i className="fa fa-long-arrow-down" data-toggle="tooltip" title="fa fa-long-arrow-down" /></li>
                        <li className="icons-list-item"><i className="fa fa-long-arrow-left" data-toggle="tooltip" title="fa fa-long-arrow-left" /></li>
                        <li className="icons-list-item"><i className="fa fa-long-arrow-right" data-toggle="tooltip" title="fa fa-long-arrow-right" /></li>
                        <li className="icons-list-item"><i className="fa fa-long-arrow-up" data-toggle="tooltip" title="fa fa-long-arrow-up" /></li>
                        <li className="icons-list-item"><i className="fa fa-low-vision" data-toggle="tooltip" title="fa fa-low-vision" /></li>
                        <li className="icons-list-item"><i className="fa fa-magic" data-toggle="tooltip" title="fa fa-magic" /></li>
                        <li className="icons-list-item"><i className="fa fa-magnet" data-toggle="tooltip" title="fa fa-magnet" /></li>
                        <li className="icons-list-item"><i className="fa fa-mail-forward" data-toggle="tooltip" title="fa fa-mail-forward" /></li>
                        <li className="icons-list-item"><i className="fa fa-mail-reply" data-toggle="tooltip" title="fa fa-mail-reply" /></li>
                        <li className="icons-list-item"><i className="fa fa-mail-reply-all" data-toggle="tooltip" title="fa fa-mail-reply-all" /></li>
                        <li className="icons-list-item"><i className="fa fa-male" data-toggle="tooltip" title="fa fa-male" /></li>
                        <li className="icons-list-item"><i className="fa fa-map" data-toggle="tooltip" title="fa fa-map" /></li>
                        <li className="icons-list-item"><i className="fa fa-map-marker" data-toggle="tooltip" title="fa fa-map-marker" /></li>
                        <li className="icons-list-item"><i className="fa fa-map-o" data-toggle="tooltip" title="fa fa-map-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-map-pin" data-toggle="tooltip" title="fa fa-map-pin" /></li>
                        <li className="icons-list-item"><i className="fa fa-map-signs" data-toggle="tooltip" title="fa fa-map-signs" /></li>
                        <li className="icons-list-item"><i className="fa fa-mars" data-toggle="tooltip" title="fa fa-mars" /></li>
                        <li className="icons-list-item"><i className="fa fa-mars-double" data-toggle="tooltip" title="fa fa-mars-double" /></li>
                        <li className="icons-list-item"><i className="fa fa-mars-stroke" data-toggle="tooltip" title="fa fa-mars-stroke" /></li>
                        <li className="icons-list-item"><i className="fa fa-mars-stroke-h" data-toggle="tooltip" title="fa fa-mars-stroke-h" /></li>
                        <li className="icons-list-item"><i className="fa fa-mars-stroke-v" data-toggle="tooltip" title="fa fa-mars-stroke-v" /></li>
                        <li className="icons-list-item"><i className="fa fa-maxcdn" data-toggle="tooltip" title="fa fa-maxcdn" /></li>
                        <li className="icons-list-item"><i className="fa fa-meanpath" data-toggle="tooltip" title="fa fa-meanpath" /></li>
                        <li className="icons-list-item"><i className="fa fa-medium" data-toggle="tooltip" title="fa fa-medium" /></li>
                        <li className="icons-list-item"><i className="fa fa-medkit" data-toggle="tooltip" title="fa fa-medkit" /></li>
                        <li className="icons-list-item"><i className="fa fa-meetup" data-toggle="tooltip" title="fa fa-meetup" /></li>
                        <li className="icons-list-item"><i className="fa fa-meh-o" data-toggle="tooltip" title="fa fa-meh-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-mercury" data-toggle="tooltip" title="fa fa-mercury" /></li>
                        <li className="icons-list-item"><i className="fa fa-microchip" data-toggle="tooltip" title="fa fa-microchip" /></li>
                        <li className="icons-list-item"><i className="fa fa-microphone" data-toggle="tooltip" title="fa fa-microphone" /></li>
                        <li className="icons-list-item"><i className="fa fa-microphone-slash" data-toggle="tooltip" title="fa fa-microphone-slash" /></li>
                        <li className="icons-list-item"><i className="fa fa-minus" data-toggle="tooltip" title="fa fa-minus" /></li>
                        <li className="icons-list-item"><i className="fa fa-minus-circle" data-toggle="tooltip" title="fa fa-minus-circle" /></li>
                        <li className="icons-list-item"><i className="fa fa-minus-square" data-toggle="tooltip" title="fa fa-minus-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-minus-square-o" data-toggle="tooltip" title="fa fa-minus-square-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-mixcloud" data-toggle="tooltip" title="fa fa-mixcloud" /></li>
                        <li className="icons-list-item"><i className="fa fa-mobile" data-toggle="tooltip" title="fa fa-mobile" /></li>
                        <li className="icons-list-item"><i className="fa fa-mobile-phone" data-toggle="tooltip" title="fa fa-mobile-phone" /></li>
                        <li className="icons-list-item"><i className="fa fa-modx" data-toggle="tooltip" title="fa fa-modx" /></li>
                        <li className="icons-list-item"><i className="fa fa-money" data-toggle="tooltip" title="fa fa-money" /></li>
                        <li className="icons-list-item"><i className="fa fa-moon-o" data-toggle="tooltip" title="fa fa-moon-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-mortar-board" data-toggle="tooltip" title="fa fa-mortar-board" /></li>
                        <li className="icons-list-item"><i className="fa fa-motorcycle" data-toggle="tooltip" title="fa fa-motorcycle" /></li>
                        <li className="icons-list-item"><i className="fa fa-mouse-pointer" data-toggle="tooltip" title="fa fa-mouse-pointer" /></li>
                        <li className="icons-list-item"><i className="fa fa-music" data-toggle="tooltip" title="fa fa-music" /></li>
                        <li className="icons-list-item"><i className="fa fa-navicon" data-toggle="tooltip" title="fa fa-navicon" /></li>
                        <li className="icons-list-item"><i className="fa fa-neuter" data-toggle="tooltip" title="fa fa-neuter" /></li>
                        <li className="icons-list-item"><i className="fa fa-newspaper-o" data-toggle="tooltip" title="fa fa-newspaper-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-object-group" data-toggle="tooltip" title="fa fa-object-group" /></li>
                        <li className="icons-list-item"><i className="fa fa-object-ungroup" data-toggle="tooltip" title="fa fa-object-ungroup" /></li>
                        <li className="icons-list-item"><i className="fa fa-odnoklassniki" data-toggle="tooltip" title="fa fa-odnoklassniki" /></li>
                        <li className="icons-list-item"><i className="fa fa-odnoklassniki-square" data-toggle="tooltip" title="fa fa-odnoklassniki-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-opencart" data-toggle="tooltip" title="fa fa-opencart" /></li>
                        <li className="icons-list-item"><i className="fa fa-openid" data-toggle="tooltip" title="fa fa-openid" /></li>
                        <li className="icons-list-item"><i className="fa fa-opera" data-toggle="tooltip" title="fa fa-opera" /></li>
                        <li className="icons-list-item"><i className="fa fa-optin-monster" data-toggle="tooltip" title="fa fa-optin-monster" /></li>
                        <li className="icons-list-item"><i className="fa fa-outdent" data-toggle="tooltip" title="fa fa-outdent" /></li>
                        <li className="icons-list-item"><i className="fa fa-pagelines" data-toggle="tooltip" title="fa fa-pagelines" /></li>
                        <li className="icons-list-item"><i className="fa fa-paint-brush" data-toggle="tooltip" title="fa fa-paint-brush" /></li>
                        <li className="icons-list-item"><i className="fa fa-paper-plane" data-toggle="tooltip" title="fa fa-paper-plane" /></li>
                        <li className="icons-list-item"><i className="fa fa-paper-plane-o" data-toggle="tooltip" title="fa fa-paper-plane-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-paperclip" data-toggle="tooltip" title="fa fa-paperclip" /></li>
                        <li className="icons-list-item"><i className="fa fa-paragraph" data-toggle="tooltip" title="fa fa-paragraph" /></li>
                        <li className="icons-list-item"><i className="fa fa-paste" data-toggle="tooltip" title="fa fa-paste" /></li>
                        <li className="icons-list-item"><i className="fa fa-pause" data-toggle="tooltip" title="fa fa-pause" /></li>
                        <li className="icons-list-item"><i className="fa fa-pause-circle" data-toggle="tooltip" title="fa fa-pause-circle" /></li>
                        <li className="icons-list-item"><i className="fa fa-pause-circle-o" data-toggle="tooltip" title="fa fa-pause-circle-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-paw" data-toggle="tooltip" title="fa fa-paw" /></li>
                        <li className="icons-list-item"><i className="fa fa-paypal" data-toggle="tooltip" title="fa fa-paypal" /></li>
                        <li className="icons-list-item"><i className="fa fa-pencil" data-toggle="tooltip" title="fa fa-pencil" /></li>
                        <li className="icons-list-item"><i className="fa fa-pencil-square" data-toggle="tooltip" title="fa fa-pencil-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-pencil-square-o" data-toggle="tooltip" title="fa fa-pencil-square-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-percent" data-toggle="tooltip" title="fa fa-percent" /></li>
                        <li className="icons-list-item"><i className="fa fa-phone" data-toggle="tooltip" title="fa fa-phone" /></li>
                        <li className="icons-list-item"><i className="fa fa-phone-square" data-toggle="tooltip" title="fa fa-phone-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-photo" data-toggle="tooltip" title="fa fa-photo" /></li>
                        <li className="icons-list-item"><i className="fa fa-picture-o" data-toggle="tooltip" title="fa fa-picture-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-pie-chart" data-toggle="tooltip" title="fa fa-pie-chart" /></li>
                        <li className="icons-list-item"><i className="fa fa-pied-piper" data-toggle="tooltip" title="fa fa-pied-piper" /></li>
                        <li className="icons-list-item"><i className="fa fa-pied-piper-alt" data-toggle="tooltip" title="fa fa-pied-piper-alt" /></li>
                        <li className="icons-list-item"><i className="fa fa-pied-piper-pp" data-toggle="tooltip" title="fa fa-pied-piper-pp" /></li>
                        <li className="icons-list-item"><i className="fa fa-pinterest" data-toggle="tooltip" title="fa fa-pinterest" /></li>
                        <li className="icons-list-item"><i className="fa fa-pinterest-p" data-toggle="tooltip" title="fa fa-pinterest-p" /></li>
                        <li className="icons-list-item"><i className="fa fa-pinterest-square" data-toggle="tooltip" title="fa fa-pinterest-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-plane" data-toggle="tooltip" title="fa fa-plane" /></li>
                        <li className="icons-list-item"><i className="fa fa-play" data-toggle="tooltip" title="fa fa-play" /></li>
                        <li className="icons-list-item"><i className="fa fa-play-circle" data-toggle="tooltip" title="fa fa-play-circle" /></li>
                        <li className="icons-list-item"><i className="fa fa-play-circle-o" data-toggle="tooltip" title="fa fa-play-circle-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-plug" data-toggle="tooltip" title="fa fa-plug" /></li>
                        <li className="icons-list-item"><i className="fa fa-plus" data-toggle="tooltip" title="fa fa-plus" /></li>
                        <li className="icons-list-item"><i className="fa fa-plus-circle" data-toggle="tooltip" title="fa fa-plus-circle" /></li>
                        <li className="icons-list-item"><i className="fa fa-plus-square" data-toggle="tooltip" title="fa fa-plus-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-plus-square-o" data-toggle="tooltip" title="fa fa-plus-square-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-podcast" data-toggle="tooltip" title="fa fa-podcast" /></li>
                        <li className="icons-list-item"><i className="fa fa-power-off" data-toggle="tooltip" title="fa fa-power-off" /></li>
                        <li className="icons-list-item"><i className="fa fa-print" data-toggle="tooltip" title="fa fa-print" /></li>
                        <li className="icons-list-item"><i className="fa fa-product-hunt" data-toggle="tooltip" title="fa fa-product-hunt" /></li>
                        <li className="icons-list-item"><i className="fa fa-puzzle-piece" data-toggle="tooltip" title="fa fa-puzzle-piece" /></li>
                        <li className="icons-list-item"><i className="fa fa-qq" data-toggle="tooltip" title="fa fa-qq" /></li>
                        <li className="icons-list-item"><i className="fa fa-qrcode" data-toggle="tooltip" title="fa fa-qrcode" /></li>
                        <li className="icons-list-item"><i className="fa fa-question" data-toggle="tooltip" title="fa fa-question" /></li>
                        <li className="icons-list-item"><i className="fa fa-question-circle" data-toggle="tooltip" title="fa fa-question-circle" /></li>
                        <li className="icons-list-item"><i className="fa fa-question-circle-o" data-toggle="tooltip" title="fa fa-question-circle-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-quora" data-toggle="tooltip" title="fa fa-quora" /></li>
                        <li className="icons-list-item"><i className="fa fa-quote-left" data-toggle="tooltip" title="fa fa-quote-left" /></li>
                        <li className="icons-list-item"><i className="fa fa-quote-right" data-toggle="tooltip" title="fa fa-quote-right" /></li>
                        <li className="icons-list-item"><i className="fa fa-ra" data-toggle="tooltip" title="fa fa-ra" /></li>
                        <li className="icons-list-item"><i className="fa fa-random" data-toggle="tooltip" title="fa fa-random" /></li>
                        <li className="icons-list-item"><i className="fa fa-ravelry" data-toggle="tooltip" title="fa fa-ravelry" /></li>
                        <li className="icons-list-item"><i className="fa fa-rebel" data-toggle="tooltip" title="fa fa-rebel" /></li>
                        <li className="icons-list-item"><i className="fa fa-recycle" data-toggle="tooltip" title="fa fa-recycle" /></li>
                        <li className="icons-list-item"><i className="fa fa-reddit" data-toggle="tooltip" title="fa fa-reddit" /></li>
                        <li className="icons-list-item"><i className="fa fa-reddit-alien" data-toggle="tooltip" title="fa fa-reddit-alien" /></li>
                        <li className="icons-list-item"><i className="fa fa-reddit-square" data-toggle="tooltip" title="fa fa-reddit-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-refresh" data-toggle="tooltip" title="fa fa-refresh" /></li>
                        <li className="icons-list-item"><i className="fa fa-registered" data-toggle="tooltip" title="fa fa-registered" /></li>
                        <li className="icons-list-item"><i className="fa fa-remove" data-toggle="tooltip" title="fa fa-remove" /></li>
                        <li className="icons-list-item"><i className="fa fa-renren" data-toggle="tooltip" title="fa fa-renren" /></li>
                        <li className="icons-list-item"><i className="fa fa-reorder" data-toggle="tooltip" title="fa fa-reorder" /></li>
                        <li className="icons-list-item"><i className="fa fa-repeat" data-toggle="tooltip" title="fa fa-repeat" /></li>
                        <li className="icons-list-item"><i className="fa fa-reply" data-toggle="tooltip" title="fa fa-reply" /></li>
                        <li className="icons-list-item"><i className="fa fa-reply-all" data-toggle="tooltip" title="fa fa-reply-all" /></li>
                        <li className="icons-list-item"><i className="fa fa-resistance" data-toggle="tooltip" title="fa fa-resistance" /></li>
                        <li className="icons-list-item"><i className="fa fa-retweet" data-toggle="tooltip" title="fa fa-retweet" /></li>
                        <li className="icons-list-item"><i className="fa fa-rmb" data-toggle="tooltip" title="fa fa-rmb" /></li>
                        <li className="icons-list-item"><i className="fa fa-road" data-toggle="tooltip" title="fa fa-road" /></li>
                        <li className="icons-list-item"><i className="fa fa-rocket" data-toggle="tooltip" title="fa fa-rocket" /></li>
                        <li className="icons-list-item"><i className="fa fa-rotate-left" data-toggle="tooltip" title="fa fa-rotate-left" /></li>
                        <li className="icons-list-item"><i className="fa fa-rotate-right" data-toggle="tooltip" title="fa fa-rotate-right" /></li>
                        <li className="icons-list-item"><i className="fa fa-rouble" data-toggle="tooltip" title="fa fa-rouble" /></li>
                        <li className="icons-list-item"><i className="fa fa-rss" data-toggle="tooltip" title="fa fa-rss" /></li>
                        <li className="icons-list-item"><i className="fa fa-rss-square" data-toggle="tooltip" title="fa fa-rss-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-rub" data-toggle="tooltip" title="fa fa-rub" /></li>
                        <li className="icons-list-item"><i className="fa fa-ruble" data-toggle="tooltip" title="fa fa-ruble" /></li>
                        <li className="icons-list-item"><i className="fa fa-rupee" data-toggle="tooltip" title="fa fa-rupee" /></li>
                        <li className="icons-list-item"><i className="fa fa-s15" data-toggle="tooltip" title="fa fa-s15" /></li>
                        <li className="icons-list-item"><i className="fa fa-safari" data-toggle="tooltip" title="fa fa-safari" /></li>
                        <li className="icons-list-item"><i className="fa fa-save" data-toggle="tooltip" title="fa fa-save" /></li>
                        <li className="icons-list-item"><i className="fa fa-scissors" data-toggle="tooltip" title="fa fa-scissors" /></li>
                        <li className="icons-list-item"><i className="fa fa-scribd" data-toggle="tooltip" title="fa fa-scribd" /></li>
                        <li className="icons-list-item"><i className="fa fa-search" data-toggle="tooltip" title="fa fa-search" /></li>
                        <li className="icons-list-item"><i className="fa fa-search-minus" data-toggle="tooltip" title="fa fa-search-minus" /></li>
                        <li className="icons-list-item"><i className="fa fa-search-plus" data-toggle="tooltip" title="fa fa-search-plus" /></li>
                        <li className="icons-list-item"><i className="fa fa-sellsy" data-toggle="tooltip" title="fa fa-sellsy" /></li>
                        <li className="icons-list-item"><i className="fa fa-send" data-toggle="tooltip" title="fa fa-send" /></li>
                        <li className="icons-list-item"><i className="fa fa-send-o" data-toggle="tooltip" title="fa fa-send-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-server" data-toggle="tooltip" title="fa fa-server" /></li>
                        <li className="icons-list-item"><i className="fa fa-share" data-toggle="tooltip" title="fa fa-share" /></li>
                        <li className="icons-list-item"><i className="fa fa-share-alt" data-toggle="tooltip" title="fa fa-share-alt" /></li>
                        <li className="icons-list-item"><i className="fa fa-share-alt-square" data-toggle="tooltip" title="fa fa-share-alt-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-share-square" data-toggle="tooltip" title="fa fa-share-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-share-square-o" data-toggle="tooltip" title="fa fa-share-square-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-shekel" data-toggle="tooltip" title="fa fa-shekel" /></li>
                        <li className="icons-list-item"><i className="fa fa-sheqel" data-toggle="tooltip" title="fa fa-sheqel" /></li>
                        <li className="icons-list-item"><i className="fa fa-shield" data-toggle="tooltip" title="fa fa-shield" /></li>
                        <li className="icons-list-item"><i className="fa fa-ship" data-toggle="tooltip" title="fa fa-ship" /></li>
                        <li className="icons-list-item"><i className="fa fa-shirtsinbulk" data-toggle="tooltip" title="fa fa-shirtsinbulk" /></li>
                        <li className="icons-list-item"><i className="fa fa-shopping-bag" data-toggle="tooltip" title="fa fa-shopping-bag" /></li>
                        <li className="icons-list-item"><i className="fa fa-shopping-cart" data-toggle="tooltip" title="fa fa-shopping-cart" /></li>
                        <li className="icons-list-item"><i className="fa fa-shopping-cart" data-toggle="tooltip" title="fa fa-shopping-cart" /></li>
                        <li className="icons-list-item"><i className="fa fa-shower" data-toggle="tooltip" title="fa fa-shower" /></li>
                        <li className="icons-list-item"><i className="fa fa-sign-in" data-toggle="tooltip" title="fa fa-sign-in" /></li>
                        <li className="icons-list-item"><i className="fa fa-sign-language" data-toggle="tooltip" title="fa fa-sign-language" /></li>
                        <li className="icons-list-item"><i className="fa fa-sign-out" data-toggle="tooltip" title="fa fa-sign-out" /></li>
                        <li className="icons-list-item"><i className="fa fa-signal" data-toggle="tooltip" title="fa fa-signal" /></li>
                        <li className="icons-list-item"><i className="fa fa-signing" data-toggle="tooltip" title="fa fa-signing" /></li>
                        <li className="icons-list-item"><i className="fa fa-simplybuilt" data-toggle="tooltip" title="fa fa-simplybuilt" /></li>
                        <li className="icons-list-item"><i className="fa fa-sitemap" data-toggle="tooltip" title="fa fa-sitemap" /></li>
                        <li className="icons-list-item"><i className="fa fa-skyatlas" data-toggle="tooltip" title="fa fa-skyatlas" /></li>
                        <li className="icons-list-item"><i className="fa fa-skype" data-toggle="tooltip" title="fa fa-skype" /></li>
                        <li className="icons-list-item"><i className="fa fa-slack" data-toggle="tooltip" title="fa fa-slack" /></li>
                        <li className="icons-list-item"><i className="fa fa-sliders" data-toggle="tooltip" title="fa fa-sliders" /></li>
                        <li className="icons-list-item"><i className="fa fa-slideshare" data-toggle="tooltip" title="fa fa-slideshare" /></li>
                        <li className="icons-list-item"><i className="fa fa-smile-o" data-toggle="tooltip" title="fa fa-smile-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-snapchat" data-toggle="tooltip" title="fa fa-snapchat" /></li>
                        <li className="icons-list-item"><i className="fa fa-snapchat-ghost" data-toggle="tooltip" title="fa fa-snapchat-ghost" /></li>
                        <li className="icons-list-item"><i className="fa fa-snapchat-square" data-toggle="tooltip" title="fa fa-snapchat-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-snowflake-o" data-toggle="tooltip" title="fa fa-snowflake-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-soccer-ball-o" data-toggle="tooltip" title="fa fa-soccer-ball-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-sort" data-toggle="tooltip" title="fa fa-sort" /></li>
                        <li className="icons-list-item"><i className="fa fa-sort-alpha-asc" data-toggle="tooltip" title="fa fa-sort-alpha-asc" /></li>
                        <li className="icons-list-item"><i className="fa fa-sort-alpha-desc" data-toggle="tooltip" title="fa fa-sort-alpha-desc" /></li>
                        <li className="icons-list-item"><i className="fa fa-sort-amount-asc" data-toggle="tooltip" title="fa fa-sort-amount-asc" /></li>
                        <li className="icons-list-item"><i className="fa fa-sort-amount-desc" data-toggle="tooltip" title="fa fa-sort-amount-desc" /></li>
                        <li className="icons-list-item"><i className="fa fa-sort-asc" data-toggle="tooltip" title="fa fa-sort-asc" /></li>
                        <li className="icons-list-item"><i className="fa fa-sort-desc" data-toggle="tooltip" title="fa fa-sort-desc" /></li>
                        <li className="icons-list-item"><i className="fa fa-sort-down" data-toggle="tooltip" title="fa fa-sort-down" /></li>
                        <li className="icons-list-item"><i className="fa fa-sort-numeric-asc" data-toggle="tooltip" title="fa fa-sort-numeric-asc" /></li>
                        <li className="icons-list-item"><i className="fa fa-sort-numeric-desc" data-toggle="tooltip" title="fa fa-sort-numeric-desc" /></li>
                        <li className="icons-list-item"><i className="fa fa-sort-up" data-toggle="tooltip" title="fa fa-sort-up" /></li>
                        <li className="icons-list-item"><i className="fa fa-soundcloud" data-toggle="tooltip" title="fa fa-soundcloud" /></li>
                        <li className="icons-list-item"><i className="fa fa-space-shuttle" data-toggle="tooltip" title="fa fa-space-shuttle" /></li>
                        <li className="icons-list-item"><i className="fa fa-spinner" data-toggle="tooltip" title="fa fa-spinner" /></li>
                        <li className="icons-list-item"><i className="fa fa-spoon" data-toggle="tooltip" title="fa fa-spoon" /></li>
                        <li className="icons-list-item"><i className="fa fa-spotify" data-toggle="tooltip" title="fa fa-spotify" /></li>
                        <li className="icons-list-item"><i className="fa fa-square" data-toggle="tooltip" title="fa fa-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-square-o" data-toggle="tooltip" title="fa fa-square-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-stack-exchange" data-toggle="tooltip" title="fa fa-stack-exchange" /></li>
                        <li className="icons-list-item"><i className="fa fa-stack-overflow" data-toggle="tooltip" title="fa fa-stack-overflow" /></li>
                        <li className="icons-list-item"><i className="fa fa-star" data-toggle="tooltip" title="fa fa-star" /></li>
                        <li className="icons-list-item"><i className="fa fa-star-half" data-toggle="tooltip" title="fa fa-star-half" /></li>
                        <li className="icons-list-item"><i className="fa fa-star-half-empty" data-toggle="tooltip" title="fa fa-star-half-empty" /></li>
                        <li className="icons-list-item"><i className="fa fa-star-half-full" data-toggle="tooltip" title="fa fa-star-half-full" /></li>
                        <li className="icons-list-item"><i className="fa fa-star-half-o" data-toggle="tooltip" title="fa fa-star-half-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-star-o" data-toggle="tooltip" title="fa fa-star-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-steam" data-toggle="tooltip" title="fa fa-steam" /></li>
                        <li className="icons-list-item"><i className="fa fa-steam-square" data-toggle="tooltip" title="fa fa-steam-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-step-backward" data-toggle="tooltip" title="fa fa-step-backward" /></li>
                        <li className="icons-list-item"><i className="fa fa-step-forward" data-toggle="tooltip" title="fa fa-step-forward" /></li>
                        <li className="icons-list-item"><i className="fa fa-stethoscope" data-toggle="tooltip" title="fa fa-stethoscope" /></li>
                        <li className="icons-list-item"><i className="fa fa-sticky-note" data-toggle="tooltip" title="fa fa-sticky-note" /></li>
                        <li className="icons-list-item"><i className="fa fa-sticky-note-o" data-toggle="tooltip" title="fa fa-sticky-note-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-stop" data-toggle="tooltip" title="fa fa-stop" /></li>
                        <li className="icons-list-item"><i className="fa fa-stop-circle" data-toggle="tooltip" title="fa fa-stop-circle" /></li>
                        <li className="icons-list-item"><i className="fa fa-stop-circle-o" data-toggle="tooltip" title="fa fa-stop-circle-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-street-view" data-toggle="tooltip" title="fa fa-street-view" /></li>
                        <li className="icons-list-item"><i className="fa fa-strikethrough" data-toggle="tooltip" title="fa fa-strikethrough" /></li>
                        <li className="icons-list-item"><i className="fa fa-stumbleupon" data-toggle="tooltip" title="fa fa-stumbleupon" /></li>
                        <li className="icons-list-item"><i className="fa fa-stumbleupon-circle" data-toggle="tooltip" title="fa fa-stumbleupon-circle" /></li>
                        <li className="icons-list-item"><i className="fa fa-subscript" data-toggle="tooltip" title="fa fa-subscript" /></li>
                        <li className="icons-list-item"><i className="fa fa-subway" data-toggle="tooltip" title="fa fa-subway" /></li>
                        <li className="icons-list-item"><i className="fa fa-suitcase" data-toggle="tooltip" title="fa fa-suitcase" /></li>
                        <li className="icons-list-item"><i className="fa fa-sun-o" data-toggle="tooltip" title="fa fa-sun-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-superpowers" data-toggle="tooltip" title="fa fa-superpowers" /></li>
                        <li className="icons-list-item"><i className="fa fa-superscript" data-toggle="tooltip" title="fa fa-superscript" /></li>
                        <li className="icons-list-item"><i className="fa fa-support" data-toggle="tooltip" title="fa fa-support" /></li>
                        <li className="icons-list-item"><i className="fa fa-table" data-toggle="tooltip" title="fa fa-table" /></li>
                        <li className="icons-list-item"><i className="fa fa-tablet" data-toggle="tooltip" title="fa fa-tablet" /></li>
                        <li className="icons-list-item"><i className="fa fa-tachometer" data-toggle="tooltip" title="fa fa-tachometer" /></li>
                        <li className="icons-list-item"><i className="fa fa-tag" data-toggle="tooltip" title="fa fa-tag" /></li>
                        <li className="icons-list-item"><i className="fa fa-tags" data-toggle="tooltip" title="fa fa-tags" /></li>
                        <li className="icons-list-item"><i className="fa fa-tasks" data-toggle="tooltip" title="fa fa-tasks" /></li>
                        <li className="icons-list-item"><i className="fa fa-taxi" data-toggle="tooltip" title="fa fa-taxi" /></li>
                        <li className="icons-list-item"><i className="fa fa-telegram" data-toggle="tooltip" title="fa fa-telegram" /></li>
                        <li className="icons-list-item"><i className="fa fa-television" data-toggle="tooltip" title="fa fa-television" /></li>
                        <li className="icons-list-item"><i className="fa fa-tencent-weibo" data-toggle="tooltip" title="fa fa-tencent-weibo" /></li>
                        <li className="icons-list-item"><i className="fa fa-terminal" data-toggle="tooltip" title="fa fa-terminal" /></li>
                        <li className="icons-list-item"><i className="fa fa-text-height" data-toggle="tooltip" title="fa fa-text-height" /></li>
                        <li className="icons-list-item"><i className="fa fa-text-width" data-toggle="tooltip" title="fa fa-text-width" /></li>
                        <li className="icons-list-item"><i className="fa fa-th" data-toggle="tooltip" title="fa fa-th" /></li>
                        <li className="icons-list-item"><i className="fa fa-th-large" data-toggle="tooltip" title="fa fa-th-large" /></li>
                        <li className="icons-list-item"><i className="fa fa-th-list" data-toggle="tooltip" title="fa fa-th-list" /></li>
                        <li className="icons-list-item"><i className="fa fa-themeisle" data-toggle="tooltip" title="fa fa-themeisle" /></li>
                        <li className="icons-list-item"><i className="fa fa-thermometer" data-toggle="tooltip" title="fa fa-thermometer" /></li>
                        <li className="icons-list-item"><i className="fa fa-thermometer-0" data-toggle="tooltip" title="fa fa-thermometer-0" /></li>
                        <li className="icons-list-item"><i className="fa fa-thermometer-1" data-toggle="tooltip" title="fa fa-thermometer-1" /></li>
                        <li className="icons-list-item"><i className="fa fa-thermometer-2" data-toggle="tooltip" title="fa fa-thermometer-2" /></li>
                        <li className="icons-list-item"><i className="fa fa-thermometer-3" data-toggle="tooltip" title="fa fa-thermometer-3" /></li>
                        <li className="icons-list-item"><i className="fa fa-thermometer-4" data-toggle="tooltip" title="fa fa-thermometer-4" /></li>
                        <li className="icons-list-item"><i className="fa fa-thermometer-empty" data-toggle="tooltip" title="fa fa-thermometer-empty" /></li>
                        <li className="icons-list-item"><i className="fa fa-thermometer-full" data-toggle="tooltip" title="fa fa-thermometer-full" /></li>
                        <li className="icons-list-item"><i className="fa fa-thermometer-half" data-toggle="tooltip" title="fa fa-thermometer-half" /></li>
                        <li className="icons-list-item"><i className="fa fa-thermometer-quarter" data-toggle="tooltip" title="fa fa-thermometer-quarter" /></li>
                        <li className="icons-list-item"><i className="fa fa-thermometer-three-quarters" data-toggle="tooltip" title="fa fa-thermometer-three-quarters" /></li>
                        <li className="icons-list-item"><i className="fa fa-thumb-tack" data-toggle="tooltip" title="fa fa-thumb-tack" /></li>
                        <li className="icons-list-item"><i className="fa fa-thumbs-down" data-toggle="tooltip" title="fa fa-thumbs-down" /></li>
                        <li className="icons-list-item"><i className="fa fa-thumbs-o-down" data-toggle="tooltip" title="fa fa-thumbs-o-down" /></li>
                        <li className="icons-list-item"><i className="fa fa-thumbs-o-up" data-toggle="tooltip" title="fa fa-thumbs-o-up" /></li>
                        <li className="icons-list-item"><i className="fa fa-thumbs-up" data-toggle="tooltip" title="fa fa-thumbs-up" /></li>
                        <li className="icons-list-item"><i className="fa fa-ticket" data-toggle="tooltip" title="fa fa-ticket" /></li>
                        <li className="icons-list-item"><i className="fa fa-times" data-toggle="tooltip" title="fa fa-times" /></li>
                        <li className="icons-list-item"><i className="fa fa-times-circle" data-toggle="tooltip" title="fa fa-times-circle" /></li>
                        <li className="icons-list-item"><i className="fa fa-times-circle-o" data-toggle="tooltip" title="fa fa-times-circle-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-times-rectangle" data-toggle="tooltip" title="fa fa-times-rectangle" /></li>
                        <li className="icons-list-item"><i className="fa fa-times-rectangle-o" data-toggle="tooltip" title="fa fa-times-rectangle-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-tint" data-toggle="tooltip" title="fa fa-tint" /></li>
                        <li className="icons-list-item"><i className="fa fa-toggle-down" data-toggle="tooltip" title="fa fa-toggle-down" /></li>
                        <li className="icons-list-item"><i className="fa fa-toggle-left" data-toggle="tooltip" title="fa fa-toggle-left" /></li>
                        <li className="icons-list-item"><i className="fa fa-toggle-off" data-toggle="tooltip" title="fa fa-toggle-off" /></li>
                        <li className="icons-list-item"><i className="fa fa-toggle-on" data-toggle="tooltip" title="fa fa-toggle-on" /></li>
                        <li className="icons-list-item"><i className="fa fa-toggle-right" data-toggle="tooltip" title="fa fa-toggle-right" /></li>
                        <li className="icons-list-item"><i className="fa fa-toggle-up" data-toggle="tooltip" title="fa fa-toggle-up" /></li>
                        <li className="icons-list-item"><i className="fa fa-trademark" data-toggle="tooltip" title="fa fa-trademark" /></li>
                        <li className="icons-list-item"><i className="fa fa-train" data-toggle="tooltip" title="fa fa-train" /></li>
                        <li className="icons-list-item"><i className="fa fa-transgender" data-toggle="tooltip" title="fa fa-transgender" /></li>
                        <li className="icons-list-item"><i className="fa fa-transgender-alt" data-toggle="tooltip" title="fa fa-transgender-alt" /></li>
                        <li className="icons-list-item"><i className="fa fa-trash" data-toggle="tooltip" title="fa fa-trash" /></li>
                        <li className="icons-list-item"><i className="fa fa-trash-o" data-toggle="tooltip" title="fa fa-trash-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-tree" data-toggle="tooltip" title="fa fa-tree" /></li>
                        <li className="icons-list-item"><i className="fa fa-trello" data-toggle="tooltip" title="fa fa-trello" /></li>
                        <li className="icons-list-item"><i className="fa fa-tripadvisor" data-toggle="tooltip" title="fa fa-tripadvisor" /></li>
                        <li className="icons-list-item"><i className="fa fa-trophy" data-toggle="tooltip" title="fa fa-trophy" /></li>
                        <li className="icons-list-item"><i className="fa fa-truck" data-toggle="tooltip" title="fa fa-truck" /></li>
                        <li className="icons-list-item"><i className="fa fa-try" data-toggle="tooltip" title="fa fa-try" /></li>
                        <li className="icons-list-item"><i className="fa fa-tty" data-toggle="tooltip" title="fa fa-tty" /></li>
                        <li className="icons-list-item"><i className="fa fa-tumblr" data-toggle="tooltip" title="fa fa-tumblr" /></li>
                        <li className="icons-list-item"><i className="fa fa-tumblr-square" data-toggle="tooltip" title="fa fa-tumblr-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-turkish-lira" data-toggle="tooltip" title="fa fa-turkish-lira" /></li>
                        <li className="icons-list-item"><i className="fa fa-tv" data-toggle="tooltip" title="fa fa-tv" /></li>
                        <li className="icons-list-item"><i className="fa fa-twitch" data-toggle="tooltip" title="fa fa-twitch" /></li>
                        <li className="icons-list-item"><i className="fa fa-twitter" data-toggle="tooltip" title="fa fa-twitter" /></li>
                        <li className="icons-list-item"><i className="fa fa-twitter-square" data-toggle="tooltip" title="fa fa-twitter-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-umbrella" data-toggle="tooltip" title="fa fa-umbrella" /></li>
                        <li className="icons-list-item"><i className="fa fa-underline" data-toggle="tooltip" title="fa fa-underline" /></li>
                        <li className="icons-list-item"><i className="fa fa-undo" data-toggle="tooltip" title="fa fa-undo" /></li>
                        <li className="icons-list-item"><i className="fa fa-universal-access" data-toggle="tooltip" title="fa fa-universal-access" /></li>
                        <li className="icons-list-item"><i className="fa fa-university" data-toggle="tooltip" title="fa fa-university" /></li>
                        <li className="icons-list-item"><i className="fa fa-unlink" data-toggle="tooltip" title="fa fa-unlink" /></li>
                        <li className="icons-list-item"><i className="fa fa-unlock" data-toggle="tooltip" title="fa fa-unlock" /></li>
                        <li className="icons-list-item"><i className="fa fa-unlock-alt" data-toggle="tooltip" title="fa fa-unlock-alt" /></li>
                        <li className="icons-list-item"><i className="fa fa-unsorted" data-toggle="tooltip" title="fa fa-unsorted" /></li>
                        <li className="icons-list-item"><i className="fa fa-upload" data-toggle="tooltip" title="fa fa-upload" /></li>
                        <li className="icons-list-item"><i className="fa fa-usb" data-toggle="tooltip" title="fa fa-usb" /></li>
                        <li className="icons-list-item"><i className="fa fa-usd" data-toggle="tooltip" title="fa fa-usd" /></li>
                        <li className="icons-list-item"><i className="fa fa-user" data-toggle="tooltip" title="fa fa-user" /></li>
                        <li className="icons-list-item"><i className="fa fa-user-circle" data-toggle="tooltip" title="fa fa-user-circle" /></li>
                        <li className="icons-list-item"><i className="fa fa-user-circle-o" data-toggle="tooltip" title="fa fa-user-circle-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-user-md" data-toggle="tooltip" title="fa fa-user-md" /></li>
                        <li className="icons-list-item"><i className="fa fa-user-o" data-toggle="tooltip" title="fa fa-user-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-user-plus" data-toggle="tooltip" title="fa fa-user-plus" /></li>
                        <li className="icons-list-item"><i className="fa fa-user-secret" data-toggle="tooltip" title="fa fa-user-secret" /></li>
                        <li className="icons-list-item"><i className="fa fa-user-times" data-toggle="tooltip" title="fa fa-user-times" /></li>
                        <li className="icons-list-item"><i className="fa fa-users" data-toggle="tooltip" title="fa fa-users" /></li>
                        <li className="icons-list-item"><i className="fa fa-vcard" data-toggle="tooltip" title="fa fa-vcard" /></li>
                        <li className="icons-list-item"><i className="fa fa-vcard-o" data-toggle="tooltip" title="fa fa-vcard-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-venus" data-toggle="tooltip" title="fa fa-venus" /></li>
                        <li className="icons-list-item"><i className="fa fa-venus-double" data-toggle="tooltip" title="fa fa-venus-double" /></li>
                        <li className="icons-list-item"><i className="fa fa-venus-mars" data-toggle="tooltip" title="fa fa-venus-mars" /></li>
                        <li className="icons-list-item"><i className="fa fa-viacoin" data-toggle="tooltip" title="fa fa-viacoin" /></li>
                        <li className="icons-list-item"><i className="fa fa-viadeo" data-toggle="tooltip" title="fa fa-viadeo" /></li>
                        <li className="icons-list-item"><i className="fa fa-viadeo-square" data-toggle="tooltip" title="fa fa-viadeo-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-video-camera" data-toggle="tooltip" title="fa fa-video-camera" /></li>
                        <li className="icons-list-item"><i className="fa fa-vimeo" data-toggle="tooltip" title="fa fa-vimeo" /></li>
                        <li className="icons-list-item"><i className="fa fa-vimeo-square" data-toggle="tooltip" title="fa fa-vimeo-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-vine" data-toggle="tooltip" title="fa fa-vine" /></li>
                        <li className="icons-list-item"><i className="fa fa-vk" data-toggle="tooltip" title="fa fa-vk" /></li>
                        <li className="icons-list-item"><i className="fa fa-volume-control-phone" data-toggle="tooltip" title="fa fa-volume-control-phone" /></li>
                        <li className="icons-list-item"><i className="fa fa-volume-down" data-toggle="tooltip" title="fa fa-volume-down" /></li>
                        <li className="icons-list-item"><i className="fa fa-volume-off" data-toggle="tooltip" title="fa fa-volume-off" /></li>
                        <li className="icons-list-item"><i className="fa fa-volume-up" data-toggle="tooltip" title="fa fa-volume-up" /></li>
                        <li className="icons-list-item"><i className="fa fa-warning" data-toggle="tooltip" title="fa fa-warning" /></li>
                        <li className="icons-list-item"><i className="fa fa-wechat" data-toggle="tooltip" title="fa fa-wechat" /></li>
                        <li className="icons-list-item"><i className="fa fa-weibo" data-toggle="tooltip" title="fa fa-weibo" /></li>
                        <li className="icons-list-item"><i className="fa fa-weixin" data-toggle="tooltip" title="fa fa-weixin" /></li>
                        <li className="icons-list-item"><i className="fa fa-whatsapp" data-toggle="tooltip" title="fa fa-whatsapp" /></li>
                        <li className="icons-list-item"><i className="fa fa-wheelchair" data-toggle="tooltip" title="fa fa-wheelchair" /></li>
                        <li className="icons-list-item"><i className="fa fa-wheelchair-alt" data-toggle="tooltip" title="fa fa-wheelchair-alt" /></li>
                        <li className="icons-list-item"><i className="fa fa-wifi" data-toggle="tooltip" title="fa fa-wifi" /></li>
                        <li className="icons-list-item"><i className="fa fa-wikipedia-w" data-toggle="tooltip" title="fa fa-wikipedia-w" /></li>
                        <li className="icons-list-item"><i className="fa fa-window-close" data-toggle="tooltip" title="fa fa-window-close" /></li>
                        <li className="icons-list-item"><i className="fa fa-window-close-o" data-toggle="tooltip" title="fa fa-window-close-o" /></li>
                        <li className="icons-list-item"><i className="fa fa-window-maximize" data-toggle="tooltip" title="fa fa-window-maximize" /></li>
                        <li className="icons-list-item"><i className="fa fa-window-minimize" data-toggle="tooltip" title="fa fa-window-minimize" /></li>
                        <li className="icons-list-item"><i className="fa fa-window-restore" data-toggle="tooltip" title="fa fa-window-restore" /></li>
                        <li className="icons-list-item"><i className="fa fa-windows" data-toggle="tooltip" title="fa fa-windows" /></li>
                        <li className="icons-list-item"><i className="fa fa-won" data-toggle="tooltip" title="fa fa-won" /></li>
                        <li className="icons-list-item"><i className="fa fa-wordpress" data-toggle="tooltip" title="fa fa-wordpress" /></li>
                        <li className="icons-list-item"><i className="fa fa-wpbeginner" data-toggle="tooltip" title="fa fa-wpbeginner" /></li>
                        <li className="icons-list-item"><i className="fa fa-wpexplorer" data-toggle="tooltip" title="fa fa-wpexplorer" /></li>
                        <li className="icons-list-item"><i className="fa fa-wpforms" data-toggle="tooltip" title="fa fa-wpforms" /></li>
                        <li className="icons-list-item"><i className="fa fa-wrench" data-toggle="tooltip" title="fa fa-wrench" /></li>
                        <li className="icons-list-item"><i className="fa fa-xing" data-toggle="tooltip" title="fa fa-xing" /></li>
                        <li className="icons-list-item"><i className="fa fa-xing-square" data-toggle="tooltip" title="fa fa-xing-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-y-combinator" data-toggle="tooltip" title="fa fa-y-combinator" /></li>
                        <li className="icons-list-item"><i className="fa fa-y-combinator-square" data-toggle="tooltip" title="fa fa-y-combinator-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-yahoo" data-toggle="tooltip" title="fa fa-yahoo" /></li>
                        <li className="icons-list-item"><i className="fa fa-yc" data-toggle="tooltip" title="fa fa-yc" /></li>
                        <li className="icons-list-item"><i className="fa fa-yc-square" data-toggle="tooltip" title="fa fa-yc-square" /></li>
                        <li className="icons-list-item"><i className="fa fa-yelp" data-toggle="tooltip" title="fa fa-yelp" /></li>
                        <li className="icons-list-item"><i className="fa fa-yen" data-toggle="tooltip" title="fa fa-yen" /></li>
                        <li className="icons-list-item"><i className="fa fa-yoast" data-toggle="tooltip" title="fa fa-yoast" /></li>
                        <li className="icons-list-item"><i className="fa fa-youtube" data-toggle="tooltip" title="fa fa-youtube" /></li>
                        <li className="icons-list-item"><i className="fa fa-youtube-play" data-toggle="tooltip" title="fa fa-youtube-play" /></li>
                        <li className="icons-list-item"><i className="fa fa-youtube-square" data-toggle="tooltip" title="fa fa-youtube-square" /></li>
                        <li />
                        <li />
                        <li />
                        <li />
                        <li />
                        <li />
                        <li />
                        <li />
                        <li />
                        <li />
                        <li />
                        <li />
                        <li />
                        <li />
                        <li />
                        <li />
                        <li />
                        <li />
                        <li />
                        <li />
                        <li />
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapStateToProps = state => ({
  fixNavbar: state.settings.isFixNavbar
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(Icons);