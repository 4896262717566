const ActionData = [
  {
    Id: 1,
    type:
      'https://cdn4.iconfinder.com/data/icons/vectory-bonus-3/40/button_add-512.png',
    name: 'John',

    date: '2018-01-01',

    job_associated: 'Web Developer',
    comment:
      'Never is this principle more pertinent than when dealing with type, the bread and butter of Web-borne communication. A well-set paragraph of text is not supposed to wow the reader; the wowing should be left to the idea or observation for which the paragraph is a vehicle. In fact, the perfect paragraph is unassuming to the point of near invisibility. That is not to say that the appearance of your text should have no appeal at all. On the contrary: well-balanced, comfortably read typography is a thing of beauty; it’s just not the arresting sort of beauty that might distract you from reading.'
  },
  {
    Id: 2,
    type:
      'https://icon-library.com/images/minus-icon/minus-icon-14.jpg',
    name: 'Alex',

    date: '2016-01-01',

    job_associated: 'Content Writing',
    comment:
      'Never is this principle more pertinent than when dealing with type, the bread and butter of Web-borne communication. A well-set paragraph of text is not supposed to wow the reader; the wowing should be left to the idea or observation for which the paragraph is a vehicle. In fact, the perfect paragraph is unassuming to the point of near invisibility. That is not to say that the appearance of your text should have no appeal at all. On the contrary: well-balanced, comfortably read typography is a thing of beauty; it’s just not the arresting sort of beauty that might distract you from reading.'
  }
]
export default ActionData
