import React, { useState, useEffect } from "react";
import {
  getAllNotifications,
  getUnreadNotificationsCount,
  markAllNotificationRead,
  markNotificationRead,
} from "../../../../redux/actions/taskManagementActions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DataLoader } from "../../../constants/loaders";
import AvatarImage from "../../../../assets/images/avatar.jpg";
import { CustomErrors } from "../../../constants/noRecordFound";

const NotificationsDropdown = () => {
  const [allNotifications, setAllNotifications] = useState([]);
  const [notificationCounts, setNotificationsCount] = useState("");
  const [isNotificationDropdownOpen, setIsNotificationDropdownOpen] =
    useState(false);
  const [notificationsIds, setNotificationsIds] = useState([]);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const taskSelector = useSelector((state) => state.tasks);
  const allNotificationsSelector = useSelector(
    (state) => state.tasks.all_notifications.notifications
  );
  const notificationsCountSelector = useSelector(
    (state) => state.tasks.notifications_count.unread_notifications
  );

  const fetchAllNotifications = async () => {
    try {
      await dispatch(getAllNotifications());
    } catch (error) {
      throw error;
    }
  };

  const fetchUnreadNotification = async () => {
    try {
      await dispatch(getUnreadNotificationsCount());
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    fetchAllNotifications();
    fetchUnreadNotification();
  }, [notificationCounts]);

  useEffect(() => {
    if (allNotificationsSelector) {
      setAllNotifications(allNotificationsSelector);

      let ids = [];
      allNotificationsSelector.forEach((items) => {
        ids.push(items.id);
      });
      setNotificationsIds(ids);
    }

    if (notificationsCountSelector) {
      setNotificationsCount(notificationsCountSelector);
    }
  }, [allNotificationsSelector, notificationsCountSelector]);

  const handleMarkNotificationsRead = () => {
    dispatch(markNotificationRead(notificationsIds));
  };

  useEffect(() => {
    if (isNotificationDropdownOpen) {
      handleMarkNotificationsRead();
    }
  }, [isNotificationDropdownOpen]);

  const handleMarkAllNotification = () => {
    dispatch(
      markAllNotificationRead(fetchAllNotifications, fetchUnreadNotification)
    );
    // fetchUnreadNotification();
  };

  return (
    <div className="dropdown d-flex">
      <a
        className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
        // data-toggle={`dropdown`}
        onClick={() => {
          setIsNotificationDropdownOpen(!isNotificationDropdownOpen);
        }}
      >
        <i className="fa fa-bell" />
        {notificationsCountSelector > 0 ? (
          <span
            className="badge badge-primary nav-unread"
            style={{ top: "-5px", right: "-1px" }}
          >
            {notificationsCountSelector}
          </span>
        ) : null}
      </a>
      {isNotificationDropdownOpen && (
        <div
          className={`dropdown-menu dropdown-menu-right dropdown-menu-arrow pt-0 ${
            isNotificationDropdownOpen ? "show" : ""
          }`}
        >
          <ul className="list-unstyled feeds_widget">
            {!taskSelector.isLoading ? (
              <>
                {allNotifications && allNotifications.length > 0 ? (
                  allNotifications.map((items, index) => {
                    if (items.read_at === null) {
                      return (
                        <li key={index} style={{ background: "#c0d6e4" }}>
                          <div className="feeds-left mr-3">
                            <img
                              src={items.image ? items.image : AvatarImage}
                              alt={"image"}
                              className="rounded mr-1"
                              width={30}
                              height={30}
                            />
                          </div>
                          <div className="feeds-body">
                            <h4 className="title text-danger">
                              {items.title}
                              <small className="float-right text-muted"></small>
                            </h4>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: items.description,
                              }}
                            />
                          </div>
                        </li>
                      );
                    } else {
                      return (
                        <li key={index}>
                          <div className="feeds-left mr-3">
                            <img
                              src={items.image ? items.image : AvatarImage}
                              alt={"image"}
                              className="rounded mr-1"
                              width={30}
                              height={30}
                            />
                          </div>
                          <div className="feeds-body">
                            <h4 className="title text-danger">
                              {items.title}
                              <small className="float-right text-muted"></small>
                            </h4>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: items.description,
                              }}
                            />
                          </div>
                        </li>
                      );
                    }
                  })
                ) : (
                  <CustomErrors message={"Empty Notifications"} />
                )}
              </>
            ) : (
              <div className={"d-flex justify-content-center"}>
                <DataLoader />
              </div>
            )}
          </ul>
          <div className="dropdown-divider" />
          <div className={"w-100 d-flex justify-content-center"}>
            {allNotifications.length > 0 && (
              <a
                href={"#"}
                className="dropdown-item text-center text-muted-dark"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(
                    getAllNotifications(taskSelector.all_notifications.offset)
                  );
                }}
              >
                {t("header.notifications.loadMore")}
              </a>
            )}
            <a
              href={"#"}
              className="dropdown-item text-center text-muted-dark"
              onClick={(e) => {
                e.stopPropagation();
                handleMarkAllNotification();
              }}
            >
              {t("header.notifications.markAllAsRead")}
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationsDropdown;
