export const populatePersonalInformation = (
  candidate_ID,
  candidateProfile,
  setFormValues,
  setTags,
  setConsent,
  setNewsLetter,
  setJobTypes,
  setIsFullTimeSelected,
  setIsPartTimeSelected
) => {
  if (candidate_ID) {
    let populateData = {
      first_name:
        candidateProfile.first_name === null ? "" : candidateProfile.first_name,
      last_name:
        candidateProfile.last_name === null ? "" : candidateProfile.last_name,
      gender: candidateProfile.gender === null ? "" : candidateProfile.gender,
      dob: candidateProfile.dob === null ? "" : candidateProfile.dob,
      personal_information:
        candidateProfile.personal_information === null
          ? ""
          : candidateProfile.personal_information,
      personal_code:
        candidateProfile.personal_code === null
          ? ""
          : candidateProfile.personal_code,
      phone: candidateProfile.phone === null ? "" : candidateProfile.phone,
      email: candidateProfile.email === null ? "" : candidateProfile.email,
      location:
        candidateProfile.location === null ? "" : candidateProfile.location,
      nationalities: [],
      marital_status:
        candidateProfile.marital_status === null
          ? ""
          : candidateProfile.marital_status,
      age: candidateProfile.age === null ? "" : candidateProfile.age,
      tags: [],
      description:
        candidateProfile.description === null
          ? ""
          : candidateProfile.description,
      source: candidateProfile.source === null ? "" : candidateProfile.source,
      job_type: [],
      desired_job:
        candidateProfile.desired_job === null
          ? ""
          : candidateProfile.desired_job,
      desired_salary:
        candidateProfile.desired_salary === null
          ? ""
          : candidateProfile.desired_salary,
      desired_job_time:
        candidateProfile.desired_job_time === null
          ? ""
          : candidateProfile.desired_job_time,
      desired_job_location:
        candidateProfile.desired_job_location === null
          ? ""
          : candidateProfile.desired_job_location,
      // action_id: candidateProfile.action_id,
      status: candidateProfile.status === null ? "" : candidateProfile.status,
      consent: candidateProfile.consent === 1,
      newsletter: candidateProfile.newsletter === 1,
      photo: candidateProfile.photo,
      driving_licenses: [],
      recommendations: [],
      keywords: [],
      children_qty:
        candidateProfile.children_qty === null
          ? ""
          : candidateProfile.children_qty,
    };

    if (candidateProfile.consent === 1) {
      setConsent(true);
    } else {
      setConsent(false);
    }

    if (candidateProfile.newsletter === 1) {
      setNewsLetter(true);
    } else {
      setNewsLetter(false);
    }

    if (candidateProfile.tags?.length > 0) {
      let tagsArr = [];
      candidateProfile.tags.map((tag) => {
        tagsArr.push(tag.name);
      });
      let uniqueTags = [...new Set(tagsArr)];
      setTags(uniqueTags);
    }

    const fullTimeElem = document.getElementById("job-type-full-time");
    const partTimeElem = document.getElementById("job-type-part-time");

    let oldJobTypes = [];
    if (candidateProfile.jobTypes?.length > 0) {
      for (let types of candidateProfile.jobTypes) {
        oldJobTypes.push(types.id);

        if (types.id === 1) {
          setIsFullTimeSelected(true);
          fullTimeElem.checked = true;
        } else if (types.id === 2) {
          setIsPartTimeSelected(true);
          partTimeElem.checked = true;
        } else {
          setIsFullTimeSelected(false);
          setIsPartTimeSelected(false);
        }
      }
    }
    populatePersonalInformation.job_type = oldJobTypes;
    setJobTypes(oldJobTypes);

    if (candidateProfile.nationalities?.length > 0) {
      for (let nationality of candidateProfile.nationalities) {
        if (populateData.hasOwnProperty("nationalities")) {
          populateData.nationalities.push(nationality.id);
        }
      }
    }

    if (candidateProfile.driving_licenses?.length > 0) {
      for (let license of candidateProfile.driving_licenses) {
        if (populateData.hasOwnProperty("driving_licenses")) {
          populateData.driving_licenses.push({
            id: license.id,
            level: license.level,
          });
        }
      }
    }

    if (candidateProfile.recommendations?.length > 0) {
      for (let recommenders of candidateProfile.recommendations) {
        if (populateData.hasOwnProperty("recommendations")) {
          populateData.recommendations.push({
            id: recommenders.id,
            recommendation: recommenders.recommendation,
          });
        }
      }
    }

    if (candidateProfile.keywords !== null) {
      let splitKeywords = candidateProfile.keywords.split(":::");
      for (let keyword of splitKeywords) {
        populateData.keywords.push(keyword);
      }
    }

    setFormValues(populateData);
  }
};

export const populateJobHistory = (candidate_ID, jobHistory, setFormValues) => {
  if (candidate_ID) {
    let candidateJobData = {
      jobs: [],
    };

    for (let job in jobHistory) {
      let jobHistoryObj = jobHistory[job];
      if (candidateJobData.hasOwnProperty("jobs")) {
        candidateJobData.jobs.push({
          id: jobHistoryObj.id,
          candidatecv_id: jobHistoryObj.candidatecv_id,
          company_name: jobHistoryObj.company_name,
          designation: jobHistoryObj.designation,
          starting_year: jobHistoryObj.starting_year,
          starting_month:
            jobHistoryObj.starting_month !== null
              ? jobHistoryObj.starting_month
              : "",
          starting_day:
            jobHistoryObj.starting_day !== null
              ? jobHistoryObj.starting_day
              : "",
          ending_year:
            jobHistoryObj.ending_year !== null ? jobHistoryObj.ending_year : "",
          ending_month:
            jobHistoryObj.ending_month !== null
              ? jobHistoryObj.ending_month
              : "",
          ending_day:
            jobHistoryObj.ending_day !== null ? jobHistoryObj.ending_day : "",
          still_working: jobHistoryObj.still_working,
          description:
            jobHistoryObj.description !== null ? jobHistoryObj.description : "",
          work_place:
            jobHistoryObj.work_place !== null ? jobHistoryObj.work_place : "",
        });
        // setStillWorkingChecked(jobHistoryObj.still_working);
        // setStartYear(jobHistoryObj.starting_year)
        // setStartMonth(jobHistoryObj.starting_month)
        // setEndYear(jobHistoryObj.ending_year)
        // setEndMonth(jobHistoryObj.ending_month)
      } else {
        candidateJobData.jobs = [];
        candidateJobData.jobs.push({
          id: jobHistoryObj.id,
          candidatecv_id: jobHistoryObj.candidatecv_id,
          company_name: jobHistoryObj.company_name,
          designation: jobHistoryObj.designation,
          starting_year: jobHistoryObj.starting_year,
          starting_month:
            jobHistoryObj.starting_month !== null
              ? jobHistoryObj.starting_month
              : "",
          starting_day:
            jobHistoryObj.starting_day !== null
              ? jobHistoryObj.starting_day
              : "",
          ending_year:
            jobHistoryObj.ending_year !== null ? jobHistoryObj.ending_year : "",
          ending_month:
            jobHistoryObj.ending_month !== null
              ? jobHistoryObj.ending_month
              : "",
          ending_day:
            jobHistoryObj.ending_day !== null ? jobHistoryObj.ending_day : "",
          still_working: jobHistoryObj.still_working,
          description:
            jobHistoryObj.description !== null ? jobHistoryObj.description : "",
          work_place:
            jobHistoryObj.work_place !== null ? jobHistoryObj.work_place : "",
        });
        // setStartYear(jobHistoryObj.starting_year)
        // setStartMonth(jobHistoryObj.starting_month)
        // setEndYear(jobHistoryObj.ending_year)
        // setEndMonth(jobHistoryObj.ending_month)
      }
      // setStillWorkingChecked(jobHistoryObj.still_working);
    }

    if (candidateJobData.jobs.length > 0) {
      for (let index = 0; index < candidateJobData.jobs.length; index++) {
        const stillWorkingElem = document.getElementById(
          `still-working.${index}`
        );
        const isStillWorking = candidateJobData.jobs[index].still_working;
        if (stillWorkingElem) {
          if (isStillWorking) {
            stillWorkingElem.checked = true;
          } else {
            stillWorkingElem.checked = false;
          }
        }
      }
    }

    setFormValues(candidateJobData);
  }
};

export const populateEducationHistory = (
  candidate_ID,
  educationHistory,
  setFormValues
) => {
  if (candidate_ID) {
    let candidateEducationData = {
      education: [],
    };

    for (let education in educationHistory) {
      let educationObj = educationHistory[education];
      if (education) {
        candidateEducationData.education.push({
          id: educationObj.id,
          candidatecv_id: educationObj.candidatecv_id,
          level_id: educationObj.level_id !== null ? educationObj.level_id : "",
          institute: educationObj.institute,
          starting_year: educationObj.starting_year,
          starting_month:
            educationObj.starting_month !== null
              ? educationObj.starting_month
              : "",
          starting_day:
            educationObj.starting_day !== null ? educationObj.starting_day : "",
          ending_year:
            educationObj.ending_year !== null ? educationObj.ending_year : "",
          ending_month:
            educationObj.ending_month !== null ? educationObj.ending_month : "",
          ending_day:
            educationObj.ending_day !== null ? educationObj.ending_day : "",
          still_studying: educationObj.still_studying,
          speciality:
            educationObj.speciality !== null ? educationObj.speciality : "",
          additonal_information:
            educationObj.additonal_information !== null
              ? educationObj.additonal_information
              : "",
        });
      } else {
        candidateEducationData.education = [];
        candidateEducationData.education.push({
          id: educationObj.id,
          candidatecv_id: educationObj.candidatecv_id,
          level_id: educationObj.level_id !== null ? educationObj.level_id : "",
          institute: educationObj.institute,
          starting_year: educationObj.starting_year,
          starting_month:
            educationObj.starting_month !== null
              ? educationObj.starting_month
              : "",
          starting_day:
            educationObj.starting_day !== null ? educationObj.starting_day : "",
          ending_year:
            educationObj.ending_year !== null ? educationObj.ending_year : "",
          ending_month:
            educationObj.ending_month !== null ? educationObj.ending_month : "",
          ending_day:
            educationObj.ending_day !== null ? educationObj.ending_day : "",
          still_studying: educationObj.still_studying,
          speciality:
            educationObj.speciality !== null ? educationObj.speciality : "",
          additonal_information:
            educationObj.additonal_information !== null
              ? educationObj.additonal_information
              : "",
        });
      }
      // setStillWorkingChecked(educationObj.still_studying);
    }

    if (candidateEducationData.education.length > 0) {
      for (
        let index = 0;
        index < candidateEducationData.education.length;
        index++
      ) {
        const stillStudyingElem = document.getElementById(
          `still-studying.${index}`
        );
        const isStillStudying =
          candidateEducationData.education[index].still_studying;
        if (stillStudyingElem) {
          if (isStillStudying) {
            stillStudyingElem.checked = true;
          } else {
            stillStudyingElem.checked = false;
          }
        }
      }
    }

    setFormValues(candidateEducationData);
  }
};

export const populateAdditionalCourses = (
  candidateCvId,
  additionalCourseData,
  setFormValues
) => {
  if (candidateCvId) {
    let candidateAdditionalCourses = {
      candidatecv_id: candidateCvId,
      courses: [],
    };

    for (let course in additionalCourseData) {
      let additionalCourseObj = additionalCourseData[course];
      candidateAdditionalCourses.courses.push({
        id: additionalCourseObj.id,
        title: additionalCourseObj.title,
        description: additionalCourseObj.description,
        total_hours:
          additionalCourseObj.total_hours === null
            ? ""
            : additionalCourseObj.total_hours,
        starting_year: additionalCourseObj.starting_year,
        starting_month:
          additionalCourseObj.starting_month === null
            ? ""
            : additionalCourseObj.starting_month,
        starting_day:
          additionalCourseObj.starting_day === null
            ? ""
            : additionalCourseObj.starting_day,
        ending_year: additionalCourseObj.ending_year,
        ending_month:
          additionalCourseObj.ending_month === null
            ? ""
            : additionalCourseObj.ending_month,
        ending_day:
          additionalCourseObj.ending_day === null
            ? ""
            : additionalCourseObj.ending_day,
      });
      setFormValues(candidateAdditionalCourses);
    }
  }
};

export const populateLanguages = (
  candidateCvId,
  languagesById,
  setFormValues
) => {
  let candidateLanguageData = {
    candidatecv_id: candidateCvId,
    language: [],
  };

  if (languagesById.length > 0) {
    for (let language of languagesById) {
      if (languagesById) {
        candidateLanguageData.language.push({
          id: language.id,
          name: language.name,
          speaking: language.pivot.speaking,
          reading_writing: language.pivot.reading_writing,
          // level: language.pivot.level,
        });
      } else {
        candidateLanguageData.language = [];
        candidateLanguageData.language.push({
          id: language.id,
          name: language.name,
          speaking: language.pivot.speaking,
          reading_writing: language.pivot.reading_writing,
          // level: language.pivot.level,
        });
      }
    }
  }
  setFormValues(candidateLanguageData);
};
