import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { baseURL } from "../baseURL";
import axios from "axios";
import { AutoComplete } from "antd";
// import LanguagesDropdown from "./Dropdowns/Languages";
// import AccessibilityDropDown from "./Dropdowns/Accessibility";
import NotificationsDropdown from "./Dropdowns/Notifications";
import ProfileDropdown from "./Dropdowns/Profile";

const Header = (props) => {
  const { fixNavbar, darkHeader } = props;

  const history = useHistory();

  const [globalSearchData, setGlobalSearchData] = useState([]);
  const [options, setOptions] = useState([]);

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleGlobalSearch = (e) => {
    let searchValue = e;
    let searchOptions = [];
    if (searchValue.length > 0) {
      const token = localStorage.getItem("token");
      axios
        .post(
          `${baseURL}api/global-search`,
          { search_query: searchValue },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setGlobalSearchData(response.data.data);
          if (response.data.data.length > 0) {
            response.data.data.map((item) => {
              searchOptions.push({
                value: item.name,
                label: item.name,
                path: item.path,
                id: item.id,
              });
            });
            setOptions(searchOptions);
          }
        });
    }
  };

  const onSelect = (data, option) => {
    let id = option.id;
    let path = option.path;
    setOptions([]);
    setGlobalSearchData([]);

    if (path === "/hr-candidate/view/") {
      history.push(`/hr-candidate/view/${id}/1`);
    } else if (path === "/hr-companies/view/") {
      history.push(`/hr-companies/view/${id}`);
    } else if (path === "/hr-jobs/view/") {
      history.push(`/hr-jobs/view/${id}`);
    }
  };

  const optimizedGlobalSearchFn = useCallback(debounce(handleGlobalSearch), []);

  return (
    <div
      id="page_top"
      // className={isFixNavbar ? "sticky-top" : "" + this.props.dataFromParent === 'dark' ? 'section-body top_dark' : 'section-body'}
      className={`section-body ${fixNavbar ? "sticky-top" : ""} ${
        darkHeader ? "top_dark" : ""
      }`}
    >
      <div className="container-fluid">
        <div className="page-header">
          <div className="left">
            {/* <h1 className="page-title">{props.dataFromSubParent}</h1>
              <select className="custom-select">
                <option>Year</option>
                <option>Month</option>
                <option>Week</option>
              </select> */}
            <div className="input-group xs-hide">
              <AutoComplete
                options={options}
                style={{ width: 200 }}
                onSelect={onSelect}
                onSearch={optimizedGlobalSearchFn}
                placeholder="Search..."
                notFoundContent={"No Search Found"}
                allowClear={true}
              />
            </div>
          </div>
          <div className="right">
            <ul className="nav nav-pills">
              {/* <LanguagesDropdown /> */}
              {/* <AccessibilityDropDown /> */}
            </ul>
            <div className="notification d-flex">
              <NotificationsDropdown />
              <ProfileDropdown />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
