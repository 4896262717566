const profileData = [
    {
     Id:1,
        photo: "https://www.w3schools.com/howto/img_avatar.png",
      name: "John",
      email: "john@gmail.com",
      phone: "043333333",
    gender: "Male",
    date_of_birth:'1999-01-01',
      location: "New York",
      date:'2018-01-01',

      onwer:"Alex",
      user_tag:["#joined a newsletter"],
      description:"hello i am here",
      source:"Facebook",
      job_history:"XYZ limited",
      skills:["#Content writing","#web", "#mobile"],
      language_level:"A1 to C2",
      language:"English",
      consent :true,
      newsletter:true,
      education:"MS"

    },
     
  ];
  export default profileData