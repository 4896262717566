const getFontSizeStyle = (fontSize, defaultValue = "0.875rem") => {
  switch (fontSize) {
    case "Large":
      return { fontSize: "large" };
    case "Extra Large":
      return { fontSize: "x-large" };
    default:
      return { fontSize: defaultValue };
  }
};

export { getFontSizeStyle };
