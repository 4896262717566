import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import { baseURL } from "../../components/Shared/baseURL";
import Toast from "../constants/toast";
import Axios from 'axios';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');

  const history = useHistory();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handleSendPassword = async () => {
    try {
      const response = await Axios.post(`${baseURL}api/auth/forgot-password`, { email });      
      if (response.data.code === -1) {
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        history.push('/updatePasswordEmailSend');
      }
    } catch (error) {
      toast.error('Error sending password reset email', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  return (
    <div className="auth">
      <div className="auth_left">
        <div className="card">
          <div className="text-center mb-5">
            <Link className="header-brand" to="/">
              <i className="fe fe-command brand-logo" />
            </Link>
          </div>
          <div className="card-body">
            <div className="card-title">Unustasin parooli</div>
            <p className="text-muted">
            Palun sisesta oma emaili aadress ning saadame sulle lingi parooli muutmiseks.
            </p>
            <div className="form-group">
              <label className="form-label" htmlFor="exampleInputEmail1">
                Emaili aadress
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Sisesta email"
                value={email}
                onChange={handleEmailChange}
              />              
            </div>
            <div className="form-footer">
              <button
                className="btn btn-primary btn-block"
                onClick={handleSendPassword}>
                Saada link
              </button>
            </div>
          </div>
          <div className="text-center text-muted">
            Unusta ära, <Link to="/login">palun suuna mind tagasi</Link> Logi sisse lehele.
          </div>
        </div>
      </div>
      <div className="auth_right">
        <div className="carousel slide" data-ride="carousel" data-interval={3000}>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="assets/images/slider1.svg" className="img-fluid" alt="login page" />
              <div className="px-4 mt-4">
                <h4>MSHR</h4>
              </div>
            </div>
            <div className="carousel-item">
              <img src="assets/images/slider2.svg" className="img-fluid" alt="login page" />
              <div className="px-4 mt-4">
                <h4>MSHR</h4>
              </div>
            </div>
            <div className="carousel-item">
              <img src="assets/images/slider3.svg" className="img-fluid" alt="login page" />
              <div className="px-4 mt-4">
                <h4>MSHR</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast />
    </div>
  );
};
export default ForgotPassword;
