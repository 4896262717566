// HIGHLIGHT FIELD COLOR
// export const highlightFieldColor = "#C0C0C0"; // grey

// export const highlightFieldColor = "#00FF00"; // green
// export const highlightClickedFieldColor = "#00A300"; // bit darker green

export const highlightFieldColor = "#ffffff"; // green
export const highlightClickedFieldColor = "#ffffff"; // bit darker green

// COMMOM COLORS
export const redColor = "#FF0000";
export const whiteColor = "#ffffff";
