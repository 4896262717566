const UserTagData =[
    {
        id:1,
        name:"#joined a newsletter",
        status:false
    },
    {
        id:2,
        name:"#owns a car",
        status:false
    },
    
]
  export default UserTagData