import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class UpdateEmailPasswordSend extends Component {
	render() {
		return (
			<div className="auth">
				<div className="auth_left">
					<div className="card">
						<div className="text-center mb-5">
							<Link className="header-brand" to="/">
								<i className="fe fe-command brand-logo" />
							</Link>
						</div>
						<div className="card-body">
							<div className="card-title">Kiri saadetud!</div>
							 <p className="text-muted">
								Oleme saatnud sulle emaili, kus on kirjeldatud järgnevad sammud parooli muutmiseks. Kui ei soovi parooli muuta, siis ignoreeri saadetud kirja ning {' '}
								<Link to="/login">
									logi sisse
								</Link>
								{' '} siit.
							</p>
						</div>
					</div>
				</div>
				<div className="auth_right">
					<div className="carousel slide" data-ride="carousel" data-interval={3000}>
						<div className="carousel-inner">
							<div className="carousel-item active">
								<img src="assets/images/slider1.svg" className="img-fluid" alt="login page" />
								<div className="px-4 mt-4">
									<h4>MSHR</h4>
								</div>
							</div>
							<div className="carousel-item">
								<img src="assets/images/slider2.svg" className="img-fluid" alt="login page" />
								<div className="px-4 mt-4">
									<h4>MSHR</h4>
								</div>
							</div>
							<div className="carousel-item">
								<img src="assets/images/slider3.svg" className="img-fluid" alt="login page" />
								<div className="px-4 mt-4">
									<h4> MSHR</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
