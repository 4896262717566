import { object, date } from "yup";
import i18n from "../../../Shared/i18n/i18n";

// messages
const requiredMessage = i18n.t("formValidationMessages.required");

// validation schemas
export const TaskValidationSchema = object().shape({
  // description: string().required(requiredMessage),
  deadline: date()
    .typeError("Must be a valid date")
    .required(requiredMessage)
    .transform((value) => {
      return value ? new Date(value) : null;
    })
    .test("is-valid-date", "Invalid date format", (value) => {
      return value instanceof Date && !isNaN(value);
    }),
});
