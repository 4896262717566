import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { logoutUser } from "../../../../redux/actions/usersAction";

const ProfileDropdown = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handleLogout = () => {
    dispatch(logoutUser(history));
  };

  return (
    <div className="dropdown d-flex">
      <a
        href="/#"
        className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
        data-toggle="dropdown"
      >
        <i className="fa fa-user" />
      </a>
      <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
        {/* <NavLink
                      to="/jobportal-settings/1"
                      className="dropdown-item"
                    >
                      <i className="dropdown-icon fe fe-user" />{" "}
                      {t("header.profileSettings.profile")}
                    </NavLink> */}
        <NavLink to="/jobportal-settings/1" className="dropdown-item">
          <i className="dropdown-icon fe fe-settings" />{" "}
          {t("header.profileSettings.settings")}
        </NavLink>
        {/* <a className="dropdown-item">
                      <span className="float-right">
                        <span className="badge badge-primary">6</span>
                      </span>
                      <i className="dropdown-icon fe fe-mail" />{" "}
                      {t("header.profileSettings.inbox")}
                    </a> */}
        {/* <a className="dropdown-item">
                      <i className="dropdown-icon fe fe-send" />{" "}
                      {t("header.profileSettings.messages")}
                    </a> */}
        <div className="dropdown-divider" />
        {/* <a className="dropdown-item">
                      <i className="dropdown-icon fe fe-help-circle" />{" "}
                      {t("header.profileSettings.needHelp")}?
                    </a> */}
        <NavLink
          to={"#"}
          className="dropdown-item bg-none"
          onClick={() => handleLogout()}
        >
          <i className="dropdown-icon fe fe-log-out" />
          {t("header.profileSettings.signOut")}
        </NavLink>
      </div>
    </div>
  );
};

export default ProfileDropdown;
