import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Collapse } from "reactstrap";
import "./candidate.css";
import { useDispatch, useSelector } from "react-redux";
import { getCandidateTimeline } from "../../../redux/actions/candidatesAction";
import { DataLoader, Loader } from "../../constants/loaders";
import { Button, FormGroup } from "reactstrap";
import { NoRecordFound } from "../../constants/noRecordFound";
import { Field, Form, Formik } from "formik";
import { postTimelineComment } from "../../../redux/actions/timelineActions";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TimelineActionModal from "./Modals/TimelineActionModal";
import { getFontSizeStyle } from "../../helpers/helpers";

const Timeline = ({ candidateItemById, triggerUpdate, setTriggerUpdate }) => {
  const { id } = useParams();
  const candidatesDataPerRow = 3;
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const timelineCommentsInitialValues = {
    timeline_id: "",
    body: "",
  };

  const [timelineCommentsFormValues, setTimelineCommentsFormValues] = useState(
    timelineCommentsInitialValues
  );
  const [timelineNewActionModal, setTimelineNewActionModal] = useState(false);
  const [nextCandidatesRow, setNextCandidatesRow] =
    useState(candidatesDataPerRow);
  const [timelineData, setTimelineData] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");
  const [timelineID, setTimelineID] = useState(null);

  const [open, setOpen] = React.useState({});

  const handleCandidateDataPerRow = () => {
    setNextCandidatesRow(nextCandidatesRow + candidatesDataPerRow);
  };

  const timelineToggle = () => {
    setTimelineNewActionModal(!timelineNewActionModal);
  };

  const candidateSelector = useSelector((state) => state.candidates);
  const timelineSelector = useSelector((state) => state.timeline);
  const settingsSelector = useSelector((state) => state.settings);

  const fetchData = async () => {
    try {
      const response = await dispatch(getCandidateTimeline(id));
      setTimelineData(response.payload.timeline);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    fetchData();
  }, [triggerUpdate]);

  const handleClick = (id, commentData) => {
    const comment = commentData.comments;
    const commentID = commentData.id;

    setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));

    if (comment.length > 0) {
      setTimelineID(null);
    } else {
      setTimelineID(commentID);
    }
  };

  const handleSorting = (e) => {
    if (sortingOrder === "asc") {
      const sorted = [...timelineData].sort((a, b) =>
        a[e].toLowerCase() > b[e].toLowerCase() ? 1 : -1
      );
      setTimelineData(sorted);
      setSortingOrder("dsc");
    }
    if (sortingOrder === "dsc") {
      const sorted = [...timelineData].sort((a, b) =>
        a[e].toLowerCase() < b[e].toLowerCase() ? 1 : -1
      );
      setTimelineData(sorted);
      setSortingOrder("asc");
    }
  };

  const handleTimelineCommentSubmit = (values) => {
    // let element = document.getElementsByName("timeline_id");
    // values["timeline_id"] = element[0].value;
    values["timeline_id"] = timelineID;
    dispatch(postTimelineComment(values, id, fetchData));
  };

  return (
    <>
      <TimelineActionModal
        timelineNewActionModal={timelineNewActionModal}
        setTimelineNewActionModal={setTimelineNewActionModal}
        timelineToggle={timelineToggle}
        fetchData={fetchData}
      />
      <Card>
        <CardHeader className="float-right w-100 d-flex justify-content-between">
          <CardTitle>
            <h5 style={getFontSizeStyle(settingsSelector.fontSize, "1.25rem")}>
              {t("timelineHistory.heading")}
            </h5>
          </CardTitle>
          <div>
            <Button
              type={"button"}
              color={"primary"}
              onClick={() => setTimelineNewActionModal(true)}
              style={getFontSizeStyle(settingsSelector.fontSize, "14px")}
            >
              {t("timelineHistory.newActivityButton")}
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <table className={"table  mb-2 table-sortable"}>
            <thead>
              <tr>
                <th
                  onClick={() => handleSorting("title")}
                  className={sortingOrder === "asc" ? "asc" : "dsc"}
                  style={{
                    paddingRight: "50px",
                    cursor: "pointer",
                    ...getFontSizeStyle(settingsSelector.fontSize, "14px"),
                  }}
                >
                  {t("timelineHistory.name")}
                </th>

                <th
                  onClick={() => handleSorting("created_at")}
                  className={sortingOrder === "asc" ? "asc" : "dsc"}
                  style={{
                    paddingRight: "50px",
                    cursor: "pointer",
                    ...getFontSizeStyle(settingsSelector.fontSize, "14px"),
                  }}
                >
                  {t("timelineHistory.date")}
                </th>
              </tr>
            </thead>
          </table>
          {!candidateSelector.isLoading ? (
            <>
              {timelineData && timelineData.length > 0 ? (
                timelineData.slice(0, nextCandidatesRow).map((c, index) => (
                  <div key={index} className="timeline_item ">
                    <span>
                      <a>{c && c.body}</a>
                      <small
                        className="float-right text-right"
                        style={getFontSizeStyle(
                          settingsSelector.fontSize,
                          "80%"
                        )}
                      >
                        {c &&
                          c.created_at.replace("T", " ").substring(11, 16) +
                            " " +
                            c.created_at.replace("T", " ").substring(8, 10) +
                            "-" +
                            c.created_at.replace("T", " ").substring(5, 7) +
                            "-" +
                            c.created_at.replace("T", " ").substring(0, 4)}
                      </small>
                    </span>
                    <h6
                      className="font600"
                      style={getFontSizeStyle(
                        settingsSelector.fontSize,
                        "1rem"
                      )}
                    >
                      {c.userPr === null ? "" : c.userPr.user.name}
                    </h6>
                    <h6
                      style={getFontSizeStyle(
                        settingsSelector.fontSize,
                        "1rem"
                      )}
                    >
                      {c && c.title}
                    </h6>
                    {c.detail_description !== null && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: c.detail_description,
                        }}
                      />
                    )}
                    {c.additional_information !== null && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: c.additional_information,
                        }}
                      />
                    )}
                    <div className="msg">
                      <a
                        onClick={() => handleClick(index, c)}
                        className="clickable text-muted"
                        role="button"
                      >
                        <i className="clickable fa fa-comments"></i>
                        {c.comments.length > 0 ? (
                          <>
                            {t(
                              "timelineHistory.timelineCommentDescription.view"
                            )}
                          </>
                        ) : (
                          <>
                            {t(
                              "timelineHistory.timelineCommentDescription.add"
                            )}
                          </>
                        )}
                      </a>
                      <Collapse isOpen={open[index]}>
                        <div>
                          <Formik
                            initialValues={timelineCommentsFormValues}
                            onSubmit={(values) =>
                              handleTimelineCommentSubmit(values)
                            }
                          >
                            <div className="collapse-event">
                              {c.comments.map((item, index) => (
                                <p key={index}>{item.body}</p>
                              ))}
                              <small
                                className="float-right font-14"
                                style={getFontSizeStyle(
                                  settingsSelector.fontSize,
                                  "14px"
                                )}
                              >
                                {c.comments.length > 0 ? (
                                  <>
                                    {c.comments.map((item, index) => (
                                      <div key={index}>
                                        {item.created_at
                                          .replace("T", " ")
                                          .substring(11, 16) +
                                          " " +
                                          item.created_at
                                            .replace("T", " ")
                                            .substring(8, 10) +
                                          "-" +
                                          item.created_at
                                            .replace("T", " ")
                                            .substring(5, 7) +
                                          "-" +
                                          item.created_at
                                            .replace("T", " ")
                                            .substring(0, 4)}
                                      </div>
                                    ))}
                                  </>
                                ) : (
                                  ""
                                )}
                              </small>
                              {c.comments.length > 0 ? null : (
                                <Form className="well">
                                  <FormGroup>
                                    <Field
                                      type={"hidden"}
                                      name={"timeline_id"}
                                      value={c.id}
                                    />
                                    <Field
                                      as={"textarea"}
                                      rows="2"
                                      name="body"
                                      className="form-control no-resize"
                                      placeholder="Enter comment..."
                                      onClick={() => {
                                        if (c.comments.length === 0) {
                                          setTimelineID(c.id);
                                        }
                                      }}
                                      style={getFontSizeStyle(
                                        settingsSelector.fontSize,
                                        "14px"
                                      )}
                                    />
                                  </FormGroup>
                                  <Button
                                    className="btn btn-primary"
                                    type="submit"
                                    disabled={timelineSelector.isLoading}
                                    style={getFontSizeStyle(
                                      settingsSelector.fontSize,
                                      "14px"
                                    )}
                                  >
                                    {timelineSelector.isLoading ? (
                                      <Loader />
                                    ) : (
                                      <>{t("buttons.save")}</>
                                    )}
                                  </Button>
                                </Form>
                              )}
                            </div>
                          </Formik>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                ))
              ) : (
                <NoRecordFound />
              )}
            </>
          ) : (
            <DataLoader />
          )}
          <div className={"w-100 text-center"}>
            {timelineData && nextCandidatesRow < timelineData.length && (
              <Button
                className="mt-4"
                color={"primary"}
                onClick={handleCandidateDataPerRow}
                style={getFontSizeStyle(settingsSelector.fontSize, "14px")}
              >
                {t("timelineHistory.loadMore")}
              </Button>
            )}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default Timeline;
