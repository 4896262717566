import * as moment from "moment/moment";

export const disablePastDate = () => {
  const today = new Date();
  today.setDate(today.getDate() - 1);
  const dd = String(today.getDate() + 1).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
};

export const disabledPastDateTimeLocale = () => {
  return moment().format("YYYY-MM-DD HH:mm:ss");
};
