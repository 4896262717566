import React from "react";
import { ErrorMessage } from "formik";

const FormikFieldErrorMessage = ({ name }) => {
  return (
    <ErrorMessage name={name}>
      {(msg) => <span style={{ color: "red" }}>{msg}</span>}
    </ErrorMessage>
  );
};

export default FormikFieldErrorMessage;
