const SkillData =[
    {
        id:1,
        name:"#Web",
        status:false
    },
    {
        id:2,
        name:"#Mobile",
        status:false
    },
    {
        id:3,
        name:"#Writing",
        status:false
    }
]
  export default SkillData
