import axios from "axios";

const useAxiosInstance = () => {
  const token = localStorage.getItem("token");
  const axiosInstance = axios.create({
    baseURL: "your_base_url_here",
    headers: {
      Authorization: `Bearer ${token}`,
      //   "Content-Type": "application/json",
    },
  });

  return axiosInstance;
};

export default useAxiosInstance;
