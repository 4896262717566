const EducationData = [
  {
    Id: 1,
    degree: 'HSSC',
    school: 'abc ltd',
    start_date: '2016-01-01',

    end_date: '2018-01-01',
    percentage: 40
  },
  {
    Id: 2,
    school: 'xyz ltd',
    start_date: '2013-01-01',
    degree: 'SSC',
    end_date: '2015-12-01',
    percentage: 70
  }
]
export default EducationData
