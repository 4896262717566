export const darkModeAction = (e) => (dispatch) => {
  dispatch({
    type: "CHANGE_DARK_MODE",
    payload: e,
  });
};
export const darkHeaderAction = (e) => (dispatch) => {
  dispatch({
    type: "CHANGE_DARK_HEADER",
    payload: e,
  });
};
export const fixNavbarAction = (e) => (dispatch) => {
  dispatch({
    type: "FIX_NAVBAR_HEADER",
    payload: e,
  });
};
export const darkMinSidebarAction = (e) => (dispatch) => {
  dispatch({
    type: "DARK_MIN_SIDEBAR",
    payload: e,
  });
};
export const darkSidebarAction = (e) => (dispatch) => {
  dispatch({
    type: "DARK_SIDEBAR",
    payload: e,
  });
};
export const iconColorAction = (e) => (dispatch) => {
  dispatch({
    type: "CHANGE_ICON_COLOR",
    payload: e,
  });
};
export const gradientColorAction = (e) => (dispatch) => {
  dispatch({
    type: "CHANGE_GRADIENT_COLOR",
    payload: e,
  });
};
export const rtlAction = (e) => (dispatch) => {
  dispatch({
    type: "IS_RTL",
    payload: e,
  });
};
export const fontAction = (e) => (dispatch) => {
  dispatch({
    type: "CHANGE_FONT",
    payload: e,
  });
};
export const fontSizeAction = (e) => (dispatch) => {
  dispatch({
    type: "CHANGE_FONT_SIZE",
    payload: e,
  });
};
export const lineHeightAction = (e) => (dispatch) => {
  dispatch({
    type: "CHANGE_LINE_HEIGHT",
    payload: e,
  });
};
export const subMenuIconAction = (e) => (dispatch) => {
  dispatch({
    type: "CHANGE_SUBMENU_ICON",
    payload: e,
  });
};
export const menuIconAction = (e) => (dispatch) => {
  dispatch({
    type: "CHANGE_DROPDOWN_MENU_ICON",
    payload: e,
  });
};
export const boxLayoutAction = (e) => (dispatch) => {
  dispatch({
    type: "CHANGE_BOX_LAYOUT",
    payload: e,
  });
};
export const statisticsAction = (e) => (dispatch) => {
  dispatch({
    type: "IS_OPEN_STATISTICS",
    payload: e,
  });
};
export const accountAction = (e) => (dispatch) => {
  dispatch({
    type: "IS_OPEN_ACCOUNT",
    payload: e,
  });
};
export const friendListAction = (e) => (dispatch) => {
  dispatch({
    type: "IS_OPEN_FRIENDLIST",
    payload: e,
  });
};
export const statisticsCloseAction = (e) => (dispatch) => {
  dispatch({
    type: "IS_CLOSE_STATISTICS",
    payload: e,
  });
};
export const accountCloseAction = (e) => (dispatch) => {
  dispatch({
    type: "IS_CLOSE_ACCOUNT",
    payload: e,
  });
};
export const friendListCloseAction = (e) => (dispatch) => {
  dispatch({
    type: "IS_CLOSE_FRIENDLIST",
    payload: e,
  });
};
export const toggleLeftMenuAction = (e) => (dispatch) => {
  dispatch({
    type: "IS_TOGGLE_LEFTMENU",
    payload: e,
  });
};
export const boxAction = (e) => (dispatch) => {
  dispatch({
    type: "IS_TOGGLE_BOX",
    payload: e,
  });
};
export const box2Action = (e) => (dispatch) => {
  dispatch({
    type: "IS_TOGGLE_BOX2",
    payload: e,
  });
};
export const box3Action = (e) => (dispatch) => {
  dispatch({
    type: "IS_TOGGLE_BOX3",
    payload: e,
  });
};
export const box4Action = (e) => (dispatch) => {
  dispatch({
    type: "IS_TOGGLE_BOX4",
    payload: e,
  });
};
export const box5Action = (e) => (dispatch) => {
  dispatch({
    type: "IS_TOGGLE_BOX5",
    payload: e,
  });
};
export const box6Action = (e) => (dispatch) => {
  dispatch({
    type: "IS_TOGGLE_BOX6",
    payload: e,
  });
};
export const box7Action = (e) => (dispatch) => {
  dispatch({
    type: "IS_TOGGLE_BOX7",
    payload: e,
  });
};
export const box8Action = (e) => (dispatch) => {
  dispatch({
    type: "IS_TOGGLE_BOX8",
    payload: e,
  });
};
export const box9Action = (e) => (dispatch) => {
  dispatch({
    type: "IS_TOGGLE_BOX9",
    payload: e,
  });
};
export const box10Action = (e) => (dispatch) => {
  dispatch({
    type: "IS_TOGGLE_BOX10",
    payload: e,
  });
};
export const boxCloseAction = (e) => (dispatch) => {
  dispatch({
    type: "IS_CLOSE_BOX",
    payload: e,
  });
};
export const box2CloseAction = (e) => (dispatch) => {
  dispatch({
    type: "IS_CLOSE_BOX2",
    payload: e,
  });
};
export const box3CloseAction = (e) => (dispatch) => {
  dispatch({
    type: "IS_CLOSE_BOX3",
    payload: e,
  });
};
export const box4CloseAction = (e) => (dispatch) => {
  dispatch({
    type: "IS_CLOSE_BOX4",
    payload: e,
  });
};
export const box5CloseAction = (e) => (dispatch) => {
  dispatch({
    type: "IS_CLOSE_BOX5",
    payload: e,
  });
};
export const box6CloseAction = (e) => (dispatch) => {
  dispatch({
    type: "IS_CLOSE_BOX6",
    payload: e,
  });
};
export const box7CloseAction = (e) => (dispatch) => {
  dispatch({
    type: "IS_CLOSE_BOX7",
    payload: e,
  });
};
export const box8CloseAction = (e) => (dispatch) => {
  dispatch({
    type: "IS_CLOSE_BOX8",
    payload: e,
  });
};
export const box9CloseAction = (e) => (dispatch) => {
  dispatch({
    type: "IS_CLOSE_BOX9",
    payload: e,
  });
};
export const box10CloseAction = (e) => (dispatch) => {
  dispatch({
    type: "IS_CLOSE_BOX10",
    payload: e,
  });
};

export const changeLanguage = (e) => (dispatch) => {
  dispatch({
    type: "CHANGE_LANGUAGE",
    payload: e,
  });
};
